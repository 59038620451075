/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { EditorInputDataType } from "../editing/EditorTypes";
import { ELDownloadData } from "../stage/StageTypes";

export enum DocumentActions {
	markDocumentDirty = "MARK_DOCUMENT_DIRTY",
	documentCreated = "DOCUMENT_CREATED",
	documentUpdated = "DOCUMENT_UPDATED",
	download = "DOWNLOAD",
	updateCloudAssetPath = "UPDATE_CLOUD_ASSET_PATH",
	replaceErrorImage = "REPLACE_ERROR_IMAGE",
	objectMoved = "OBJECT_MOVED",
	objectRotated = "OBJECT_ROTATED",
	objectScaled = "OBJECT_SCALED",
	mouseDoubleClick = "MOUSE_DOUBLE_CLICK",
	zoomModified = "ZOOM_MODIFIED",
	swapAssets = "SWAP_ASSETS",
	activeObjectChange = "ACTIVE_OBJECT_CHANGE",
	commitLayout = "COMMIT_LAYOUT",
	applyEdit = "APPLY_EDIT",
	textEditingEntered = "TEXT_EDITING_ENTERED",
	textEditingExited = "TEXT_EDITING_EXITED"
}

export enum DocumentDirty {
	NON_DIRTY = 0,
	DIRTY = 1
}

export enum DocumentSaveStatus {
	unsaved = "UNSAVED",
	saveInProgress = "SAVE_IN_PROGRESS",
	saved = "SAVED",
	saveError = "SAVE_ERROR"
}

export enum DocumentFormat {
	JPG = "image/jpg",
	PNG = "image/png",
	TIFF = "image/tiff",
	PSD = "image/vnd.adobe.photoshop",
	PSD2 = "application/x-photoshop",
	CPSD = "document/vnd.adobe.cpsd+dcx"
}

export const enum DocumentConvertibleDataType {
	imageData = "IMAGE_DATA"
}

export const editorInputToDocumentDataTypeMap: Record<EditorInputDataType, DocumentConvertibleDataType> = Object.freeze({
	[EditorInputDataType.imageData]: DocumentConvertibleDataType.imageData
});

export type ELSaveOptions = ELDownloadData;

export enum DocSaveError {
	ASSET_ALREADY_EXISTS = "ASSET_ALREADY_EXISTS"
}

export const enum CanvasContextTypeId {
    webgl = "webgl",
    context2D = "2d"
}