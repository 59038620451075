/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import React from "react";
import ReactDOM from "react-dom";

//Application Specefic
import { ViewAction } from "../../../IBaseController";
import ITemplateViewController from "../../../ITemplateViewController";
import { ControllerAction } from "../../../IViewController";
import { ELWhatsNewDialogView } from "./ELWhatsNewPanelView";
import { ContentEntity, ContentType } from "@elements/elementswebcommon";
import { ELContentCacheDownloader } from "../../../../workspaces/creations/utils/ELContentCacheDownloader";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { ELWhatsNewPanelViewProvider } from "./ELWhatsNewPanelViewProvider";
import {
    ELWhatsNewButtonInfo,
    ELWhatsNewDialogAction, ELWhatsNewDialogContentType, ELWhatsNewPanelViewPropsData,
    ELWhatsNewSpecificInfoType, ELWhatsNewTabData
} from "../../../../common/interfaces/ELWhatsNewTypes";
import { ContentUtils } from "../../../../utils/ContentUtils";
import { ToastUtils } from "../../../../utils/ToastUtils";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { IngestLogging } from "../../../../services/IngestWrapper";
import { IngestWorkflowTypes, IngestEventTypes, IngestEventSubTypes, IngestLogObjectKey } from "../../../../utils/IngestConstants";
import { IngestUtils } from "../../../../utils/IngestUtils";
import AppRouteUtil from "../../../../app/AppRouteUtil";
import { WorkflowsName } from "../../../../workspaces/IWorkflow";
import CreationUtils from "../../../../workspaces/creations/utils/CreationUtils";
import Utils from "../../../../utils/Utils";

export class ELWhatsNewPanel extends ITemplateViewController {
    private _whatsNewData: ELWhatsNewDialogContentType[] = [];

    private _onTabSelectionChange = (tabName: string): void => {
        IngestLogging.getInstance().logEvent(IngestUtils.addWorkspaceDetail(IngestWorkflowTypes.whatsNew,
            IngestUtils.getPseudoLogObject(IngestWorkflowTypes.cardCategory, IngestEventTypes.click, tabName)));
    };

    createView(container: HTMLElement): void {
        super.createView(container);
        const whatsNewDialog = React.createElement(ELWhatsNewDialogView, {
            controller: this, onTabSelectionChange: this._onTabSelectionChange.bind(this)
        });

        ReactDOM.render(whatsNewDialog, container);
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        this._getWhatsNewData().then((data) => {
            this._updateView(data);
        }).catch((error) => {
            Logger.log(LogLevel.ERROR, "ELWhatsNewPanel:initialize: Error in fetching data", error);
            ToastUtils.error(IntlHandler.getInstance().formatMessage("report-abuse-failure-toast"));
            const additionalLogInfo: Record<string, string> = {};
            additionalLogInfo[IngestLogObjectKey.errorDescription] = "Couldn't get content data for WhatsNew dialog.";
            IngestLogging.getInstance().logEvent(IngestUtils.addWorkspaceDetail(IngestWorkflowTypes.whatsNew,
                IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventTypes.error, IngestEventSubTypes.render, "auto", additionalLogInfo)));
        });
    }

    destroyView(): void {
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container);
        }
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    private async _openCreation(workflowName: string): Promise<void> {
        AppRouteUtil.changeRoute(CreationUtils.getHighlightCreationHistoryState(workflowName as WorkflowsName));
    }

    private async _onTryNowButtonPress(workflowName: string, buttonLabel: string): Promise<void> {
        IngestLogging.getInstance().logEvent(IngestUtils.addWorkspaceDetail(IngestWorkflowTypes.whatsNew,
            IngestUtils.getPseudoLogObject(workflowName, IngestEventTypes.click, buttonLabel)));
        this.notify({ type: ELWhatsNewDialogAction.onDismiss });
        await this._openCreation(workflowName);
    }

    private _getUpdateGoUrlAsPerLocale(url?: string): string {
        return url + "-" + IntlHandler.getInstance().getCurrentLocale();
    }

    private _onLearnMoreButtonPress(workflowName: string, buttonLabel: string, url?: string): void {
        IngestLogging.getInstance().logEvent(IngestUtils.addWorkspaceDetail(IngestWorkflowTypes.whatsNew,
            IngestUtils.getPseudoLogObject(workflowName, IngestEventTypes.click, buttonLabel)));
        this.notify({ type: ELWhatsNewDialogAction.onDismiss });
        if (url) {
            Utils.openInNewTab(url);
        }
    }

    private _handleButtonPress(workflowName: string, buttonData: ELWhatsNewButtonInfo): void {
        if (buttonData.label === "try-now") {
            this._onTryNowButtonPress(workflowName, buttonData.label);
        } else if (buttonData.label === "learn-more") {
            this._onLearnMoreButtonPress(workflowName, buttonData.label, this._getUpdateGoUrlAsPerLocale(buttonData.url));
        } else {
            Logger.log(LogLevel.WARN, "ELWhatsNewPanel:_handleButtonPress: Button label not recognized");
            this._onLearnMoreButtonPress(workflowName, buttonData.label, buttonData.url);
        }
    }

    private _getView(viewProps: ELWhatsNewPanelViewPropsData): React.ReactElement {
        return React.createElement(ELWhatsNewPanelViewProvider, { data: viewProps, onButtonPress: this._handleButtonPress.bind(this) });
    }

    private _createViewsFromContentData(data: ELWhatsNewDialogContentType[]): ELWhatsNewTabData[] {
        const panelViewData: ELWhatsNewTabData[] = [];
        data.forEach((item: ELWhatsNewDialogContentType, index) => {
            const panelView = this._getView({ itemContent: item });
            panelViewData.push({ key: index, tabName: item.tabName, panelData: panelView });
        });
        return panelViewData;
    }

    private _updateView(data: ELWhatsNewDialogContentType[]): void {
        const panelViewData: ELWhatsNewTabData[] = this._createViewsFromContentData(data);
        this.viewDispatcher?.call(this.viewDispatcher, { type: ELWhatsNewDialogAction.updateWhatsNewContent, payload: panelViewData });
    }

    private async _parseContent(rawContent: ContentEntity[]): Promise<void> {
        try {
            const filteredContentUrls = await ContentUtils.getContentUrls(rawContent);
            rawContent.forEach((item: ContentEntity, index: number) => {
                const itemSpecificInfo = item.props.specificInfo as ELWhatsNewSpecificInfoType;
                if (!item.props.mimeType)
                    throw new Error("ELWhatsNewPanel:_parseContent: MimeType is missing in content");
                this._whatsNewData.push({
                    mediaType: item.props.mimeType,
                    contentURL: filteredContentUrls[index],
                    tabName: item.props.title,
                    tabTitle: itemSpecificInfo.tabTitle,
                    tabDescription: itemSpecificInfo.tabDescription,
                    buttonData: itemSpecificInfo.button,
                    size: itemSpecificInfo.size,
                    workflowName: itemSpecificInfo.workflowName
                });
            });
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELWhatsNewPanel:_parseContent: Error in parsing content", error);
            return Promise.reject(error);
        }
    }

    private async _fetchContent(): Promise<void> {
        try {
            const whatsNewData = await ELContentCacheDownloader.getContentForContentType(ContentType.whatsNew);
            await this._parseContent(whatsNewData);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELWhatsNewPanel:_fetchContent: ", error);
            return Promise.reject(error);
        }
    }

    private async _getWhatsNewData(): Promise<ELWhatsNewDialogContentType[]> {
        if (this._whatsNewData.length === 0) {
            await this._fetchContent();
        }
        return this._whatsNewData;
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELWhatsNewDialogAction.onDismiss: {
                this.destroyView();
                handled = true;
                break;
            }
        }

        if (!handled)
            return this._owner.notify(action);

        return handled;
    }
}