/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

//Adobe Internal
import { error, info } from "@react/react-spectrum/Toast";

//Application Specific
import SlideshowView from "./SlideshowView";
import IBaseWorkspace, { WorkspaceActionType } from "../../../IBaseWorkspace";
import IWorkflow, { WorkflowAction, WorkflowActionType, WorkflowsName } from "../../../IWorkflow";
import ELCreationsHeader from "../../../../view/components/templates/el-creations-header/ELCreationsHeader";
import SlideshowPanel from "../../../../view/components/templates/slideshow-panel/SlideshowPanel";
import { CLOSED_PANEL_KEY, ELSlideshowPanelWorkflowAction, ELSlideshowProjectRequestParams, SlideshowExportSettings, SlideshowInputInfo } from "../../../../common/interfaces/creations/SlideshowTypes";
import { ViewAction } from "../../../../view/IBaseController";
import { ControllerAction } from "../../../../view/IViewController";
import Logger, { LogLevel } from "../../../../utils/Logger";
import {
    CreationsStatus,
    CreationsData,
    CreationsStatusPayload,
    CreationJobAsset,
    CreationsVideoExportPreset,
    CreationsVideoExportSettings,
    ELCreationWorkflowPayload,
    UNTITLED_INTL_KEY,
    CreationsMode
} from "../../../../common/interfaces/creations/CreationTypes";
import Utils from "../../../../utils/Utils";
import store from "../../../../stores/store";
import CreationsAction, { CreationsThumb, CreationsVideoRendition } from "../../../../stores/actions/CreationsAction";
import CreationWorkflow, {DocTypeUnset} from "../CreationWorkflow";
import CreationInAppNotifier from "../../utils/CreationInAppNotifier";
import SlideshowUtils from "./utils/SlideshowUtils";
import {
    CreationAppSubscriberType,
    CreationInAppNotifierAction,
    CreationStatusData,
    ELProjectProgressPayload
} from "../../../../common/interfaces/creations/CreationInAppNotifierTypes";
import {
    ELCreationsHeaderControllerAction, ELCreationsHeaderWorkflowAction
} from "../../../../common/interfaces/creations/ELCreationsHeaderTypes";
import { SlideshowControllerActions, SlideshowViewActions } from "../../../../common/interfaces/creations/SlideshowTypes";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import CreationUtils from "../../utils/CreationUtils";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectCustomKey, IngestLogObjectKey, IngestLogObjectValue, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import Constants, { ERROR_THUMBDATA } from "../../../../utils/Constants/Constants";
import { ToastUtils } from "../../../../utils/ToastUtils";
import { HistoryUtils } from "../../../../utils/HistoryUtils";
import { ELAdobeAsset } from "../../../../common/interfaces/storage/AssetTypes";
import { CreationOperationTypeInfo, CreationOperationTypeOutput, Request as ELServiceRequest, OutputType, SlideshowCreationOperationSubTypeInfo, SlideshowJobUtils } from "@elements/elementswebcommon";
import { SlideshowJobCreator } from "./utils/SlideshowJobCreator";
import { OperationInputV2 } from "../../../../common/interfaces/creations/v2/CSTypes";
import { CreationsJobCreator } from "../../utils/CreationsJobCreator";
import { ELError } from "../../../../modules/error/ELError";

class Slideshow extends CreationWorkflow<DocTypeUnset> {
    
    private _slideshowPanel!: SlideshowPanel;
    private _creationsHeader!: ELCreationsHeader;
    
    constructor(owner: IBaseWorkspace) {
        super(owner, WorkflowsName.slideshow);
        this.mediaGridConfig = SlideshowUtils.getSlideshowMediaGridConfig();
    }
    
    protected getJobCreator(): CreationsJobCreator {
        return new SlideshowJobCreator();
    }

    private _resetSlideshowRenditionInView(): void {
        if (this.viewDispatcher) {
            const slideshowThumbPayload = { id: Utils.getNilUUID(), renditionData: {} };
            this.viewDispatcher({ type: SlideshowViewActions.slideshowThumbData, payload: slideshowThumbPayload as CreationsVideoRendition });
        }
    }

    private async _getSlideshowRendition(slideshow: CreationsData, slideshowRequestObj: ELServiceRequest): Promise<void> {
        Logger.log(LogLevel.INFO, "Slideshow : fetching slideshow renditions - " + JSON.stringify(slideshow));

        try {
            const startDate = new Date();
            const slideshowCreationAsset = await this._getSlideshowFullOutputAsset(slideshowRequestObj);
            const renditionData = await this.getOriginalDataOrPollAssetRendition(slideshowCreationAsset);

            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.slideshow, IngestEventTypes.rendition,
                IngestEventSubTypes.success, Utils.getDateDifference(startDate, new Date(), "second")));
            const slideshowThumbPayload = { id: slideshow.id, renditionData: renditionData };
            this.viewDispatcher?.call(this.viewDispatcher, {
                type: SlideshowViewActions.slideshowThumbData,
                payload: slideshowThumbPayload
            });
        } catch (e) {
            Logger.log(LogLevel.WARN, "Slideshow:_getSlideshowRendition: Unable to fetch slideshow renditions -  " + JSON.stringify(slideshow) + " " + e);
            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.slideshow, IngestEventTypes.rendition,
                IngestEventSubTypes.error));
            const slideshowThumbPayload = { id: slideshow.id, renditionData: {} };
            this.viewDispatcher?.call(this.viewDispatcher,
                { type: SlideshowViewActions.slideshowThumbData, payload: slideshowThumbPayload as CreationsVideoRendition });
        }
    }

    private async _createSlideshow(assetList: ELAdobeAsset[]): Promise<void> {
        this.startDate = new Date();

        const requestParams: ELSlideshowProjectRequestParams = {
            assets: assetList,
            title: IntlHandler.getInstance().formatMessage(UNTITLED_INTL_KEY)
        };

        try {
            const requestJson = this.createRequestJson(requestParams);
            this.projectId = await this.createCreation(requestJson);
            this._updateStoreSlideshowStatus(CreationsStatus.requested);
        } catch (error) {
            const elError = new ELError("Slideshow project creation failed!", this.getWorkflowConfiguration(), error as ELError);
            Logger.log(LogLevel.ERROR, elError);
            this._updateStoreSlideshowStatus(CreationsStatus.apiError);
            return;
        }

        this._pollSlideshowStatus();
    }

    private _preprocessSlideshowEdit(projectId: string): void {
        const creationsThumb: CreationsThumb = { id: projectId, objectURL: ERROR_THUMBDATA };
        store.dispatch(CreationsAction.updateThumb(creationsThumb));

        this.startDate = new Date();
        this.mode = CreationsMode.update;
        this._updateStoreSlideshowStatus(CreationsStatus.editing);
    }

    private _getEditCreationsRequestParams(projectId: string, projectData: CreationsData): ELSlideshowProjectRequestParams {
        const inputInfo = store.getState().creationsReducer.data[projectId].uiData?.inputs as SlideshowInputInfo;
        if (inputInfo &&
            projectData.inputs &&
            inputInfo.templates &&
            inputInfo.templates.video !== projectData.inputs.templates?.video &&
            inputInfo.templates.audio === projectData.inputs.templates?.audio) {
            inputInfo.templates.audio = inputInfo.templates.video;
        }

        const exportPreset = (store.getState().creationsReducer.data[projectId].exportPreset) as CreationsVideoExportPreset ?? CreationsVideoExportPreset.EPR360;

        const requestParams: ELSlideshowProjectRequestParams = {
            assets: store.getState().selectedMediaListReducer,
            title: this.getTrueTitleForRequest(store.getState().creationsReducer.data[projectId].appliedData?.title ?? projectData.title),
            inputInfo: inputInfo,
            resolution: exportPreset
        }

        return requestParams;
    }

    private _getOutDatedCreationsRequestParams(projectId: string, projectData: CreationsData): ELSlideshowProjectRequestParams {
        const inputInfo = store.getState().creationsReducer.data[projectId].appliedData?.inputs as SlideshowInputInfo;
        if (inputInfo &&
            projectData.inputs &&
            inputInfo.templates &&
            inputInfo.templates.video !== projectData.inputs.templates?.video &&
            inputInfo.templates.audio === projectData.inputs.templates?.audio) {
            inputInfo.templates.audio = inputInfo.templates.video;
        }

        const exportPreset = (store.getState().creationsReducer.data[projectId].appliedData?.outputs?.media.exportSettings as CreationsVideoExportSettings)?.preset ??
            CreationsVideoExportPreset.EPR360;

        const requestParams: ELSlideshowProjectRequestParams = {
            assets: projectData.assets ? this.getAssetListForJob(projectData.assets as CreationJobAsset[]) : store.getState().selectedMediaListReducer,
            title: projectData.title,
            inputInfo: inputInfo,
            resolution: exportPreset
        }

        return requestParams;
    }

    private _preprocessOutdatedSlideshow(projectId: string): void {
        const creationsThumb: CreationsThumb = { id: projectId, objectURL: ERROR_THUMBDATA };
        store.dispatch(CreationsAction.updateThumb(creationsThumb));
        store.dispatch(CreationsAction.updateProgress({ projectId: projectId, percent: 0 }));

        this.startDate = new Date();

        this.mode = CreationsMode.update; //updating outdated project is editing for backend service
        this._updateStoreSlideshowStatus(CreationsStatus.oldVersion);
    }

    private async _editOutdatedSlideshow(): Promise<void> {
        if (!this.projectId || !this.projectData)
            return;

        this._preprocessOutdatedSlideshow(this.projectId);

        const requestParams = this._getOutDatedCreationsRequestParams(this.projectId, this.projectData);

        try {
            const requestJson = this.createRequestJson(requestParams);
            await this.editCreation(this.projectId, requestJson);
        } catch (error) {
            Logger.log(LogLevel.WARN, "Slideshow:_editOutdatedSlideshow: project edit failed!" + error);
            this._updateStoreSlideshowStatus(CreationsStatus.apiError);
            return;
        }

        this._resetSlideshowRenditionInView();
        this._pollSlideshowStatus();
    }

    private async _editSlideshow(): Promise<void> {
        if (!this.projectId || !this.projectData)
            return;

        const requestParams = this._getEditCreationsRequestParams(this.projectId, this.projectData);
        if(requestParams.assets.length === 0) {
            ToastUtils.warning(IntlHandler.getInstance().formatMessage("add-media-slideshow-warning"));
            return;
        }

        store.dispatch(CreationsAction.updateSlideshowPanelSelectedKey(CLOSED_PANEL_KEY));
        this._preprocessSlideshowEdit(this.projectId);

        try {
            const requestJson = this.createRequestJson(requestParams);
            await this.editCreation(this.projectId, requestJson);
        } catch (error) {
            const elError = new ELError("Slideshow project edit failed!", this.getWorkflowConfiguration(), error as ELError);
            Logger.log(LogLevel.ERROR, elError);
            this._updateStoreSlideshowStatus(CreationsStatus.apiError);
            return;
        }

        this._resetSlideshowRenditionInView();
        this._pollSlideshowStatus();
    }

    private _resetSlideshowUIData(): void {
        const slideshowId = store.getState().creationsReducer.activeSlideshowId;
        if (slideshowId) {
            const appliedData = store.getState().creationsReducer.data[slideshowId].appliedData;
            if (appliedData) {
                this.populateSelectedMediaList(appliedData.assets);
                store.dispatch(CreationsAction.updateUIData(appliedData));
            }
        }
    }

    private _cancelSlideshow(): void {
        this._resetSlideshowUIData();
        store.dispatch(CreationsAction.updateSlideshowPanelSelectedKey(CLOSED_PANEL_KEY));
    }

    private _preprocessSlideshowRetry(): void {
        this.mode = CreationsMode.retry
        this._updateStoreSlideshowStatus(CreationsStatus.requested);
        this.startDate = new Date();
    }

    private async _retrySlideshow(creationData: CreationsData): Promise<void> {
        this._preprocessSlideshowRetry();

        const projectId = creationData.id;
        try {
            this.projectId = await this.retryCreation(projectId);
        } catch (error) {
            Logger.log(LogLevel.WARN, "Slideshow:_retrySlideshow: project retry failed!" + error);
            this._updateStoreSlideshowStatus(CreationsStatus.apiError);
            return;
        }

        this._pollSlideshowStatus();
    }

    private _shouldShowLastVersion(projectStatusData: CreationStatusData): boolean {
        const hasMultipleVersion = this.projectData ? (this.projectData?.isMultipleVersion ?? false) : false;
        const isSlideshowCreateError = projectStatusData.status === CreationsStatus.error;
        return hasMultipleVersion && isSlideshowCreateError;
    }

    private _shouldDeleteSlideshow(projectStatusData: CreationStatusData): boolean {
        const hasMultipleVersion = this.projectData ? (this.projectData?.isMultipleVersion ?? false) : false;
        const isSlideshowCreateError = projectStatusData.status === CreationsStatus.error;
        return !hasMultipleVersion && isSlideshowCreateError;
    }

    private _hasProjectTitle(): string {
        if (this.projectData &&
            this.projectData.inputs &&
            this.projectData.inputs.titles) {
            return (this.projectData.inputs.titles[0].mainText !== "") ? "Yes" : "No";
        }
        return "No";
    }

    protected logIngestData(projectStatus: string): void {
        try {
            const timeElapsed = Utils.getDateDifference(this.startDate, new Date(), "second");
            const title = this._hasProjectTitle();

            const hasOutputData = this.projectData?.outputs && this.projectData?.outputs?.media;
            const exportSettings = this.projectData?.outputs?.media.exportSettings as CreationsVideoExportSettings;

            const theme = (this.projectData?.inputs?.templates?.video) as string ?? "null";
            const audio = (this.projectData?.inputs?.templates?.audio) as string ?? "null";

            const allMedia = store.getState().selectedMediaListReducer;

            const customEntries: Record<string, string> = IngestUtils.getMediaLoggingInfo(allMedia);
            customEntries[IngestLogObjectCustomKey.timeTaken] = timeElapsed.toString();
            customEntries[IngestLogObjectCustomKey.theme] = theme;
            customEntries[IngestLogObjectCustomKey.audio] = audio;
            customEntries[IngestLogObjectCustomKey.title] = title;
            customEntries[IngestLogObjectCustomKey.quality] = hasOutputData ? exportSettings?.preset : CreationsVideoExportPreset.EPR360;

            const eventContextId = Utils.getRandomUUID();
            const additionalLogInfo: Record<string, string> = {};
            additionalLogInfo[IngestLogObjectKey.eventContextGuid] = eventContextId;

            for (const key in customEntries) {
                const additionalLogInfoTemp = { ...additionalLogInfo };
                additionalLogInfoTemp[IngestLogObjectKey.eventContextGuid] = eventContextId;
                additionalLogInfoTemp[IngestLogObjectKey.contentName] = key;
                additionalLogInfoTemp[IngestLogObjectKey.eventCount] = customEntries[key];
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                    IngestEventSubTypes.info, this.mode, IngestWorkflowTypes.slideshow, additionalLogInfoTemp));
            }

            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                projectStatus, this.mode, IngestWorkflowTypes.slideshow, additionalLogInfo));
        }
        catch (e) {
            Logger.log(LogLevel.WARN, `Slideshow:logIngestData:${this.mode}: `, `Dunamis Logging Error:${e as string}`);
        }
    }

    private async _deleteFailedSlideshow(projectId: string): Promise<void> {
        if (this.projectId) {
            ToastUtils.error(IntlHandler.getInstance().formatMessage("slideshow-error"), { timeout: 0 });

            try {
                await CreationUtils.deleteCreation(projectId, "all");
            } catch (error) {
                const elError = new ELError("Slideshow _deleteFailedSlideshow failed!", this.getWorkflowConfiguration(), error as ELError);
                Logger.log(LogLevel.ERROR, elError);
            }
        }
    }

    private async _handleFailedSlideshow(projectId: string, projectStatusData: CreationStatusData): Promise<boolean> {
        let handled = false;

        if (this._shouldDeleteSlideshow(projectStatusData)) {
            await this._deleteFailedSlideshow(projectId);
            this.notify({ type: SlideshowControllerActions.backSlideshowView });
            handled = true;
        }

        if (this._shouldShowLastVersion(projectStatusData)) {
            this._fetchLastVersion();
            handled = true;
        }

        if (handled) {
            this.logIngestData(projectStatusData.status);
        }

        return handled;
    }

    private _updateSlideshowHistory(projectId: string): void {
        HistoryUtils.replaceHistory(SlideshowUtils.getSlideshowHistoryState(projectId));
    }

    private async _updateProjectData(projectId: string): Promise<boolean> {
        try {
            this.projectData = await this.getProjectData(projectId);
        } catch (error) {
            Logger.log(LogLevel.WARN, "Slideshow:_updateProjectData: Could not fetch slideshow project data! " + error);
            this._updateStoreSlideshowStatus(CreationsStatus.apiError);
            return false;
        }

        return true;
    }

    private _updateOutdatedSlideshow(): void {
        this._editOutdatedSlideshow();
    }

    private _updateCreationProgress = (projectId: string, percentageComplete: number): void => {
        store.dispatch(CreationsAction.updateProgress({
            projectId: projectId,
            percent: percentageComplete
        }));
    }

    private async _processSlideshowProject(projectStatusData: CreationStatusData): Promise<void> {
        if (!this.projectId || this.projectId !== projectStatusData.projectId) {
            const elError = new ELError("Slideshow:_processSlideshowProject: project Id not matching or project status isn't success", this.getWorkflowConfiguration());
            Logger.log(LogLevel.ERROR, elError);
            return;
        }

        if (await this._handleFailedSlideshow(this.projectId, projectStatusData)) {
            return;
        }

        const projectDataStatus = await this._updateProjectData(this.projectId);


        if (projectDataStatus && this.projectData && this.projectData.outputs) {
            const outputConfigPath = this.getOutputConfigPathFromOutputConfigurations(this.projectData.outputs.config)
            const outputJsonData = await CreationUtils.getOutputResultConfigJsonData(outputConfigPath.rootPath, outputConfigPath.relativeFilePath);
            const slideshowRequestObj = await this.getSerializedRequestObject(outputJsonData);
            if (!CreationUtils.isCreationOutdated(this.projectData)) {
                this._updateSlideshowStatus(projectStatusData);
                this._updateCreationProgress(this.projectId, 0);
                this._updateSlideshowHistory(this.projectId);
                this._populatePanelData(this.projectData, slideshowRequestObj);
                await this._getSlideshowRendition(this.projectData, slideshowRequestObj);
                this.logIngestData(projectStatusData.status);
                this._creationsHeader.notify({ type: ELCreationsHeaderControllerAction.updateCreationsData, payload: this.projectData });
            } else if (CreationUtils.isCreationOutdated(this.projectData)) {
                this._updateOutdatedSlideshow();
            }
        }
    }

    private async _getSlideshowFullOutputAsset(slideshowRequestObj: ELServiceRequest): Promise<ELAdobeAsset> {
        const operation = slideshowRequestObj.operations[0];
        const assetPath = SlideshowJobUtils.getOutputSlideShowPath(operation, OutputType.full);
        if (!assetPath) {
            const elError = new ELError("Slideshow: _getSlideshowFullOutputAsset, not able to get output asset path from outputconfigresult.json", this.getWorkflowConfiguration());
            Logger.log(LogLevel.ERROR, elError);
            return Promise.reject();
        }
        const slideshowOutputAsset = await CreationUtils.getCreationOutputAssetFromPathOrId({ path: assetPath });
        return slideshowOutputAsset;
    }

    private async _pollSlideshowStatus(): Promise<void> {
        if (this.projectId) {
            CreationInAppNotifier.pollStatus(this.projectId);
        }
    }

    private _updateSlideshowStatus(projectStatusData: CreationStatusData): void {
        if (this.projectId !== projectStatusData.projectId)
            return;

        this._updateStoreSlideshowStatus(projectStatusData.status);
    }

    private _updateActiveSlideshowInStore(): void {
        if (this.projectId)
            store.dispatch(CreationsAction.updateActiveSlideshowId(this.projectId));
    }

    async enterProject(slideshowPayload: ELCreationWorkflowPayload): Promise<void> {
        switch (slideshowPayload.initMode) {
            case CreationsMode.create: {
                this._createSlideshow(slideshowPayload.payload as ELAdobeAsset[]);
                break;
            }
            case CreationsMode.render: {
                this._updateActiveSlideshowInStore();
                const slideshowStatusPayload = slideshowPayload.payload as CreationsStatusPayload;
                this.projectData = slideshowStatusPayload.data;
                this.openProject(slideshowStatusPayload.projectId);
                break;
            }
            case CreationsMode.retry: {
                this._retrySlideshow(slideshowPayload.payload as CreationsData);
                break;
            }
            default: {
                Logger.log(LogLevel.WARN, "Slideshow (enterProject) - Invalid slideshow mode");
                break;
            }
        }
    }

    private _populatePanelData(projectData: CreationsData, slideshowRequestObj: ELServiceRequest): void {
        this.populateSelectedMediaList(projectData.assets);

        const operation = slideshowRequestObj.operations[0];
        const operationTypeInfo = operation.operationTypeInfo as CreationOperationTypeInfo;
        const operationSubTypeInfo = operationTypeInfo.operationSubTypeInfo as SlideshowCreationOperationSubTypeInfo;
        const output = operationTypeInfo.output as CreationOperationTypeOutput;

        const inputConfigurations = SlideshowJobUtils.getSlideShowConfiguration(operationSubTypeInfo);
        if (inputConfigurations) {
            projectData.inputs = inputConfigurations as unknown as OperationInputV2;
        }

        const exportSettings = SlideshowJobUtils.getExportSettings(output, OutputType.full) as unknown as SlideshowExportSettings;
        const exportPreset = exportSettings.preset.id as CreationsVideoExportPreset;

        store.dispatch(CreationsAction.updateVideoExportSettings({ projectId: projectData.id, exportPreset: exportPreset }));
        store.dispatch(CreationsAction.updateAppliedData(projectData));
        store.dispatch(CreationsAction.updateUIData(projectData));
    }

    async openProject(projectId: string): Promise<void> {
        this.projectId = projectId;
        this.startDate = new Date();
        this._pollSlideshowStatus();
    }

    private async _notifySlideshow(): Promise<void> {
        if (this.projectId) {
            const notifyEmailStatus = await this.notifyViaEmail(this.projectId);
            if (notifyEmailStatus) {
                ToastUtils.success(IntlHandler.getInstance().formatMessage("notify-slideshow-email-success"));
                this.notifyWorkflow({ type: WorkspaceActionType.startPreviousWorkflow });
            } else {
                ToastUtils.error(IntlHandler.getInstance().formatMessage("notify-slideshow-email-failure"));
            }
        }
    }

    private _getSlideshowStatusInOpenMode(): CreationsStatus | undefined {
        if (this.mode === CreationsMode.render && this.projectData) {
            return this.projectData.status as CreationsStatus;
        }

        return undefined;
    }

    private _updateStoreSlideshowStatus(creationsStatus: CreationsStatus): void {
        const status = this._getSlideshowStatusInOpenMode() ?? creationsStatus;

        store.dispatch(CreationsAction.updateStatus({
            id: this.projectId || Utils.getNilUUID(),
            status: status
        }));
        store.dispatch(CreationsAction.updateActiveSlideshowId(this.projectId || Utils.getNilUUID()));
    }

    private async _retrySlideshowBasedOnStatus(showMessage = true): Promise<void> {
        if (this.projectId) {
            try {
                if (showMessage) {
                    const message = IntlHandler.getInstance().formatMessage("retrying-slideshow");
                    info(message, {
                        closable: true,
                        timeout: 2000,
                    });
                }
                const status = await this.getCreationStatus(this.projectId);
                this._updateStoreSlideshowStatus(status);
                switch (status) {
                    case CreationsStatus.requested: {
                        this._pollSlideshowStatus();
                        break;
                    }
                    case CreationsStatus.retry: {
                        if (!this.projectData)
                            this.projectData = await this.getProjectData(this.projectId);
                        this._retrySlideshow(this.projectData);
                        break;
                    }
                    case CreationsStatus.success: {
                        this._resetSlideshowRenditionInView();
                        const statusData: CreationStatusData = {
                            projectId: this.projectId,
                            status: CreationsStatus.success
                        };
                        this._processSlideshowProject(statusData);
                        break;
                    }
                }
            } catch (error) {
                Logger.log(LogLevel.WARN, "Slideshow:_retrySlideshowBasedOnStatus: failed - ", error);
                return;
            }
        }
    }

    private async _onDeleteSlideshow(): Promise<void> {
        if (this.projectId) {
            const message = IntlHandler.getInstance().formatMessage("file-deletion-in-background", { media: "Slideshow" });
            ToastUtils.info(message);

            try {
                const deleteStatus = await CreationUtils.deleteCreation(this.projectId, "all");
                if (deleteStatus) {
                    ToastUtils.success(IntlHandler.getInstance().formatMessage("items-deleted", { itemCount: 1, media: "slideshow" }), { timeout: 2000 });
                    this.notify({ type: SlideshowControllerActions.backSlideshowView });
                    return;
                }
            } catch (error) {
                const elError = new ELError("Slideshow - _onDeleteSlideshow failed", this.getWorkflowConfiguration(), error as Error);
                Logger.log(LogLevel.ERROR, elError);
            }
        }

        ToastUtils.error(IntlHandler.getInstance().formatMessage("failed-deleting-creation"));
    }

    private async _fetchLastVersion(): Promise<void> {
        if (this.projectId) {
            try {
                const deleteStatus = await CreationUtils.deleteCreation(this.projectId, "active");
                if (deleteStatus) {
                    const message = IntlHandler.getInstance().formatMessage("fetching-last-slideshow-version");
                    info(message, { timeout: 0, closable: true });
                    this._retrySlideshowBasedOnStatus(false);
                    return;
                }
            } catch (error) {
                const elError = new ELError("Slideshow:_fetchLastVersion: failed", this.getWorkflowConfiguration(), error as Error);
                Logger.log(LogLevel.ERROR, elError);
            }
        }

        error(IntlHandler.getInstance().formatMessage("failed-fetching-last-version"), { timeout: 2000 });
    }

    private _ingest(payload: Record<string, string>): void {
        this._owner.notify({
            type: WorkspaceActionType.ingest,
            payload: payload
        });
    }

    private _onSlideshowStatusChanged(creationStatusData: CreationStatusData): void {
        this._processSlideshowProject(creationStatusData);
    }

    protected async ingestCreationFeedback(eventSubType: string): Promise<void> {
        try {
            const exportSettings = this.projectData?.outputs?.media.exportSettings as CreationsVideoExportSettings;

            const theme = (this.projectData?.inputs?.templates?.video) ?? "null";
            const audio = (this.projectData?.inputs?.templates?.audio) ?? "null";


            const customEntries: Record<string, string> = {};
            customEntries[IngestLogObjectCustomKey.theme] = theme;
            customEntries[IngestLogObjectCustomKey.audio] = audio;
            customEntries[IngestLogObjectCustomKey.quality] = exportSettings ? exportSettings.preset : CreationsVideoExportPreset.EPR360;

            const additionalLogInfo: Record<string, string> = {};
            for (const key in customEntries) {
                const additionalLogInfoTemp = { ...additionalLogInfo };
                additionalLogInfoTemp[IngestLogObjectKey.contentName] = key;
                additionalLogInfoTemp[IngestLogObjectKey.eventCount] = customEntries[key];
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                    IngestEventSubTypes.info, eventSubType, IngestUtils.getIngestCreationsWorkflowName(this.workflowName), additionalLogInfoTemp));
            }
        } catch (e) {
            Logger.log(LogLevel.WARN, `Slideshow:_ingestCreationFeedback: Dunamis Logging Error:${e as string}`);
        }
    }

    private async _download(): Promise<void> {
        if (!this.projectId) {
            const elError = new ELError("Slideshow:download: invalid project id", this.getWorkflowConfiguration());
            Logger.log(LogLevel.ERROR, elError);
            return;
        }

        await this._updateProjectData(this.projectId);

        if (!this.projectData) {
            const elError = new ELError("Slideshow:download: invalid project data", this.getWorkflowConfiguration());
            Logger.log(LogLevel.ERROR, elError);
            return;
        }

        const message = IntlHandler.getInstance().formatMessage("file-download-in-background",
            { media: this.projectData.operationSubType.toLowerCase() });
        ToastUtils.info(message);

        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.eventContextGuid] = Utils.getRandomUUID();
        additionalLogInfo[IngestLogObjectCustomKey.viewType] = IngestLogObjectValue.workspace;

        try {
            await CreationUtils.downloadCreation(this.projectData);
            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                IngestEventSubTypes.success, IngestEventTypes.download, this.projectData.operationSubType, additionalLogInfo));
        } catch (e) {
            //wait for download start message to go away then show the failed msg
            setTimeout(() => {
                ToastUtils.error(IntlHandler.getInstance().formatMessage("download-fail-toast-msg"), {
                    closable: true,
                    timeout: 0
                });
            }, Constants.TOAST_DEFAULT_TIME_OUT_LIMIT as number);
            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                IngestEventSubTypes.error, IngestEventTypes.download, this.projectData.operationSubType, additionalLogInfo));
            const elError = new ELError("Slideshow:_download: could not download creation", this.getWorkflowConfiguration());
            Logger.log(LogLevel.ERROR, elError);
        }
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);

        this._slideshowPanel = new SlideshowPanel(this);
        this._creationsHeader = new ELCreationsHeader(this, this.shareOptions);

        this._slideshowPanel.createView(this.ensureHTMLElement("slideshow-right-panel-container"));
        this._creationsHeader.createView(this.ensureHTMLElement("slideshow-header-container"));
        this.createFeedbackView(this.ensureHTMLElement("feedback-popover-container"));

        CreationInAppNotifier.subscribe(this, CreationAppSubscriberType.statusChange);
    }

    createView(container: HTMLElement): void {
        super.createView(container)
        const element = React.createElement(SlideshowView, {
            controller: this,
            mode: this.mode,
            status: this.projectData?.status as CreationsStatus
        });

        const provider = React.createElement(Provider, { store }, element);

        ReactDOM.render(
            provider,
            container
        );
    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
        CreationInAppNotifier.unsubscribe(this, CreationAppSubscriberType.statusChange);
    }

    startWorkflow(containerId: string, prevWorkflow?: IWorkflow, action?: WorkflowAction): void {
        super.startWorkflow(containerId, prevWorkflow, action);

        const workflowPayload = action?.payload as CreationsStatusPayload;
        this.mode = action?.initMode as CreationsMode;

        const slideshowPayload: ELCreationWorkflowPayload = {
            initMode: this.mode,
            payload: workflowPayload
        }

        this.enterProject(slideshowPayload);

        this.createView(this.ensureHTMLElement(containerId));
    }

    endWorkflow(): void {
        super.endWorkflow();
        this.projectId && store.dispatch(CreationsAction.resetCreationsData(this.projectId));
    }

    /**
     * Handles ui events generated by views rendered in the workflow
     * @param action ControllerAction
     */
    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case CreationInAppNotifierAction.creationStatusChanged: {
                const creationStatusData = action.payload as CreationStatusData;
                this._onSlideshowStatusChanged(creationStatusData);
                handled = true;
                break;
            }
            case SlideshowControllerActions.notifyOnEmail: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.notifyMe, IngestWorkflowTypes.slideshow));
                this._notifySlideshow();
                handled = true;
                break;
            }
            case SlideshowControllerActions.retrySlideshow: {
                this._retrySlideshowBasedOnStatus();
                handled = true;
                break;
            }
            case SlideshowControllerActions.deleteSlideshow: {
                this._onDeleteSlideshow();
                handled = true;
                break;
            }
            case SlideshowControllerActions.backSlideshowView: {
                const workspacePayload = {
                    startWorkflow: WorkflowsName.creationsHome
                };
                const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
                handled = await this._owner.notify(workspaceAction);
                break;
            }
            case CreationInAppNotifierAction.creationProgressChanged: {
                const { projectId, progress } = action.payload as ELProjectProgressPayload;
                store.dispatch(CreationsAction.updateProgress({
                    projectId: projectId,
                    percent: progress
                }));
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.download: {
                this._download();
                handled = true;
                break;
            }

            default: {
                Logger.log(LogLevel.DEBUG, "Slideshow - ControllerAction: Invalid action" + action);
                break;
            }
        }

        if (!handled)
            handled = await super.notify(action);

        return handled;
    }

    /**
     * Handles core actions
     * @param action WorkflowAction
     */
    protected async notifyWorkflow<T extends WorkflowAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case WorkspaceActionType.startPreviousWorkflow:
                {
                    const workspacePayload = {
                        startWorkflow: WorkflowsName.creationsHome
                    };
                    const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
                    handled = await this._owner.notify(workspaceAction);
                    break;
                }
            case ELSlideshowPanelWorkflowAction.manageMedia:
                {
                    const workspacePayload = {
                        startWorkflow: WorkflowsName.mediaManager,
                        nextWorkflow: WorkflowsName.slideshow,
                        payload: this.mediaGridConfig
                    };
                    const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
                    handled = await this._owner.notify(workspaceAction);
                    break;
                }
            case ELCreationsHeaderWorkflowAction.editSlideshow:
                {
                    this._editSlideshow();
                    handled = true;
                    break;
                }
            case ELCreationsHeaderWorkflowAction.cancelCreation:
                {
                    this._cancelSlideshow();
                    handled = true;
                    break;
                }
            case WorkflowActionType.ingest:
                {
                    this._ingest(action.payload as Record<string, string>);
                    handled = true;
                    break;
                }
            default:
                Logger.log(LogLevel.WARN, "Slideshow(notifyWorkflow): Invalid action" + action);
                break;
        }
        return handled;
    }
}

export default Slideshow;
