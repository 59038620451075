/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { AdjustmentsEditor } from "../../../editors/adjustments/AdjustmentsEditor";
import { AdjustmentsConfig, AdjustmentsEditorInitProps } from "../editing/adjustments/AdjustmentsEditorTypes";

export const enum Edit {
    adjustments = "ADJUSTMENTS"
}

export const enum EditorInputDataType {
    imageData = "IMAGE_DATA"
}

export const editTypeToEditorMap: { [key in Edit]: any } = {
    [Edit.adjustments]: AdjustmentsEditor
}

export interface EditTypeToConfigMap {
    [Edit.adjustments]: AdjustmentsConfig
}

export interface EditTypeToInitPropsMap {
    [Edit.adjustments]: AdjustmentsEditorInitProps
}