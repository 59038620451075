/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third Party
import React from "react";
import { Flex, Heading } from "@adobe/react-spectrum";

//Application Specific
import { ELWhatsNewButtonInfo, ELWhatsNewPanelViewPropsData } from "../../../../common/interfaces/ELWhatsNewTypes";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ELButton, { ELButtonPropVariantType } from "../../atoms/el-button/ELButtonView";

export interface ELWhatsNewPanelViewProviderProps {
    data: ELWhatsNewPanelViewPropsData;
    onButtonPress: (tabName: string, buttonData: ELWhatsNewButtonInfo) => void;
}

export const ELWhatsNewPanelViewProvider = (props: ELWhatsNewPanelViewProviderProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();

    const onButtonPress = (workflowName: string, buttonData: ELWhatsNewButtonInfo): void => {
        props.onButtonPress(workflowName, buttonData);
    };

    const getMediaElement = (): React.ReactElement => {
        if (props.data.itemContent.mediaType === "image") {
            return <img crossOrigin="anonymous" onContextMenu={(e) => e.preventDefault()} className="whats-new-dialog__media" alt="feature-data" src={props.data.itemContent.contentURL} />;
        } else {
            return <video crossOrigin="anonymous" className="whats-new-dialog__media" src={props.data.itemContent.contentURL} onContextMenu={(e) => e.preventDefault()} playsInline loop autoPlay muted />;
        }
    };

    const getButtonVariant = (buttonCtaData: string): ELButtonPropVariantType => {
        if (buttonCtaData === "cta") {
            return "cta";
        }
        return "secondary";
    }

    const getButtonsElement = (): React.ReactElement => {
        return (
            <Flex UNSAFE_className="whats-new__icon-button-box">
                <ELButton size="L" variant={getButtonVariant(props.data.itemContent.buttonData[0].ctaType)}
                    onClick={() => onButtonPress(props.data.itemContent.workflowName, props.data.itemContent.buttonData[0])}>
                    <span className="whats-new-dialog__cta-text">{intlHandler.formatMessage(props.data.itemContent.buttonData[0].label)}</span>
                </ELButton>
            </Flex>
        );
    };

    return (
        <>
            {getMediaElement()}
            <Heading UNSAFE_className="whats-new-tab-body__heading">{intlHandler.formatMessage(props.data.itemContent.tabTitle)}</Heading>
            <p className="whats-new-tab-body__description">{intlHandler.formatMessage(props.data.itemContent.tabDescription[0].desc)}</p>
            {getButtonsElement()}
        </>
    );
};