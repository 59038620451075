/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { CanvasProps } from "../../../../editors/IEditor";

export type AdjustmentsParams = {
    temperature?: number
    tint?: number
    exposure?: number
    contrast?: number
    highlights?: number
    shadows?: number
    whites?: number
    blacks?: number
    clarity?: number
    vibrance?: number
    saturation?: number
    vignette_amount?: number
    vignette_roundness?: number
    vignette_midpoint?: number
    vignette_feather?: number
    vignette_highlight?: number

    [key: string]: number | undefined
}

export interface AdjustmentsConfig {
    params: AdjustmentsParams,
    intensity: number
}

type MinMax = {
    min: number
    max: number
}

export type AdjustmentsParamsMinMax = {
    temperature: MinMax
    tint: MinMax
    exposure: MinMax
    contrast: MinMax
    highlights: MinMax
    shadows: MinMax
    whites: MinMax
    blacks: MinMax
    clarity: MinMax
    vibrance: MinMax
    saturation: MinMax
    vignette_amount: MinMax
    vignette_roundness: MinMax
    vignette_midpoint: MinMax
    vignette_feather: MinMax
    vignette_highlight: MinMax

    [key: string]: MinMax | undefined
}

export const DEFAULT_INTENSITY_VALUE = 1;
export const DEFAULT_PRESET_ID = "";

export type AdjustmentsEditorInitProps = CanvasProps;