/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { Edit } from "../../common/interfaces/editing/EditorTypes";
import IDoc from "../document/IDoc";

interface IDocEditingManager<DocEditingConfigType> {
    applyEdit(editName: Edit, config: DocEditingConfigType): Promise<void>;
}

export abstract class DocEditingManager<DocType extends IDoc, DocEditingConfigType> implements IDocEditingManager<DocEditingConfigType> {
    protected doc: DocType;
    
    constructor(doc: DocType) {
        this.doc = doc;
    }

    abstract applyEdit(editName: Edit, config: DocEditingConfigType): Promise<void>;
    abstract resetEdit(editName: Edit): Promise<void>;
    abstract initializeEdit(editName: Edit): void;
    abstract setDataForEdit(editName: Edit): Promise<void>;
    abstract commitEdits(deInitializeEditor: boolean): Promise<void>;
}