/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { ImportCompleteState } from "../common/interfaces/import/ImportProgressTypes"

/*
    These constants should be changed after discussion since it can break the query for analytics
*/


export const IngestLogObjectKey = {
    eventWorkflow: "event.workflow",
    eventSubcategory: "event.subcategory",
    eventType: "event.type",
    eventSubtype: "event.subtype",
    eventValue: "event.value",
    eventUrl: "event.url",
    eventReferrer: "event.referrer",
    eventGuid: "event.guid",
    eventDtsEnd: "event.dts_end",
    eventCategory: "event.category",
    eventUserGuid: "event.user_guid",
    eventOffline: "event.offline",
    eventUserAgent: "event.user_agent",
    eventLanguage: "event.language",
    eventDeviceGuid: "event.device_guid",
    eventSessionGuid: "event.session_guid",
    eventBuild: "event.build",
    sourceClientId: "source.client_id",
    sourceName: "source.name",
    sourceVersion: "source.version",
    sourcePlatform: "source.platform",
    sourceDevice: "source.device",
    sourceOSVersion: "source.os_version",
    userServiceCode: "user.service_code",
    userServiceLevel: "user.service_level",
    errorDescription: "event.error_desc",
    eventContextGuid: "event.context_guid",
    contentName: "content.name",
    eventCount: "event.count",
    eventVisitorGuid: "event.visitor_guid",
    uiSearchKeyword: "ui.search_keyword",
    expCampaignId: "exp.campaign_id",
    contentSource: "event.content_source"
}

export const IngestLogObjectValue = {
    eventCategory: "WEB",
    sourceName: "Elements Web",
    photo: "Photo",
    video: "Video",
    ascending: "Ascending",
    descending: "Descending",
    workspace: "workspace",
    grid: "grid",
    creations: "creations",
    media: "media",
    siv: "siv",
    sharedLink: "Shared-Link",
    startTrial: "start trial",
    continueTrial: "continue trial - ",
    trialExpired: "trial expired",
    paid: "paid",
    dialog: "dialog",
    trialModal: "trial-modal",
    saveCopy: "save-copy",
    exitSaveCopy: "exit-save-copy",
    replaceMediaSaveCopy: "replace-media-save-copy",
    intensitySlider: "intensity-slider",
    reset: "reset",
    yes: "yes",
    no: "no",
    popUp: "pop-up"
}

export const IngestLogObjectCustomKey = {
    timeTaken: "time_taken",
    theme: "theme",
    audio: "audio",
    title: "title",
    photoCount: "photo_count",
    videoCount: "video_count",
    totalCount: "total_count",
    quality: "quality",
    countSuffix: "_count",
    screenResolution: "screen_resolution",
    viewType: "ui.view_type",
    layoutName: "layout",
    layoutCategory: "category",
    background: "background",
    customColor: "custom_color",
    shareChannel: "share_channel",
    totalSize: "total_size",
    landingURL: "landing_url",
    overlay: "overlay",
    socialLayoutId: "social_layout",
    socialLayoutScaling: "social_layout_scaling_used",
    cardHeading: "card_heading",
    moveToolUsed: "move-tool-used",
    moveToolTransformationUsed: "transformation-used",
    source: "source",
    looksPresetUsed: "looks-preset-used",
    titleRenamed: "title-renamed",
}

/*
    In the case of view to view notification
    eg. utility nav we need App Workspace
    - Handle such situation case by case
*/
export const INGEST_APP_WORKSPACE = "App";
export const INGEST_PROFILE_WORKSPACE = "Profile";

export const IngestWorkflowTypes = {
    siv: "SIV",
    sivMedia: "SIV Media",
    mediaGrid: "Media",
    mediaGridHasMoreData: "Media Grid hasMoreData",
    mediaGridPhotos: "Media Grid Photos",
    mediaGridVideos: "Media Grid Videos",
    mediaGridStacks: "Media Grid Stacks",
    userIcon: "User Icon",
    import: "Import",
    importStartStorage: "Import Start Storage",
    importEndStorage: "Import End Storage",
    importInvalidFormat: "Import Invalid Format",
    utilityNav: "Utility Nav",
    tabs: "Tabs",
    branding: "Branding",
    storage: "Storage",
    noAccessUserHome: "No Access User Home",
    userHome: "Home",
    publicLanding: "Public Landing",
    creationHome: "Creations",
    recentCreations: "Recent Creations",
    creations: "Creation Workflow",
    slideshow: "Slideshow",
    preview: "Preview",
    createOnDemand: "Create On Demand",
    collage: "Collage",
    manageLink: "Manage Link",
    cookies: "Cookies",
    trialNotStartedUserHome: "Trial Not Started User Home",
    trialExpiredUserHome: "Trial Expired User Home",
    trialInProgressUserHome: "Trial In Progress User Home",
    handleSignIn: "Handle Sign In",
    refreshTrial: "Refresh Trial",
    patternOverlay: "Pattern Overlay",
    movingOverlay: "Moving Overlay",
    replaceBackground: "Replace Background",
    operations: "Operations",
    popUp: "Pop up",
    workspace: "Workspace",
    selection: "Selection",
    app: "App",
    trial: "Trial",
    feedback: "Feedback",
    consentScreen: "Consent screen",
    email: "Email",
    link: "Link",
    facebook: "Facebook",
    sharedLink: "Shared Link",
    peekThrough: "Peek Through",
    eliveBanner: "ELive Banner",
    eliveCard: "Elive Card",
    navbar: "Navbar",
    errorDialogOpenDesktop: "Error-dialog-open-desktop",
    download: "Download",
    jarvis: "Jarvis",
    utility: "Utility",
    whatsNew: "whats new",
    cardCategory: "card category",
    dislikeFeedback: "dislike-feedback",
    imageEdit: "Image Edit",
    photoText: "Photo Text",
    featureFlag: "Feature Flag",
}

export const IngestEventTypes = {
    info: "Info",
    success: "success",
    error: "error",
    click: "Click",
    change: "Change",
    render: "Render",
    delete: "Delete",
    download: "Download",
    filter: "Filter",
    sort: "Sort",
    select: "Select",
    deselect: "Deselect",
    close: "Close",
    signOut: "Sign-Out",
    privacy: "Privacy",
    cookiepreferences: "Cookie-Preferences",
    tou: "TOU",
    share: "Share",
    feedback: "Feedback",
    faq: "FAQ",
    supportCommunity: "Support Communtiy",
    signIn: "Sign-in",
    SignInThroughShareLink: "Sign in through share link",
    rename: "rename",
    notify: "notify",
    rendition: "rendition",
    create: "Create",
    discardLink: "discard-link",
    fetchAccess: "Fetch Access Info",
    trialExpired: "Trial Expired",
    update: "Update",
    open: "Open",
    trialType: "trial-type",
    downloadButton: "download-button",
    startTrialLater: "start-trial-later",
    startTrial: "start-trial",
    chatInitialized: "chat-initialized",
    chatEnd: "chat-end",
    redirect: "redirect",
    looks: "looks",
    init: "init",
    search: "search"
}

export const IngestEventSubTypes = {
    count: "Count",
    siv: "SIV",
    success: "Success",
    error: "Error",
    all: "All",
    import: "Import",
    click: "Click",
    type: "Type",
    chevron: "Chevron",
    delete: "Delete",
    info: "Info",
    infoPanelClose: "Info Panel Close",
    download: "Download",
    share: "Share",
    manage: "Manage",
    close: "Close",
    reportAbuse: "Report Abuse",
    importDate: "Imported",
    modifiedDate: "Modified",
    createdDate: "Created",
    anonymous: "Anonymous",
    icon: "Icon",
    start: "Start",
    continue: "Continue",
    doNotShowAgain: "do-not-show-again",
    cancel: "Cancel",
    sendCopy: "send-me-a-copy",
    sendEmail: "send-email",
    copy: "Copy",
    shareDialog: "Share Dialog",
    getStarted: "Get Started",
    signIn: "Sign in",
    back: "back",
    retry: "retry",
    edit: "edit",
    editWorkspaceRender: "edit-workspace-render",
    apply: "apply",
    active: "active",
    manageMedia: "manage_media",
    replaceMedia: "replace_media",
    mediaReplaced: "Media replaced",
    mediaDeleted: "Media deleted",
    media: "Number of Media used",
    layout: "Layout",
    backgroundImage: "Background image",
    backgroundColor: "Background color",
    backgroundPSD: "Background embeddedFromPSD",
    move: "Move",
    rotate: "Rotate",
    zoom: "Zoom",
    format: "Format",
    startTrial: "Start-Trial",
    createCreations: "create-creations",
    buyNow: "Buy Now",
    continueTrial: "Continue Trial",
    learnMore: "Learn More",
    troubleShoot: "Troubleshoot",
    slideshowSettings: "Slideshow Settings",
    done: "Done",
    notifyMe: "notify-me",
    audio: "audio",
    theme: "theme",
    titleSlide: "title-slide",
    background: "background",
    replace: "replace",
    colorPicker: "colorpicker",
    shuffle: "shuffle",
    stopImport: "stop-import",
    deSelectAll: "de-select-all",
    infoPanel: "info-panel",
    storageLimit: "storage-limit",
    storageUtilized: "storage-utilized",
    storageQuotaFull: "storage-quota-full",
    buyElements: "buy-elements",
    takeSurvey: "take-survey",
    troubleshootAccess: "troubleshoot-access",
    downloadDesktop: "download-desktop",
    submitFeedback: "submit-feedback",
    overlay: "overlay",
    before: "before",
    after: "after",
    backDialogDontSave: "dont-save",
    socialLayoutName: "social-layout-name",
    socialLayoutRevert: "social-layout-revert",
    socialLayoutApply: "social-layout-apply",
    socialLayoutCancel: "social-layout-cancel",
    socialLayoutAutoCancel: "social-layout-auto-cancel",
    watchVideo: "watch-video",
    tryNow: "try-now",
    feedbackLike: "like",
    feedbackDislike: "dislike",
    feedbackDialog: "feedback-dialog",
    openInDesktop: "open-in-desktop",
    ok: "Ok",
    languagePreference: "language-preference",
    quality: "quality",
    speed: "speed",
    editing: "editing",
    others: "others",
    render: "render",
    moveTool: "move-tool",
    moveToolTransformationUsed: "move-tool-transformation",
    save: "save",
    addFromComputer: "add-from-computer",
    featureFlagName: "feature-flag-name",
    stock: "stock"
}

export const IngestImportState: { [key in ImportCompleteState]: string } = {
    [ImportCompleteState.success]: "Success",
    [ImportCompleteState.successWithSkipped]: "Success With Skipped",
    [ImportCompleteState.partialUpload]: "Partial Upload",
    [ImportCompleteState.partialWithSkipped]: "Partial With Skipped",
    [ImportCompleteState.storageQuotaFull]: "Storage Quota Full",
    [ImportCompleteState.noSupportedFile]: "No Supported File",
    [ImportCompleteState.unreachableServer]: "Unreachable Server",
    [ImportCompleteState.internetConnectionLost]: "Internet Connection Lost",
    [ImportCompleteState.importStopped]: "Import Stopped"
}

export enum ShareInvocationPoint {
    mediaGrid = "Media Grid",
    siv = "SIV",
    desktopWebShare = "Desktop Web Share",
    managelink = "Manage Link",
    grid = "Grid",
    workspace = "Workspace"
}

export enum IngestUserType {
    paid = "Paid",
    trial = "Trial"
}

export enum IngestCreationWorkflows {
    slideshow = "slideshow",
    photoCollage = "photocollage",
    patternOverlay = "patternoverlay",
    movingOverlay = "movingoverlay",
    replaceBackground = "replacebackground",
    peekThrough = "peek-through-overlay",
    photoText = "photo-text"
}

export enum IngestOSValue {
    macos = "macos",
    windows = "windows"
}