/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import { Provider } from "react-redux";

//Application Specific
import SharedMediaView, { SharedMediaControllerAction } from "./SharedMediaView";
import { ViewAction } from "../../../../view/IBaseController";
import IBaseWorkspace, { WorkspaceActionType } from "../../../IBaseWorkspace";
import IWorkflow, { WorkflowAction, WorkflowActionType, WorkflowsName } from "../../../IWorkflow";
import ELMediaGrid, { ELMediaGridControllerAction, ELMediaGridData } from "../../../../view/components/organism/el-mediagrid/ELMediaGrid";
import useMediaFetch, { MediaSource } from "../../../../utils/hooks/useMediaFetch";
import store from "../../../../stores/store";
import { ControllerAction } from "../../../../view/IViewController";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { ShareOptions } from "../../../../view/components/organism/el-share-options/ELShareOptions";
import { LinkParams } from "../../../../utils/Constants/Constants";
import { SIVWorkflowPayload } from "../../../organizer/workflows/singleImage/SingleImage";
import { IconType } from "../../../../assets/IconConstants";
import { ELEmptyBannerSharedGridView } from "../../../../view/components/organism/el-empty-banner-sharedgrid/ELEmptyBannerSharedGridView";
import { ShareAction } from "../../../../stores/actions/ShareAction";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectCustomKey, IngestLogObjectKey, IngestWorkflowTypes, ShareInvocationPoint } from "../../../../utils/IngestConstants";
import { ShareUtils } from "../../../../utils/ShareUtils";
import Utils from "../../../../utils/Utils";
import { ShareOptionsActionType } from "../../../../view/components/organism/el-share-options/ELShareOptionsView";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { LinkInfo, ShareMode } from "../../../../common/interfaces/share/ShareTypes";
import { ClipboardService } from "../../../../services/ClipboardService";
import { ToastUtils } from "../../../../utils/ToastUtils";
import { AdobeAsset } from "@dcx/common-types";
import { ELMediaSelectionMode } from "../../../../common/interfaces/media/ELThumbTypes";
import { IngestUtils } from "../../../../utils/IngestUtils";

export class SharedMedia extends IWorkflow {
    private _mediaGrid?: ELMediaGrid;
    private _shareOptionsController?: ShareOptions;

    private readonly _mediaGridLayoutConfig = {
        TILE_W: 16.75,
        TILE_H: 14.5,
        SECTION_HEADING_H: 3.125,
        BUF_SIZE: 1500,
        Y_DIST_BETWEEN_TILE: 18,
        X_DIST_BETWEEN_TILE: 18,
        Y_DIST_BETWEEN_SECTION: 4.375,
        OFFSET_PERCENTAGE_X: 5,
        Y_OFFSET_TOP: 1.875,
        Y_OFFSET_BOTTOM: 1.875
    };
    private _SIVIconList: Array<IconType> = [IconType.download];
    private _isOwner = false;

    constructor(owner: IBaseWorkspace) {
        super(owner, WorkflowsName.sharedMedia);
    }

    // View Controller methods
    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        if (this._shareOptionsController) {
            this._shareOptionsController.setOptions = ([
                {
                    key: "Link",
                    src: IconType.copyLink,
                    text: "copy-link",
                    shareMode: ShareMode.link
                },
                ...ShareUtils.getDefaultShareOptionsForShareModes([ShareMode.facebook, ShareMode.email])
            ]);
        }
    }

    createView(container: HTMLElement): void {
        super.createView(container);
        if (this._shareOptionsController) {
            const collectionId = (new URLSearchParams(window.location.search))?.get(LinkParams.ELEMENTS_COLLECTION_ID_PARAM) ?? "";
            const sharedMediaView = React.createElement(SharedMediaView, {
                controller: this,
                collectionId: collectionId,
                shareOptionController: this._shareOptionsController
            });
            const reactReduxProvider = React.createElement(Provider, { store: store }, sharedMediaView);
            const mediaSourceType = MediaSource.sharedMediaDirFetch;
            const sharedMediaFetchHook = _.partial(useMediaFetch, _, mediaSourceType);

            const mediaBanner = React.createElement(ELEmptyBannerSharedGridView);

            ReactDOM.render(
                reactReduxProvider,
                container,
                () => {
                    const mediaGridData: ELMediaGridData = {
                        workflow: this,
                        mediaFetchHookFunc: sharedMediaFetchHook,
                        dirPath: collectionId,
                        createTracks: true,
                        emptyGridBanner: mediaBanner,
                        selectionEnabled: false,
                        selectionMode: ELMediaSelectionMode.DEFAULT
                    };
                    // TODO: diagarwa: urgent: Scroll bar will be visible in wrong location right not. Will refactor in next PR
                    this._mediaGrid = new ELMediaGrid(mediaGridData, this._mediaGridLayoutConfig);
                    this._mediaGrid.createView(this.ensureHTMLElement("shared-media-container__media-grid"));
                }
            );
        } else {
            Logger.log(LogLevel.DEBUG, "SharedMedia: createView: shareOptionsController undefined");
        }
    }

    destroyView(): void {
        this._mediaGrid?.destroyView();
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container);
        }
        super.destroyView();
    }

    private _generatePublicLinkFromURL(): string | undefined {
        const collectionId = Utils.getLinkParamValue(window.location.href, LinkParams.ELEMENTS_COLLECTION_ID_PARAM);
        if (collectionId) {
            const link = ShareUtils.makePublicLink(collectionId);
            return link;
        }

        Logger.log(LogLevel.WARN, "SharedMedia:_updatePublicLinkFromURL: ", "Collection not found in the URL");
        return undefined;
    }

    private _openShareDialog(mediaList: string[], workflowName?: WorkflowsName): void {
        this._shareOptionsController?.notify({ type: ShareOptionsActionType.openShareWorkspace, payload: { mediaList: mediaList, workflowName: workflowName } });
    }

    private _handleCopyLink(link: string): void {
        const intlHandler = IntlHandler.getInstance();
        ClipboardService.writeText(link);
        ToastUtils.success(intlHandler.formatMessage("link-copied-successfully"));
    }

    private _getSharedAssets(): AdobeAsset[] {
        const collectionId = Utils.getLinkParamValue(window.location.href, LinkParams.ELEMENTS_COLLECTION_ID_PARAM);
        const assetsShared = collectionId ? store.getState().mediaOrganizerReducer[collectionId]?.children ?? [] : [];

        return assetsShared;
    }

    private additionalIngestShareInfo(sharedAssets: AdobeAsset[]): void {
        const shareType = IngestUtils.shareTypeToIngest(store.getState().shareReducer.shareType);
        const viewType = store.getState().shareReducer.viewType;
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.eventContextGuid] = Utils.getRandomUUID();
        if (viewType)
            additionalLogInfo[IngestLogObjectCustomKey.viewType] = viewType;

        if (sharedAssets.length > 0) {
            const customEntries: Record<string, string> = IngestUtils.getMediaLoggingInfo(sharedAssets);
            const eventContextId = Utils.getRandomUUID();

            for (const key in customEntries) {
                const additionalLogInfoTemp = { ...additionalLogInfo };
                additionalLogInfoTemp[IngestLogObjectKey.eventContextGuid] = eventContextId;
                additionalLogInfoTemp[IngestLogObjectKey.contentName] = key;
                additionalLogInfoTemp[IngestLogObjectKey.eventCount] = customEntries[key];
                this.notify({
                    type: WorkflowActionType.ingest,
                    payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                        IngestEventSubTypes.info, IngestEventTypes.share, shareType, additionalLogInfoTemp)
                });
            }
        }
    }

    private _handleShare(mediaList: string[], shareCompleteCallback: () => void): void {
        const shareMode = store.getState().shareReducer.shareMode;
        const link = this._generatePublicLinkFromURL();
        const sharedAssets = this._getSharedAssets();
        const sharedAssetsCount = sharedAssets.length;

        if (link) {
            const linkInfo: LinkInfo = {
                link: link,
                sharedAssetsCount: sharedAssetsCount
            }

            store.dispatch(ShareAction.updateLinkInfo(linkInfo));
            if (shareMode === ShareMode.link)
                this._handleCopyLink(link);
            else {
                const workflowName = ShareUtils.getShareWorkflowName;
                this._openShareDialog(mediaList, workflowName);
                shareCompleteCallback();
            }
        } else {
            this._openShareDialog(mediaList);
            shareCompleteCallback();
        }

        this.notify({
            type: WorkflowActionType.ingest,
            payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.manageLink,
                IngestEventSubTypes.click, shareMode, null)
        });
        ShareUtils.ingestShareStart(ShareInvocationPoint.managelink, this.notify.bind(this));
        this.additionalIngestShareInfo(sharedAssets);
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case SharedMediaControllerAction.setIsOwner:
                {
                    const isOwner = !!action.payload;
                    this._isOwner = isOwner;
                    this._setIconListForSIV();
                    handled = true;
                    break;
                }
            case SharedMediaControllerAction.handleShare:
                {
                    const payload = action.payload as Record<string, unknown>;
                    const mediaList = payload.mediaList as string[];
                    const closeManageLinkDialog = payload.closeManageLinkDialog as () => void;
                    this._handleShare(mediaList, closeManageLinkDialog);
                    handled = true;
                    break;
                }
            default:
                Logger.log(LogLevel.DEBUG, "Incorrect action is being passed", action);
                break;
        }

        if (!handled)
            handled = await this.notifyWorkflow(action as WorkflowAction);

        return handled;
    }

    // Workflow methods
    protected async notifyWorkflow<T extends WorkflowAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELMediaGridControllerAction.startSIV:
                {
                    let payload = action?.payload as SIVWorkflowPayload;
                    payload = { ...payload, iconList: this._SIVIconList };
                    const workspaceAction = { type: WorkspaceActionType.startWorkflow, startWorkflow: WorkflowsName.singleImageView, payload: payload };
                    handled = await this._owner.notify(workspaceAction);
                    break;
                }
            default:
                handled = await super.notifyWorkflow(action);
                break;
        }
        return handled;
    }

    // Base controller method
    destroy(): void {
        super.destroy();
    }

    // Workflow lifecycle methods
    startWorkflow<T extends WorkflowAction>(containerId: string, prevWorkflow?: IWorkflow, action?: T): void {
        super.startWorkflow<T>(containerId, prevWorkflow, action);
        this._shareOptionsController = action?.payload as ShareOptions;
        this.createView(this.ensureHTMLElement(containerId));
    }

    // Own methods
    private _setIconListForSIV(): void {
        this._SIVIconList = [IconType.download, IconType.info];
        if (this._isOwner === true) {
            this._SIVIconList.push(IconType.share);
        } else {
            this._SIVIconList.push(IconType.reportAbuse);
        }
    }
}
