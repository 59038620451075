/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";

//Adobe Internal
import { Content, Dialog, DialogContainer, Divider, Flex, Heading } from "@adobe/react-spectrum";

//Application Specific
import ELButton from "../../atoms/el-button/ELButtonView";

//CSS
import "./ELStockLicenseAndDownloadDialog.scss";

export interface ELStockLicenseAndDownloadDialogProps {
    heading: string,
    headingDescription: string,
    dialogDescription: string,
    dontSaveButtonText: string,
    saveButtonText: string,
    onDismiss: () => void,
    onSave: () => void,
    onDontSave: () => void,
}

const ELStockLicenseAndDownloadDialog = (props: ELStockLicenseAndDownloadDialogProps): React.ReactElement => {

    return (
        <DialogContainer onDismiss={props.onDismiss} isDismissable>
            <Dialog data-testid="stock-license-download-dialog" UNSAFE_className="stock-license-download-dialog__container">
                <Heading>
                    <div data-testid="stock-license-download-dialog-heading" className="stock-license-download-dialog__heading">{props.heading}</div>
                </Heading>
                <Content>
                    <Flex marginTop="size-200" gap="size-400">
                        <div data-testid="stock-license-download-dialog-heading-description" className="stock-license-download-dialog__heading-description" >{props.headingDescription}</div>
                    </Flex>
                    <Divider data-testid="stock-license-download-dialog-divider" size="S" margin={"4px 0px 4px 0px"} />
                    <Flex gap="size-500" direction="column" alignItems={"center"}>
                        <Flex marginTop="size-200" justifyContent={"center"}>
                            <div data-testid="stock-license-download-dialog-description" className="stock-license-download-dialog__description" >
                                {props.dialogDescription}
                            </div>
                        </Flex>
                        <Divider data-testid="stock-license-download-dialog-inner-divider" size="S" />
                        <Flex gap="size-100" alignSelf="end">
                            <ELButton data-testid="stock-license-download-dialog-dont-save-button" variant="primary" onClick={props.onDontSave}>
                                <div className="stock-license-download-dialog__button-text">
                                    {props.dontSaveButtonText}
                                </div>
                            </ELButton>
                            <ELButton data-testid="stock-license-download-dialog-save-button" variant="cta" onClick={props.onSave}>
                                <div className="stock-license-download-dialog__button-text">
                                    {props.saveButtonText}
                                </div>
                            </ELButton>
                        </Flex>
                    </Flex>
                </Content>
            </Dialog>
        </DialogContainer>
    )
}

export default ELStockLicenseAndDownloadDialog;