/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

//Adobe Internal
import {
    ContentEntity,
    ContentType,
    CreationOperationSubTypeConfiguration,
    CreationOperationTypeInfo,
    CreationOperationTypeOutput,
    ELAdobeAsset,
    MovingOverlayCreationOperationSubTypeConfiguration,
    MovingOverlayCreationOperationSubTypeInfo,
    MovingOverlayJobUtils,
    MovingOverlayOutputAssetsPath,
    OutputType,
    Request,
    Resolution,
    ResourceType,
    TemplateId,
} from "@elements/elementswebcommon";


//Application Specific
import {
    CSAssetWithData,
    CreationWorkflowActions,
    CreationsJobProjectSubType,
    CreationsJobStorageType,
    CreationsMode,
    CreationsStatus,
    CreationsStatusPayload,
    ELCreationWorkflowPayload,
    ELViewType,
    UNTITLED_INTL_KEY,
} from "../../../../common/interfaces/creations/CreationTypes";
import {
    ELCreateAndEditProjectParams,
    ELPreviewCreationThumbData,
    ELRecommendationWorkflowControllerActions,
    ELRecommendationWorkflowViewActions,
    ELRecommendationsOutputJsonConfigData,
    ELThumbUpdateProps
} from "../../../../common/interfaces/creations/ELRecommendationsWorkflowTypes";
import CreationsAction, { CreationStatusPayload, CreationsThumb } from "../../../../stores/actions/CreationsAction";
import store from "../../../../stores/store";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { ViewAction } from "../../../../view/IBaseController";
import { ControllerAction } from "../../../../view/IViewController";
import IBaseWorkspace, { WorkspaceActionType, WorkspacePayload } from "../../../IBaseWorkspace";
import IWorkflow, { WorkflowAction, WorkflowActionType, WorkflowsName } from "../../../IWorkflow";
import MovingOverlayView from "./MovingOverlayView";
import { MovingOverlayJobCreator } from "./utils/MovingOverlayJobCreator";
import MovingOverlayUtils from "./utils/MovingOverlayUtils";
import {
    CreationAppSubscriberType,
    CreationInAppNotifierAction,
    CreationStatusData,
    ELProjectProgressPayload,
    ELRecommendationsProgressPayload,
    RecommendationsAppSubscriberType,
    RecommendationsInAppNotifierAction,
    RecommendationsStatusData
} from "../../../../common/interfaces/creations/CreationInAppNotifierTypes";
import { ReplaceAssetInfo } from "../../../../common/interfaces/creations/ELCollageTypes";
import { ELCreationsHeaderControllerAction } from "../../../../common/interfaces/creations/ELCreationsHeaderTypes";
import { ELMovingOverlayCreationRequestParams, ELMovingOverlayControllerActions, ELMovingOverlayOutputAssets, ELMovingOverlayUpdateDocumentPayload } from "../../../../common/interfaces/creations/ELMovingOverlayTypes";
import { ELAdobeAssetControllerAction } from "../../../../common/interfaces/creations/templates/ELAdobeAssetDocTypes";
import { DocumentActions, DocumentDirty, DocumentSaveStatus } from "../../../../common/interfaces/document/DocumentTypes";
import { CanvasZoomLevelAction } from "../../../../common/interfaces/stage/StageTypes";
import { ReplaceMediaManagerMode, ReplaceMediaManagerWorkflowAction } from "../../../../common/interfaces/workflows/ReplaceMediaManagerTypes";
import { ELAdobeAssetDocPayload } from "../../../../editors/adobeAsset/ELAdobeAssetDoc";
import ELVideoDoc from "../../../../editors/videoDoc/ELVideoDoc";
import { FeaturesManager } from "../../../../modules/floodgate/Featuresmanager";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { FeatureName } from "../../../../services/Floodgate/FloodgateConstants";
import { StorageService } from "../../../../services/StorageServiceWrapper";
import DocActions from "../../../../stores/actions/DocActions";
import FullResMediaAction from "../../../../stores/actions/FullResMediaAction";
import { RecommendationWorkflowAction } from "../../../../stores/actions/RecommendationWorkflowAction";
import Constants from "../../../../utils/Constants/Constants";
import { HistoryUtils } from "../../../../utils/HistoryUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectCustomKey, IngestLogObjectKey, IngestLogObjectValue, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { ToastUtils } from "../../../../utils/ToastUtils";
import Utils from "../../../../utils/Utils";
import ELMovingOverlayHeader from "../../../../view/components/templates/el-creations-header/ELMovingOverlayHeader";
import CreationInAppNotifier from "../../utils/CreationInAppNotifier";
import { ELContentCacheDownloader } from "../../utils/ELContentCacheDownloader";
import RecommendationsInAppNotifier from "../../utils/RecommendationsInAppNotifier";
import CollageUtils from "../collage/utils/CollageUtils";
import { CreationMediaActionType } from "../../../../view/components/templates/el-creation-media-panel/ELCreationMediaView";
import DualViewAction from "../../../../stores/actions/DualViewAction";
import { ELRecommendationBatchProcessor } from "../../../../modules/elBatchProcessing/elBatchProcessor/ELRecommendationBatchProcessor";
import { DEFAULT_NUMBER_OF_RETRIES, ELBatchRecommendationStatusData, ELCreationBatchActionTypes, ELRecommendationBatchProcessorRequestInfo, ELRecommendationBatchProgressData } from "../../../../common/interfaces/modules/elBatchHandler/ELCreationBatchHandlerTypes";
import BatchHandlingWorkflow from "../BatchHandlingWorkflow";
import CreationUtils from "../../utils/CreationUtils";
import { IBaseRequestParams } from "../../../../common/interfaces/creations/ELCreationsJobTypes";
import ELPanelManager from "../../../../view/components/templates/el-panel-manager/ELPanelManager";
import ELMovingOverlayPanelProvider from "../../utils/panelProvider/ELMovingOverlayPanelProvider";
import { ELTabPanelType } from "../../../../common/interfaces/tabpanel/ELTabPanelTypes";
import { ELVideoDocActions } from "../../../../common/interfaces/document/ELVideoDocTypes";
import { CreationsJobCreator } from "../../utils/CreationsJobCreator";
import { ELExportOption } from "../../../../common/interfaces/export/ELExportTypes";
import { ELError } from "../../../../modules/error/ELError";

class MovingOverlay extends BatchHandlingWorkflow<ELAdobeAssetDocPayload> {

    protected updateRecommendationWorkflowRoute(): void {
        throw new Error("Method not implemented.");
    }

    private _creationsHeader!: ELMovingOverlayHeader;
    private _leftTabPanel!: ELPanelManager;
    private _rightTabPanel!: ELPanelManager;
    private _movingOverlayPayload!: ELCreationWorkflowPayload;
    private _templateIdToOutputAssets?: Map<TemplateId, ELMovingOverlayOutputAssets>;
    private _overlayIdAudioIdMap?: Map<string, string>;

    private readonly _leftTabPanelContainer = "moving-overlay-left-panel-container";
    private readonly _rightTabPanelContainer = "moving-overlay-right-panel-container";
    private readonly _feedbackContainer = "feedback-popover-container";
    private readonly _editPanelContainer = "moving-overlay-edit-container";
    private readonly _creationsHeaderContainer = "moving-overlay-creations-header-container";
    private readonly _defaultContentResolution = Resolution._720p;

    constructor(owner: IBaseWorkspace) {
        super(owner, WorkflowsName.movingOverlay);
        this.mediaGridConfig = MovingOverlayUtils.getMovingOverlayMediaGridConfig();
    }

    protected getJobCreator(): CreationsJobCreator {
        return new MovingOverlayJobCreator();
    }

    // overriding
    protected updateStoreStateOnExit(): void {
        store.dispatch(RecommendationWorkflowAction.updateProjectStatus(CreationsStatus.success));
        store.dispatch(DocActions.updateDocumentSaveStatus(DocumentSaveStatus.saved));
        this.doc?.markAndNotifyDocumentDirty(DocumentDirty.NON_DIRTY);
    }

    protected logIngestData(creationStatus: string, errorInfo?: string): void {
        try {
            const allMedia = store.getState().selectedMediaListReducer;

            const customEntries: Record<string, string> = IngestUtils.getMediaLoggingInfo(allMedia);
            customEntries[IngestLogObjectCustomKey.totalCount] = allMedia.length.toString();

            const selectedOverlayName = store.getState().recommendationWorkflowReducer.selectedOverlayName;

            if (selectedOverlayName) {
                customEntries[IngestLogObjectCustomKey.overlay] = selectedOverlayName;
            }

            const eventContextId = Utils.getRandomUUID();
            const additionalLogInfo: Record<string, string> = {};
            additionalLogInfo[IngestLogObjectKey.eventContextGuid] = eventContextId;

            if (errorInfo)
                additionalLogInfo[IngestLogObjectKey.errorDescription] = errorInfo;

            if (!this.ingestParams.subType)
                this.ingestParams.subType = this.mode;

            const timeElapsed = Utils.getDateDifference(this.startDate, new Date(), "second");
            customEntries[IngestLogObjectCustomKey.timeTaken] = timeElapsed.toString();
            //TODO: Sundaram
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const workflowName = IngestUtils.getIngestCreationsWorkflowName(this.getWorkflowName!);

            if (this.ingestParams.subType === CreationsMode.save && this.ingestParams.eventViewType) {
                additionalLogInfo[IngestLogObjectCustomKey.viewType] = this.ingestParams.eventViewType;
            }

            for (const key in customEntries) {
                const additionalLogInfoTemp = { ...additionalLogInfo };
                additionalLogInfoTemp[IngestLogObjectKey.eventContextGuid] = eventContextId;
                additionalLogInfoTemp[IngestLogObjectKey.contentName] = key;
                additionalLogInfoTemp[IngestLogObjectKey.eventCount] = customEntries[key];
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                    IngestEventSubTypes.info, this.ingestParams.subType, workflowName, additionalLogInfoTemp));
            }

            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                creationStatus, this.ingestParams.subType, workflowName, additionalLogInfo));
        }
        catch (e) {
            Logger.log(LogLevel.WARN, `MovingOverlay:logIngestData:${this.mode}: `, `Dunamis Logging Error:${e as string}`);
        }
    }

    private async _getoverlyaIdAudioIdMap(overlayList: ContentEntity[]): Promise<Map<string, string>> {
        const overlyaIdAudioIdMap = new Map<string, string>();
        await Promise.all(overlayList.map(async data => {
            const referencedAudioInfo = await data.getReferencedResourceInfo(ResourceType.audio);
            overlyaIdAudioIdMap.set(data.props.id, referencedAudioInfo.id);
        }));
        return overlyaIdAudioIdMap;
    }

    private async _createMovingOverlay(asset: ELAdobeAsset): Promise<void> {
        this.numberOfRetry = DEFAULT_NUMBER_OF_RETRIES;
        this.primaryRequestDone = false;
        this.startDate = new Date();
        try {
            const contentData = await ELContentCacheDownloader.getContentForContentType(ContentType.movingOverlay);
            this._overlayIdAudioIdMap = await this._getoverlyaIdAudioIdMap(contentData);
            store.dispatch(RecommendationWorkflowAction.updateRecommendationStatus(CreationsStatus.requested));
            const projectRequestParams = {
                assets: [asset],
                overlayIdAudioIdMap: this._overlayIdAudioIdMap,
            };
            store.dispatch(RecommendationWorkflowAction.updateRecommendationStatus(CreationsStatus.requested));

            const requestObj = await this._createRequestObj(projectRequestParams);
            this.recommendationBatchProcessor = new ELRecommendationBatchProcessor(this);
            await this.recommendationBatchProcessor.makeBatchRequest(requestObj, 3);
        } catch (error) {
            const elError = new ELError("MovingOverlay recommendations failed!", this.getWorkflowConfiguration(), error as Error);
            Logger.log(LogLevel.ERROR, elError);
            const message = IntlHandler.getInstance().formatMessage("failed-to-create-recommendations");
            ToastUtils.error(message);
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            this.logIngestData(IngestEventSubTypes.error, "MovingOverlay recommendations failed");
            return Promise.reject();
        }
    }

    private _updateMovingOverlayRoute(): void {
        if (!this.projectId) {
            const elError = new ELError("MovingOverlay:_updateMovingOverlayRoute: project id not valid", this.getWorkflowConfiguration());
            Logger.log(LogLevel.ERROR, elError);
            return;
        }

        HistoryUtils.replaceHistory(MovingOverlayUtils.getMovingOverlayHistoryState(this.projectId));
    }

    private _createProjectRequestParams(projectParams: ELCreateAndEditProjectParams): IBaseRequestParams {
        const intlHandler = IntlHandler.getInstance();
        const title = store.getState().recommendationWorkflowReducer.title;
        const uiTitle = this.getTrueTitleForRequest(title ?? intlHandler.formatMessage(UNTITLED_INTL_KEY));
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const selectedOverlayId = store.getState().recommendationWorkflowReducer.selectedOverlayId!;
        this.savedProjectOverlayId = selectedOverlayId;
        const overlayIdAudioIdMap = new Map<string, string>();
        if (this._overlayIdAudioIdMap) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            overlayIdAudioIdMap.set(selectedOverlayId, this._overlayIdAudioIdMap.get(selectedOverlayId)!);
        }

        const projectRequestParams = {
            assets: [projectParams.asset],
            overlayIdAudioIdMap: overlayIdAudioIdMap,
            title: uiTitle
        };
        return projectRequestParams;
    }

    protected async editRecommendationsProject(projectParams: ELCreateAndEditProjectParams): Promise<void> {
        if (!this.projectId || !this.projectData) {
            const elError = new ELError("MovingOverlay:editRecommendationsProject: project id or project data not valid", this.getWorkflowConfiguration());
            Logger.log(LogLevel.ERROR, elError);
            return;
        }
        const projectRequestParams = this._createProjectRequestParams(projectParams);
        const requestJson = await this.createRequestJson(projectRequestParams);
        try {
            this._preProcessMovingOverlayEdit(this.projectId);
            this.projectId = await this.editCreation(this.projectId, requestJson);
        } catch (error) {
            Logger.log(LogLevel.WARN, "MovingOverlay:editRecommendationsProject: , project edit failed!" + error);
            return;
        }
        this._pollMovingOverlayProjectStatus();
    }

    private async _createRequestObj(payload: IBaseRequestParams): Promise<Request> {
        try {
            const requestCreatorObj = new MovingOverlayJobCreator();
            const requestParams = payload as ELMovingOverlayCreationRequestParams;
            const asset = requestParams.assets[0];
            const dimensions = await MovingOverlayUtils.getMovingOverlayAssetDimensions(asset);
            const projectRequestParams: ELMovingOverlayCreationRequestParams = {
                assets: [asset],
                overlayIdAudioIdMap: requestParams.overlayIdAudioIdMap,
                title: payload.title,
                outputTypes: [OutputType.preview, OutputType.full],
                compositeSettings: dimensions,
                contentResolution: this._defaultContentResolution
            };
            const requestObj = await requestCreatorObj.getRequestObject(projectRequestParams);
            return requestObj;
        } catch (error) {
            const elError = new ELError("MovingOverlay:_createRequestObj, failed to create request object from params", this.getWorkflowConfiguration());
            Logger.log(LogLevel.ERROR, elError);
            return Promise.reject(error);
        }
    }

    protected async createRequestJson(payload: IBaseRequestParams): Promise<unknown> {
        try {
            const requestObj = await this._createRequestObj(payload);
            const requestJson = requestObj.serializeOut();
            return requestJson;
        } catch (error) {
            const elError = new ELError("MovingOverlay:_createRequestObj, failed to create request object from params", this.getWorkflowConfiguration());
            Logger.log(LogLevel.ERROR, elError);
            return Promise.reject(error);
        }
    }

    protected async createRecommendationProject(projectParams: ELCreateAndEditProjectParams): Promise<void> {
        try {
            const projectRequestParams = this._createProjectRequestParams(projectParams);
            const requestJson = await this.createRequestJson(projectRequestParams);
            this.projectId = await this.createCreation(requestJson);
        } catch (error) {
            const elError = new ELError("MovingOverlay project creation failed!", this.getWorkflowConfiguration(), error as Error);
            Logger.log(LogLevel.ERROR, elError);
            const message = IntlHandler.getInstance().formatMessage("failed-to-get-project-outputs");
            ToastUtils.error(message);
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            this.logIngestData(IngestEventSubTypes.error, "MovingOverlay project creation failed");
            return Promise.reject();
        }
        this._pollMovingOverlayProjectStatus();
        this._updateMovingOverlayRoute();
    }

    private async _pollMovingOverlayProjectStatus(): Promise<void> {
        if (this.projectId) {
            CreationInAppNotifier.pollStatus(this.projectId);
        }
    }

    private _updateViewStatusAndProgressText(status: CreationsStatus, progressText: string): void {
        this._updateProgressText(progressText);
        this._updateViewStatus(status);
    }

    private _updateViewStatus(status: CreationsStatus): void {
        this.viewDispatcher?.call(this.viewDispatcher, {
            type: ELRecommendationWorkflowViewActions.recommendationWorkflowStatus,
            payload: status,
        });
    }

    private _updateProgressText(progressText: string): void {
        this.viewDispatcher?.call(this.viewDispatcher, {
            type: ELRecommendationWorkflowViewActions.recommendationWorkflowProgressText,
            payload: progressText
        });
    }

    protected async createAndRenderDoc(movingDocPayload: ELAdobeAssetDocPayload, documentDirty: DocumentDirty): Promise<void> {
        if (this.doc) {
            this.doc.destroy();
        }

        try {
            this.doc = new ELVideoDoc(this, movingDocPayload);
            this.doc.createView(this.ensureHTMLElement(this._editPanelContainer));
            this.doc.markAndNotifyDocumentDirty(documentDirty);
            this.doc?.notify({
                type: ELMovingOverlayControllerActions.updateEditViewData,
                payload: movingDocPayload
            });
        } catch (error) {
            const elError = new ELError("MovingOverlay:_createAndRenderDoc: Couldn't render document", this.getWorkflowConfiguration(), error as Error);
            Logger.log(LogLevel.ERROR, elError);
            ToastUtils.error(IntlHandler.getInstance().formatMessage("creations-render-error", { workflow: IntlHandler.getInstance().formatMessage("moving-overlay-creation") }));
            this.startPreviousWorkflow();
            this.logIngestData(IngestEventSubTypes.error, "Document render failed");
        }

        Logger.log(LogLevel.INFO, "Document Object: ", this.doc);
    }

    private async _renderMovingOverlayDocument(asset: ELAdobeAsset, documentDirty: DocumentDirty): Promise<void> {

        const renditonData = await this.getOriginalDataOrPollAssetRendition(asset);
        const objectURL = renditonData.videoData;
        const videoAsset: CSAssetWithData = {
            id: Utils.getRandomUUID(),
            assetURN: asset.assetId ?? "",
            storageType: CreationsJobStorageType.RAPI,
            mimeType: asset.format ?? "",
            objectURL: objectURL
        };

        const movingDocPayload: ELAdobeAssetDocPayload = {
            assetInfo: videoAsset
        };
        this.createAndRenderDoc(movingDocPayload, documentDirty);
    }

    private async _overlayClicked(thumbInfo: ELPreviewCreationThumbData): Promise<void> {
        this.startDate = new Date();

        const movingOverlayOutputAssets = this._templateIdToOutputAssets?.get(thumbInfo.id);

        const fullResAsset = movingOverlayOutputAssets?.full;
        let objectURL;
        if (fullResAsset) {
            const objUrl = CollageUtils.getAssetFullResObjectURL(fullResAsset.assetId ?? "");
            if (objUrl) {
                objectURL = objUrl;
            } else {
                const renditonData = await this.getOriginalDataOrPollAssetRendition(movingOverlayOutputAssets?.full);
                if (renditonData.videoData)
                    store.dispatch(FullResMediaAction.updateData({ assetId: fullResAsset?.assetId ?? "", objectURL: renditonData.videoData }));
                objectURL = renditonData.videoData;
            }

        }

        const tempAsset: CSAssetWithData = {
            id: thumbInfo.id,
            assetURN: fullResAsset?.assetId ?? "",
            storageType: CreationsJobStorageType.RAPI,
            mimeType: fullResAsset?.format ?? "",
            objectURL: objectURL
        };
        const movingDocPayload: ELAdobeAssetDocPayload = {
            assetInfo: tempAsset
        };

        this.doc?.notify({
            type: ELAdobeAssetControllerAction.renderImage,
            payload: movingDocPayload
        });
        this.doc?.markAndNotifyDocumentDirty(DocumentDirty.DIRTY);
        this._updateSelectedOverlayId(thumbInfo.id);
        this.primaryOverlayId = thumbInfo.id;
        this.ingestParams.subType = CreationsMode.update;
        this.logIngestData(IngestEventSubTypes.success);
    }

    private _populatePanelData(projectData: ELRecommendationsOutputJsonConfigData): void {
        this.populateSelectedMediaList(projectData.assets);
    }

    private async _processUIRenderForProject(): Promise<void> {
        if (!this.projectData || !this.projectData.outputs) {
            const message = IntlHandler.getInstance().formatMessage("failed-to-get-project-outputs");
            ToastUtils.warning(message);
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            this.logIngestData(IngestEventSubTypes.error, "MovingOverlay get project ouputs failed");
            return;
        }
        try {
            if (!this.projectData.assets) {
                const message = IntlHandler.getInstance().formatMessage("creation-asset-not-found");
                ToastUtils.error(message);
                this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
                return Promise.reject();
            }
            const assetId = this.projectData.assets[0].assetURN;
            const projectAsset = await StorageService.getInstance().resolveAsset({ assetId }, "id");
            await this._createMovingOverlay(projectAsset);
        } catch (error) {
            const elError = new ELError("MovingOverlay:_openSavedMovingOverlay, not able to resolve asset, might be deleted", this.getWorkflowConfiguration(), error as Error);
            Logger.log(LogLevel.ERROR, elError);
            const message = IntlHandler.getInstance().formatMessage("failed-to-resolve-asset-for-doc");
            ToastUtils.error(message);
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            this.logIngestData(IngestEventSubTypes.error, "Not able to resolve asset");
            return Promise.reject();
        }
        this._notifySubViews();
        const outputConfigJson = await CreationUtils.getJsonFromProjectData(this.projectData) as ELRecommendationsOutputJsonConfigData;
        this._populatePanelData(outputConfigJson);
        this.setPrimaryOverlayId(outputConfigJson);
    }

    private async _openSavedMovingOverlay(): Promise<void> {
        this.ingestParams.subType = this.mode;
        this._processUIRenderForProject();
        this._pollMovingOverlayProjectStatus();
    }

    private async _getAndSetProjectData(projectId: string): Promise<void> {
        this.projectId = projectId;
        try {
            this.projectData = await this.getProjectData(this.projectId);
            this._updateMovingOverlayRoute();
        } catch (error) {
            const elError = new ELError("MovingOverlay:_getAndSetProjectData, failed to get projectData for projectId, " + projectId, this.getWorkflowConfiguration(), error as Error);
            Logger.log(LogLevel.ERROR, elError);
            ToastUtils.error(IntlHandler.getInstance().formatMessage("failed-to-get-project-outputs"));
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            this.logIngestData(IngestEventSubTypes.error, "MovingOverlay get project ouputs failed");
            return Promise.reject();
        }
    }

    async openProject(projectId: string): Promise<void> {
        await this._getAndSetProjectData(projectId);
        this._openSavedMovingOverlay();
    }

    protected _shouldAllowCreationToOpen(): boolean {
        const featureFlagName = FeatureName.eMovingOverlay;
        const isCreationFeatureActive = FeaturesManager.getInstance().IsFeatureActive(featureFlagName);
        return isCreationFeatureActive;
    }

    async enterProject(movingOverlayPayload: ELCreationWorkflowPayload): Promise<void> {
        const isCreationActive = this._shouldAllowCreationToOpen();
        if (!isCreationActive) {
            ToastUtils.error(IntlHandler.getInstance().formatMessage("creation-disabled-via-feature-flag-message"));
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            return;
        }
        switch (movingOverlayPayload.initMode) {
            case CreationsMode.create: {
                this.projectId = undefined;
                this.primaryOverlayId = undefined;
                this.updateStoreStateOnEnter();
                const asset = (movingOverlayPayload.payload as ELAdobeAsset[])[0];
                this._onDocumentDirty(DocumentDirty.DIRTY);
                this._createMovingOverlay(asset);
                break;
            }
            case CreationsMode.open: {
                this.primaryOverlayId = undefined;
                const movingOverlayStatusPayload = movingOverlayPayload.payload as CreationsStatusPayload;
                this.openProject(movingOverlayStatusPayload.projectId);
                break;
            }
            default: {
                Logger.log(LogLevel.WARN, "MovingOverlay (_enterCollage) - Invalid movingOverlay mode");
                break;
            }
        }
    }

    private _handleBackWorkflowWithToast(): void {
        ToastUtils.error(IntlHandler.getInstance().formatMessage("failed-to-get-project-outputs"), { timeout: Constants.TOAST_NO_TIMEOUT as number });
        this.removeRetryToast();
        this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
        this.logIngestData(IngestEventSubTypes.error, "Failed to create recommendations");
    }

    private async _handleFailedRecommendations(recommendationStatusData: RecommendationsStatusData): Promise<boolean> {
        let handled = false;
        const isRecommendationsCreateError = this.isFailedRecommendation(recommendationStatusData);
        if (isRecommendationsCreateError) {
            this._handleBackWorkflowWithToast();
            handled = true;
        }
        return handled;
    }

    private async _getMappedAssetToOverlayId(overlayId: string, movingOverlayOutputAssetsPath: MovingOverlayOutputAssetsPath): Promise<any> {
        const previewAsset = await StorageService.getInstance().resolveAsset({ repositoryId: "", path: movingOverlayOutputAssetsPath.preview ?? "" }, "path");
        const fullresAsset = await StorageService.getInstance().resolveAsset({ repositoryId: "", path: movingOverlayOutputAssetsPath.full ?? "" }, "path");
        return {
            overlayId: overlayId,
            outputAssets: { preview: previewAsset, full: fullresAsset }
        };
    }

    private getOutputAssetsType(): OutputType[] {
        return [OutputType.preview, OutputType.full];
    }

    private async _getMapOfOverlayIdToAssets(outputJsonData: unknown): Promise<Map<TemplateId, ELMovingOverlayOutputAssets>> {
        const requestObj = await this.getSerializedRequestObject(outputJsonData);
        const operation = requestObj.operations[0];
        const operationTypeInfo = operation.operationTypeInfo as CreationOperationTypeInfo;
        const operationSubTypeInfo = operationTypeInfo.operationSubTypeInfo as MovingOverlayCreationOperationSubTypeInfo;
        const output = operationTypeInfo.output as CreationOperationTypeOutput;
        const mapOf_templateIdToOutputAssetsPath = MovingOverlayJobUtils.getMappedTemplateIdsToAssetPath(operationSubTypeInfo as MovingOverlayCreationOperationSubTypeInfo, output, this.getOutputAssetsType());

        const resolveAssetPromises = [];
        for (const [overlayId, movingOverlayOutputAssetsPath] of mapOf_templateIdToOutputAssetsPath) {
            resolveAssetPromises.push(this._getMappedAssetToOverlayId(overlayId, movingOverlayOutputAssetsPath));
        }
        const mapOf_templateIdToOutputAssets = new Map<TemplateId, ELMovingOverlayOutputAssets>();
        const response = await Promise.all(resolveAssetPromises);
        response.forEach(data => {
            const { overlayId, outputAssets } = data;
            mapOf_templateIdToOutputAssets.set(overlayId, outputAssets);
        });
        return mapOf_templateIdToOutputAssets;
    }

    private async _getRecommendedAssetsForOverlays(outputJsonData: unknown): Promise<Map<TemplateId, ELMovingOverlayOutputAssets>> {
        try {
            const mapOfTemplateIdToAssets = await this._getMapOfOverlayIdToAssets(outputJsonData);
            mapOfTemplateIdToAssets.forEach((asset, overlayId) => {
                if (!this._templateIdToOutputAssets) {
                    this._templateIdToOutputAssets = new Map();
                }
                this._templateIdToOutputAssets.set(overlayId, asset);
            });
            return mapOfTemplateIdToAssets;
        } catch (error) {
            const elError = new ELError("MovingOverlay:_getRecommendedAssetsForOverlays, failed to process OutputResultConfig.json", this.getWorkflowConfiguration(), error as Error);
            Logger.log(LogLevel.ERROR, elError);
            const message = IntlHandler.getInstance().formatMessage("failed-to-get-project-outputs");
            ToastUtils.error(message);
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            this.logIngestData(IngestEventSubTypes.error, "MovingOverlay get project ouputs failed");
            return Promise.reject();
        }
    }

    protected async updateDocumentWithOverlay(updateDocumentPayload: ELMovingOverlayUpdateDocumentPayload): Promise<void> {
        const mapOfTemplateIdToAsset = updateDocumentPayload.mapOfTemplateIdToAsset;
        const overlayId = updateDocumentPayload.overlayId;

        const asset = mapOfTemplateIdToAsset.get(overlayId);
        if (!asset) {
            Logger.log(LogLevel.WARN, "MovingOverlay: updateDocumentWithOverlay", "Asset not present");
            return Promise.reject("Asset not present");
        }

        store.dispatch(DualViewAction.updateView(ELViewType.after));
        const originalAssetpayload = this.beforeDoc?.getData() as ELAdobeAssetDocPayload;
        const isDocumentDirty = store.getState().docStateReducer.isDirty;
        this._renderMovingOverlayDocument(asset.full, isDocumentDirty).then(() => {
            if (originalAssetpayload) {
                this.beforeDoc?.notify({
                    type: ELAdobeAssetControllerAction.renderImage,
                    payload: originalAssetpayload
                });
            }
        });
    }

    // @ts-ignore //TODO: Ashish
    private async _getFirstOverlayIdToRender(): Promise<string> {
        let selectedOverlayId = store.getState().recommendationWorkflowReducer.selectedOverlayId;
        const projectId = store.getState().recommendationWorkflowReducer.id;

        if (!selectedOverlayId) {
            const overlayContent = await ELContentCacheDownloader.getContentForContentType(ContentType.movingOverlay);
            const randomOverlayId = Math.floor(Math.random() * overlayContent.length);
            selectedOverlayId = overlayContent[randomOverlayId].props.id;
            this._updateSelectedOverlayId(selectedOverlayId);
            if (!projectId) this.ingestParams.subType = CreationsMode.create;
        }

        return selectedOverlayId;
    }

    private _reorderPanelThumbnails(overlayId: string): void {
        this._leftTabPanel.notify({
            type: ELRecommendationWorkflowControllerActions.keepThumbIdOnTop,
            payload: overlayId
        });
    }

    private async _updateOverlayPanelViewForRequestedOverlays(mapOfOverlayIdAndOutputAssets: Map<TemplateId, ELMovingOverlayOutputAssets>): Promise<void> {
        const overlayData = await ELContentCacheDownloader.getContentForContentType(ContentType.movingOverlay);
        overlayData.forEach(data => {
            if (mapOfOverlayIdAndOutputAssets.has(data.props.id)) {
                const updatedThumbData: ELThumbUpdateProps = {
                    id: data.props.id,
                    asset: mapOfOverlayIdAndOutputAssets.get(data.props.id)?.preview
                };
                this._leftTabPanel.notify({
                    type: ELRecommendationWorkflowControllerActions.updateSingleOverlayData,
                    payload: updatedThumbData
                });
            }
        });
    }


    private async _handleFailedMovingOverlayProject(projectId: string, projectStatusData: CreationStatusData): Promise<boolean> {
        let handled = false;
        const isProjectCreateError = projectStatusData.status !== CreationsStatus.success;
        if (isProjectCreateError) {
            ToastUtils.error(IntlHandler.getInstance().formatMessage("failed-to-get-project-outputs"), { timeout: 0 });
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            this.logIngestData(IngestEventSubTypes.error, "Failed to open project");
            handled = true;
        }
        return handled;
    }

    private _notifySubViews(): void {
        this._creationsHeader.notify({ type: ELCreationsHeaderControllerAction.updateCreationsData, payload: this.projectData });
    }

    private async _processMovingOverlayProject(projectStatusData: CreationStatusData): Promise<void> {
        if (!this.projectId || this.projectId !== projectStatusData.projectId) {
            const elError = new ELError("MovingOverlay:_processMovingOverlayProject: project Id not matching or project status isn't success", this.getWorkflowConfiguration());
            Logger.log(LogLevel.ERROR, elError);
            return;
        }

        if (await this._handleFailedMovingOverlayProject(this.projectId, projectStatusData)) {
            this.logIngestData(IngestEventSubTypes.error);
            return Promise.reject();
        }
        this.logIngestData(IngestEventSubTypes.success);
        this.projectData = await this.getProjectData(this.projectId);
        this._notifySubViews();
        store.dispatch(RecommendationWorkflowAction.updateProjectStatus(CreationsStatus.success));
        store.dispatch(DocActions.updateDocumentSaveStatus(DocumentSaveStatus.saved));
        this.doc?.markAndNotifyDocumentDirty(DocumentDirty.NON_DIRTY);
    }

    private _preProcessMovingOverlayEdit(projectId: string): void {
        const creationsThumb: CreationsThumb = { id: projectId, objectURL: "" };
        store.dispatch(CreationsAction.updateThumb(creationsThumb));
    }

    private async _save(): Promise<void> {
        this._updateProgressPercentageToView(Constants.ZERO as number);
        const asset = store.getState().selectedMediaListReducer[0];
        store.dispatch(RecommendationWorkflowAction.updateProjectStatus(CreationsStatus.requested));
        const message = IntlHandler.getInstance().formatMessage("saving-creation");
        this._updateProgressText(message);
        this.startDate = new Date();
        if (!this.projectId) {
            Logger.log(LogLevel.INFO, "MovingOverlay:_save, project is not created yet, so no projectId found");
            this.createRecommendationProject({ asset: asset });
        } else {
            const asset = store.getState().selectedMediaListReducer[0];
            const selectedOverlayId = store.getState().recommendationWorkflowReducer.selectedOverlayId;
            if (selectedOverlayId) {
                this.editRecommendationsProject({ asset: asset });
            }
        }
        this.doc?.notify({ type: ELVideoDocActions.pauseVideo });
    }

    private _onProjectRequestStatusChanged(creationStatusData: CreationStatusData): void {
        this._processMovingOverlayProject(creationStatusData);
    }

    private async _updateProjectData(projectId: string): Promise<boolean> {
        try {
            this.projectData = await this.getProjectData(projectId);
        } catch (error) {
            Logger.log(LogLevel.WARN, "MovingOverlay:_updateProjectData: Could not fetch MovingOverlay project data! " + error);
            return false;
        }
        return true;
    }

    private async _onMovingOverlayTitleChange(): Promise<void> {
        if (this.projectId) {
            await this._updateProjectData(this.projectId);
        }
    }

    async initialize(dispatch?: React.Dispatch<ViewAction> | undefined): Promise<void> {
        super.initialize(dispatch);

        const panelProvider = new ELMovingOverlayPanelProvider(this);

        this._leftTabPanel = panelProvider.getTabPanel(ELTabPanelType.leftTabPanel);
        this._leftTabPanel.createView(this.ensureHTMLElement(this._leftTabPanelContainer));

        this._rightTabPanel = panelProvider.getTabPanel(ELTabPanelType.rightTabPanel);
        await this._rightTabPanel.createView(this.ensureHTMLElement(this._rightTabPanelContainer));

        this._creationsHeader = new ELMovingOverlayHeader(this, this.shareOptions);
        await this._creationsHeader.createView(this.ensureHTMLElement(this._creationsHeaderContainer));

        this.createFeedbackView(this.ensureHTMLElement(this._feedbackContainer));

        CreationInAppNotifier.subscribe(this, CreationAppSubscriberType.statusChange);
        RecommendationsInAppNotifier.subscribe(this, RecommendationsAppSubscriberType.statusChange);

        this.enterProject(this._movingOverlayPayload);
    }

    createView(container: HTMLElement): void {
        super.createView(container);
        const element = React.createElement(MovingOverlayView, {
            controller: this
        });

        const provider = React.createElement(Provider, { store }, element);
        ReactDOM.render(
            provider,
            container
        );
    }

    destroyView(): void {
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container);
        }
        super.destroyView();
    }

    destroy(): void {
        this.renditionHandler.clearRenditions();
        this.recommendationBatchProcessor = undefined;
        CreationInAppNotifier.unsubscribe(this, CreationAppSubscriberType.statusChange);
        RecommendationsInAppNotifier.unsubscribe(this, RecommendationsAppSubscriberType.statusChange);
        super.destroy();
    }

    startWorkflow(containerId: string, prevWorkflow?: IWorkflow, action?: WorkflowAction): void {
        super.startWorkflow(containerId, prevWorkflow, action);

        const workflowPayload = action?.payload as CreationStatusPayload;
        this.mode = action?.initMode as CreationsMode;

        const movingOverlayPayload: ELCreationWorkflowPayload = {
            initMode: this.mode,
            payload: workflowPayload
        };

        this._movingOverlayPayload = movingOverlayPayload;
        this.createView(this.ensureHTMLElement(containerId));
    }

    endWorkflow(): void {
        this.removeRetryToast();
        super.endWorkflow();
    }

    private _ingest(payload: Record<string, string>): void {
        this._owner.notify({
            type: WorkspaceActionType.ingest,
            payload: payload
        });
    }

    private async _downloadSelectedRecommendation(): Promise<void> {
        this.startDate = new Date();
        const selectedOverlayId = store.getState().recommendationWorkflowReducer.selectedOverlayId;
        if (selectedOverlayId) {
            const fullResAsset = this._templateIdToOutputAssets?.get(selectedOverlayId)?.full;
            if (fullResAsset) {
                const toastMessage = IntlHandler.getInstance().formatMessage("moving-overlay-creation").toLowerCase();
                const message = IntlHandler.getInstance().formatMessage("file-download-in-background", { media: toastMessage });
                ToastUtils.info(message);
                const defaultTitle = IntlHandler.getInstance().formatMessage(UNTITLED_INTL_KEY);
                const title = store.getState().recommendationWorkflowReducer.title ?? defaultTitle;
                await this.downloadSelectedRecommendation(fullResAsset, title, ELExportOption.thirdParty);
            }
        }
    }

    private async _download(): Promise<void> {
        const selectedOverlayId = store.getState().recommendationWorkflowReducer.selectedOverlayId;
        if (!this.projectId || (this.projectId && selectedOverlayId !== this.savedProjectOverlayId)) {
            await this._downloadSelectedRecommendation();
            this.ingestParams.subType = IngestEventSubTypes.download;
            this.logIngestData(IngestEventSubTypes.success);
            return Promise.resolve();
        }
        await this._updateProjectData(this.projectId);
        if (!this.projectData) {
            const elError = new ELError("Moving overlay:download: invalid project data for " + this.projectId, this.getWorkflowConfiguration());
            Logger.log(LogLevel.ERROR, elError);
            return;
        }
        const toastMessage = IntlHandler.getInstance().formatMessage("moving-overlay-creation").toLowerCase();
        this.startDate = new Date();
        this.downloadSavedProject(this.projectData, CreationsJobProjectSubType.movingOverlay, toastMessage);
        this.ingestParams.subType = IngestEventSubTypes.download;
        this.logIngestData(IngestEventSubTypes.success);
    }

    private _updateSelectedOverlayId(selectedOverlayId?: string): void {
        store.dispatch(RecommendationWorkflowAction.updateSelectedOverlayId(selectedOverlayId));
        this._leftTabPanel.notify({
            type: ELRecommendationWorkflowControllerActions.setSelectedOverlayId,
            payload: selectedOverlayId
        });
    }

    private async _cleanAllThumbDataAndUpdateView(): Promise<void> {
        await this._leftTabPanel.notify({ type: ELRecommendationWorkflowControllerActions.cleanAllThumbsData });
    }

    private async _onReplaceMedia(replaceAssetInfo: ReplaceAssetInfo): Promise<void> {
        this.primaryOverlayId = store.getState().recommendationWorkflowReducer.selectedOverlayId;
        this._updateProgressPercentageToView(Constants.ZERO as number);
        this._cleanAllThumbDataAndUpdateView();
        const asset = replaceAssetInfo.assetToReplaceWith;
        this.ingestParams.subType = CreationsMode.update;
        this._updateViewStatus(CreationsStatus.requested);
        this._updateProgressText(IntlHandler.getInstance().formatMessage("generating-moving-overlay"));
        this.removeRetryToast();
        this._onDocumentDirty(DocumentDirty.DIRTY);
        this.recommendationBatchProcessor?.deleteRequestedCreations();
        this.recommendationBatchProcessor = undefined;
        this._createMovingOverlay(asset);
        this.doc?.notify({ type: ELVideoDocActions.pauseVideo });
    }

    private _onDocumentDirty(dirty: DocumentDirty): void {
        if (this.doc) {
            store.dispatch(DocActions.updateDocumentError(this.doc.hasRenderingError()));
        }

        store.dispatch(DocActions.updateDocumentDirty(dirty));
    }

    private async _onMovingOverlayRendered(status: CreationsStatus): Promise<void> {
        this._updateViewStatus(status);
    }

    private async _updateProgressPercentageToView(progress: number): Promise<void> {
        let displayProgress = progress;
        if (!this.primaryRequestDone && displayProgress === 100) {
            displayProgress = 95;
        }
        this.viewDispatcher?.({
            type: CreationInAppNotifierAction.creationProgressChanged,
            payload: displayProgress
        });
    }

    private _updateCreationProgress(progress: number): void {
        if (this.mode === CreationsMode.open && progress === 100) {
            return;
        }
        this._updateProgressPercentageToView(progress);
    }

    private _logIngestSave(viewType: string): void {
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.eventContextGuid] = Utils.getRandomUUID();
        additionalLogInfo[IngestLogObjectCustomKey.viewType] = viewType;
        this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
            IngestEventSubTypes.success, CreationsMode.save, CreationsJobProjectSubType.movingOverlay, additionalLogInfo));
    }

    protected async setPrimaryOverlayId(projectData: ELRecommendationsOutputJsonConfigData): Promise<void> {
        const mapOfOverlayIdAssetPath = await this._getRecommendedAssetsForOverlays(projectData);
        let projectOverlayId: string | undefined;

        mapOfOverlayIdAssetPath.forEach((asset, overlayId) => {
            projectOverlayId = overlayId;
        });

        this.primaryOverlayId = projectOverlayId;
        if (!projectOverlayId) {
            throw new Error("not able to find overlayId from projectData");
        }
        this._reorderPanelThumbnails(projectOverlayId);
    }

    private _updateThumbnailPanelForErrorConfigurations(configurations: MovingOverlayCreationOperationSubTypeConfiguration[]): void {
        const overlayIds: string[] = [];
        configurations.forEach(configuration => {
            const movingOverlayConfig = configuration as MovingOverlayCreationOperationSubTypeConfiguration;
            const templateId = movingOverlayConfig.configurationInfo?.configurationInfo?.templates.video.operationContentData?.id;
            if (templateId) {
                overlayIds.push(templateId);
            }
        });
        overlayIds.forEach((id) => {
            this._leftTabPanel.notify({
                type: ELRecommendationWorkflowControllerActions.putErrorDataInThumbnail,
                payload: id
            });
        });
    }

    protected async batchRequestStatusChanged(recommendationsStatusData: ELBatchRecommendationStatusData): Promise<void> {
        const { requestId, status } = recommendationsStatusData;
        if (status === CreationsStatus.success) {
            const requestData = await this.getRecommendationRequestData(requestId);
            const outputConfigPath = this.getOutputConfigPathFromOutputConfigurations(requestData.outputs.config);
            const outputJsonData = await CreationUtils.getOutputResultConfigJsonData(outputConfigPath.rootPath, outputConfigPath.relativeFilePath);
            const mapOfOverlayIdOutputAssets = await this._getRecommendedAssetsForOverlays(outputJsonData);
            this._updateOverlayPanelViewForRequestedOverlays(mapOfOverlayIdOutputAssets);
            if (!this.primaryOverlayId) {
                mapOfOverlayIdOutputAssets.forEach((asset, overlayId) => {
                    this.primaryOverlayId = overlayId;
                });
            }
            const isPrimaryBatchRequest = this.primaryOverlayId !== undefined && mapOfOverlayIdOutputAssets.has(this.primaryOverlayId);
            if (isPrimaryBatchRequest) {
                if (this.primaryOverlayId) {
                    const updateDocumentPayload: ELMovingOverlayUpdateDocumentPayload = {
                        mapOfTemplateIdToAsset: mapOfOverlayIdOutputAssets,
                        overlayId: this.primaryOverlayId
                    };

                    this.updateDocumentWithOverlay(updateDocumentPayload);
                    this._reorderPanelThumbnails(this.primaryOverlayId);
                    this._updateSelectedOverlayId(this.primaryOverlayId);
                }
                if (store.getState().recommendationWorkflowReducer.status === CreationsStatus.requested) {
                    store.dispatch(RecommendationWorkflowAction.updateRecommendationStatus(CreationsStatus.success));
                    this._updateViewStatus(CreationsStatus.success);
                    this._leftTabPanel.notify({
                        type: ELRecommendationWorkflowControllerActions.setSelectedOverlayId,
                        payload: this.primaryOverlayId
                    });
                    this.logIngestData(IngestEventSubTypes.success);
                    this.primaryRequestDone = true;
                }
            }
        } else if (status === CreationsStatus.error) {
            if (!this.recommendationBatchProcessor) {
                return Promise.reject(new Error("recommendationBatchProcessor is not set"));
            }
            const requestObj = this.recommendationBatchProcessor.getRequestObjForRequestId(requestId);
            if (!requestObj) {
                return Promise.reject(new Error("requestObj is not set"));
            }
            const operationSubTypeInfo = (requestObj.operations[0].operationTypeInfo as CreationOperationTypeInfo).operationSubTypeInfo;
            if (!operationSubTypeInfo || !operationSubTypeInfo.configurations) {
                return Promise.reject(new Error("not able to extract configurations from requestObj"));
            }
            const configurations = operationSubTypeInfo.configurations;

            if (!this.primaryOverlayId) {
                this._updateThumbnailPanelForErrorConfigurations(configurations as MovingOverlayCreationOperationSubTypeConfiguration[]);
                return Promise.resolve();
            }

            const isPrimaryRequest = this._isTemplateIdPresentInConfigurations(this.primaryOverlayId, configurations);
            if (isPrimaryRequest) {
                this.recommendationBatchProcessor?.deleteRequestedCreations();
                this._handleFailedRecommendations(recommendationsStatusData);
            } else {
                this._updateThumbnailPanelForErrorConfigurations(configurations as MovingOverlayCreationOperationSubTypeConfiguration[]);
            }
            return Promise.resolve();
        }
    }

    private _isTemplateIdPresentInConfigurations(overlayId: string, configurations: CreationOperationSubTypeConfiguration[]): boolean {
        return configurations.some(config => config.id === overlayId);
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case DocumentActions.markDocumentDirty: {
                this._onDocumentDirty(action.payload as DocumentDirty);
                handled = true;
                break;
            }
            case CreationWorkflowActions.creationRenderStatus: {
                this._onMovingOverlayRendered(action.payload as CreationsStatus);
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.download: {
                this._download();
                handled = true;
                break;
            }
            case ELRecommendationWorkflowControllerActions.overlayClicked: {
                const thumbInfo = action.payload as ELPreviewCreationThumbData;
                this._overlayClicked(thumbInfo);
                handled = true;
                break;
            }
            case ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView: {
                const workspacePayload = {
                    startWorkflow: WorkflowsName.creationsHome
                };
                this.recommendationBatchProcessor = undefined;
                const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
                handled = await this._owner.notify(workspaceAction);
                break;
            }
            case ELAdobeAssetControllerAction.showAfterImage: {
                this.dualView?.showDoc(ELViewType.after);
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.after, CreationsJobProjectSubType.movingOverlay));
                handled = true;
                break;
            }
            case ELAdobeAssetControllerAction.showBeforeImage: {
                this.dualView?.showDoc(ELViewType.before);
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.before, CreationsJobProjectSubType.movingOverlay));
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.zoomInEvent:
            case CanvasZoomLevelAction.zoomOutEvent: {
                this.doc?.notify(action);
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.movingOverlay, IngestEventTypes.click, IngestEventSubTypes.zoom, "Zoom-In-Out"));
                handled = true;
                break;
            }
            case CreationMediaActionType.replaceMedia: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.movingOverlay, IngestEventTypes.click, IngestEventSubTypes.replaceMedia));
                const workspacePayload: WorkspacePayload = {
                    startWorkflow: WorkflowsName.replaceMediaManager,
                    payload: {
                        assetId: store.getState().selectedMediaListReducer[0].assetId,
                        mode: ReplaceMediaManagerMode.replacingMedia,
                        mediaGridConfig: this.mediaGridConfig
                    }
                };
                const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
                handled = await this._owner.notify(workspaceAction);
                break;
            }
            case ReplaceMediaManagerWorkflowAction.replaceMediaSelection: {
                const replaceAssetInfo = action.payload as ReplaceAssetInfo;
                this._onReplaceMedia(replaceAssetInfo);
                handled = true;
                break;
            }
            case ELCreationBatchActionTypes.updateProgressChange: {
                const batchProgressData = action.payload as ELRecommendationBatchProgressData;
                if (batchProgressData.batchProcessor === this.recommendationBatchProcessor) {
                    this._updateProgressPercentageToView(batchProgressData.progress);
                }
                handled = true;
                break;
            }
            case ELCreationBatchActionTypes.overallBatchRequestsStatus: {
                const overallBatchProgress = action.payload as ELRecommendationBatchProcessorRequestInfo;
                if (overallBatchProgress.batchProcessor === this.recommendationBatchProcessor && this.isWorkflowRunning) {
                    this._handleOverallBatchProgress(overallBatchProgress);
                }
                handled = true;
                break;
            }
            case ELCreationBatchActionTypes.recommendationBatchStatusChanged: {
                const recommendationsStatusData = action.payload as ELBatchRecommendationStatusData;
                if (recommendationsStatusData.batchProcessor === this.recommendationBatchProcessor) {
                    this.batchRequestStatusChanged(recommendationsStatusData);
                }
                handled = true;
                break;
            }
            case CreationInAppNotifierAction.creationStatusChanged: {
                const creationStatusData = action.payload as CreationStatusData;
                this._onProjectRequestStatusChanged(creationStatusData);
                handled = true;
                break;
            }
            case RecommendationsInAppNotifierAction.recommendationProgressChanged: {
                const payload = action.payload as ELRecommendationsProgressPayload;
                if (payload.requestId === this.requestId) {
                    this._updateProgressPercentageToView(payload.progress);
                }
                handled = true;
                break;
            }
            case CreationInAppNotifierAction.creationProgressChanged: {
                const { progress, projectId } = action.payload as ELProjectProgressPayload;
                if (projectId === this.projectId) {
                    this._updateCreationProgress(progress);
                }
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.save: {
                this.ingestParams.subType = CreationsMode.save;
                this.ingestParams.eventViewType = IngestLogObjectValue.workspace;
                await this._save();
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.dontSave: {
                this.recommendationBatchProcessor?.deleteRequestedCreations();
                this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.backDialogDontSave, CreationsJobProjectSubType.movingOverlay));
                handled = true;
                break;
            }
            case WorkflowActionType.ingest: {
                this._ingest(action.payload as Record<string, string>);
                handled = true;
                break;
            }
            default: {
                Logger.log(LogLevel.DEBUG, "MovingOverlay(ControllerAction): Invalid action" + action);
                break;
            }
        }
        if (!handled) {
            handled = await super.notify(action);
        }
        return handled;
    }

    private _handleUserInputFromRetryToast(): void {
        if (this.numberOfRetry > 0) {
            this.numberOfRetry--;
            this.addToastForRetryBatch();
        } else {
            this._handleBackWorkflowWithToast();
        }
    }

    private _handleOverallBatchProgress({ requestCount, failureCount, successCount }: ELRecommendationBatchProcessorRequestInfo): void {
        if (requestCount === failureCount) {
            this._handleBackWorkflowWithToast();
        } else if (failureCount !== 0 && requestCount === failureCount + successCount) {
            this._handleUserInputFromRetryToast();
        }
    }

    private async _saveAndStartPreviousWorkflow(): Promise<void> {
        const saveStatus = store.getState().docStateReducer.saveStatus;
        if (saveStatus === DocumentSaveStatus.saveInProgress) {
            this._updateViewStatusAndProgressText(CreationsStatus.requested, IntlHandler.getInstance().formatMessage("saving-creation"));
            await this.waitForSaveComplete();
        } else if (this.doc?.isDocumentDirty === DocumentDirty.DIRTY) {
            await this._save();
            await this.waitForDocumentDirtyStatus();
            this.updateStoreStateOnExit();
        }
        this.recommendationBatchProcessor?.deleteRequestedCreations();
        this.recommendationBatchProcessor = undefined;
        this.startPreviousWorkflow();
        this._logIngestSave(IngestLogObjectValue.dialog);
    }

    protected async notifyWorkflow<T extends WorkflowAction>(action: T): Promise<boolean> {
        let handled = false;

        switch (action.type) {
            case WorkspaceActionType.startPreviousWorkflow: {
                this._saveAndStartPreviousWorkflow();
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.changeZoomValue: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.movingOverlay, IngestEventTypes.click, IngestEventSubTypes.zoom, action.payload as string));
                this.doc?.notify(action);
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.zoomToFill: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.movingOverlay, IngestEventTypes.click, IngestEventSubTypes.zoom, "Fill"));
                this.doc?.notify(action);
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.zoomToFit: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.movingOverlay, IngestEventTypes.click, IngestEventSubTypes.zoom, "Fit"));
                this.doc?.notify(action);
                handled = true;
                break;
            }
            case CreationWorkflowActions.renameCreationTitle: {
                this._onMovingOverlayTitleChange();
                handled = true;
                break;
            }
            default:
                Logger.log(LogLevel.WARN, "MovingOverlay(notifyWorkflow): Invalid action" + action);
                break;
        }
        return handled;
    }
}

export default MovingOverlay;