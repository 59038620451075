/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { ShareOptions } from "../../../view/components/organism/el-share-options/ELShareOptions";
import IViewController, { ControllerAction } from "../../../view/IViewController";
import { CreationsData, CreationsDownloadFileType } from "./CreationTypes";

export enum ELCreationsHeaderControllerAction {
    cancel = "cancel",
    back = "BACK",
    save = "SAVE",
    dontSave = "DONT_SAVE",
    dontSaveAskFeedback = "DONT_SAVE_ASK_FEEDBACK",
    share = "SHARE",
    download = "DOWNLOAD",
    edit = "EDIT",
    changeName = "CHANGE_NAME",
    slideshowSettingsChanged = "SLIDESHOW_SETTINGS_CHANGED",
    updateCreationsData = "UPDATE_CREATIONS_DATA",
    slideshowDataUpdated = "SLIDESHOW_DATA_UPDATED",
    slideshowMediaUpdated = "SLIDESHOW_MEDIA_UPDATED",
    ctaDone = "CTA_DONE",
    ctaCancel = "CTA_CANCEL",
    documentError = "DOCUMENT_ERROR",
    openInDesktop = "OPEN_IN_DESKTOP",
    sharePopoverClicked = "SHARE_POPOVER_CLICKED",
    createShareQR = "CREATE_SHARE_QR"
}

export enum ELCreationsHeaderViewAction {
    changeName = "CHANGE_NAME",
    nameChanged = "NAME_CHANGED",
    updateCreationsData = "UPDATE_CREATIONS_DATA",
    updateDoneButtonState = "UPDATE_DONE_BUTTON_STATE"
}

export enum ELCreationsHeaderWorkflowAction {
    cancelCreation = "CANCEL_CREATION",
    editSlideshow = "EDIT_SLIDESHOW"
}

export interface ELCreationsHeaderViewProps {
    controller: IViewController,
    shareOptionController: ShareOptions,
    data?: CreationsData,
    radioButtonList?: CreationsDownloadFileType[],
    showOpenInDesktop?: boolean,
    showBeforeAfter?: boolean
}

export interface ELMediaRecommendationHeaderViewProps extends ELCreationsHeaderViewProps {
    backButtonDialogHeading: string
}

export enum ELCreationsRenameStatus {
    inProgress = "inProgress",
    success = "success",
    failure = "failure"
}

export interface ELCreationsHeaderState {
    data?: CreationsData,
    title: string,
    renameStatus: ELCreationsRenameStatus
}

export interface ELBackButtonProps {
    notify: (param: ControllerAction) => void,
    launchFeedbackDialogOnDontSave?: boolean,
    hasDialog?: boolean,
    headingDescription?: string,
    isDialogOpen?: boolean,
    shouldShowLicenseAndSave?: boolean
}

export interface ELBackButtonDialogProps {
    headingDescription: string,
    onDismiss: () => void,
    onSave: () => void,
    onDontSave: () => void
}