/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ELStageDocActions, ELStageDocAddLayerByIndexPayload, ELStageDocDataPayload, ELStageDocLinkLayersPayload, ELStageDocTextPropertyPayload } from "../../../common/interfaces/document/ELStageDocTypes";
import { ELLayerKind, ELStageLayerDataOptions, CSLayerTextInfo } from "../../../common/interfaces/editing/layer/ELStageLayerTypes";
import { RenderedStageData, RendererUpdateData } from "../../../common/interfaces/renderer/RendererTypes";
import { CanvasControllerAction, CanvasLinkObjectsPayload, CanvasUpdateTextPropertyPayload, ELStageObject, ELStageTextObject } from "../../../common/interfaces/stage/StageTypes";
import ELStageObjectUtils from "../../../utils/ELStageObjectUtils";
import Logger, { LogLevel } from "../../../utils/Logger";
import { StageUtils } from "../../../utils/stage/StageUtils";
import { ControllerAction } from "../../../view/IViewController";
import IDoc from "../../document/IDoc";
import ILayer from "../../document/layer/ILayer";
import IDocDataParser from "../../document/parser/IDocDataParser";
import IRenderer from "../../renderer/IRenderer";
import IGraphicsStage from "../../stage/IGraphicsStage";
import ELStageDocDataParser from "../parser/ELStageDocParser";

export default class ELStageDocRenderer extends IRenderer {
    private _doc: IDoc;
    private readonly _baseLayerIndex = 1;

    constructor(doc: IDoc, stage: IGraphicsStage, dataParser: IDocDataParser) {
        super(stage, dataParser);
        this._doc = doc;
    }

    async render(container: HTMLElement): Promise<void> {
        await super.render(container);
        return new Promise((resolve) => {
            this.dataParser.parseDocData().then((stageShapeAndObjectOptionsList) => {
                this.drawShapes(stageShapeAndObjectOptionsList).then((stageObjectList) => {
                    this.saveStageData(stageShapeAndObjectOptionsList, stageObjectList);
                    resolve();
                });
            });
        });
    }

    private async _updateLayerData(payload: ELStageDocDataPayload): Promise<void> {
        const layer = await this._doc.getLayerById(payload.layerId);
        const layerKind = layer.getLayerKind();

        const data = StageUtils.getRenderedDataByLayerId(this.stageDataList, payload.layerId);
        const objectOptions = data.stageShapeAndObjectOptions.objectOptions;
        switch (layerKind) {
            case ELLayerKind.pixel: {
                objectOptions.image = await layer.getStageData();
                break;
            }
            case ELLayerKind.text: {
                objectOptions.text = await layer.getStageData();
                break;
            }
            case ELLayerKind.path: {
                objectOptions.svgPath = await layer.getStageData();
                break;
            }
        }

        const updatedObject = await this.updateShape(data.stageShapeAndObjectOptions.shape, objectOptions);
        if (updatedObject) {
            data.object = updatedObject;
            data.stageShapeAndObjectOptions.objectOptions = objectOptions;
        }

        const filteredStageDataList = StageUtils.getRenderedDataByExclusionLayerId(this.stageDataList, payload.layerId);
        filteredStageDataList.push(data);

        this.stageDataList = filteredStageDataList;
    }

    async setLayerVisible(payload: ELStageDocDataPayload): Promise<void> {
        const data = StageUtils.getRenderedDataByLayerId(this.stageDataList, payload.layerId);
        if (data) {
            const objectOptions = data.stageShapeAndObjectOptions.objectOptions;
            objectOptions.visible = payload.data as boolean;
            const updatedObject = await this.updateShape(data.stageShapeAndObjectOptions.shape, objectOptions);
            if (updatedObject) {
                data.object = updatedObject;
                data.stageShapeAndObjectOptions.objectOptions = objectOptions;
            }
        } else {
            return Promise.reject("ELStageDocRenderer::setLayerVisible Layer not found with id: " + payload.layerId);
        }
    }

    async setLayerFill(payload: ELStageDocDataPayload): Promise<void> {
        const data = StageUtils.getRenderedDataByLayerId(this.stageDataList, payload.layerId);
        if (data) {
            const objectOptions = data.stageShapeAndObjectOptions.objectOptions;
            objectOptions.fill = payload.data as string;
            const updatedObject = await this.updateShape(data.stageShapeAndObjectOptions.shape, objectOptions);
            if (updatedObject) {
                data.object = updatedObject;
                data.stageShapeAndObjectOptions.objectOptions = objectOptions;
            }
        } else {
            return Promise.reject("ELStageDocRenderer::setLayerFill Layer not found with id: " + payload.layerId);
        }
    }

    async setLayerOpacity(payload: ELStageDocDataPayload): Promise<void> {
        const data = StageUtils.getRenderedDataByLayerId(this.stageDataList, payload.layerId);
        if (data) {
            const objectOptions = data.stageShapeAndObjectOptions.objectOptions;
            objectOptions.opacity = payload.data as number;
            const updatedObject = await this.updateShape(data.stageShapeAndObjectOptions.shape, objectOptions);
            if (updatedObject) {
                data.object = updatedObject;
                data.stageShapeAndObjectOptions.objectOptions = objectOptions;
            }
        } else {
            return Promise.reject("ELStageDocRenderer::setLayerOpacity Layer not found with id: " + payload.layerId);
        }
    }

    async setLayerTextOptions(payload: ELStageDocDataPayload): Promise<void> {
        const data = StageUtils.getRenderedDataByLayerId(this.stageDataList, payload.layerId);
        if (data) {
            const objectOptions = data.stageShapeAndObjectOptions.objectOptions;
            const textOptions = payload.data as CSLayerTextInfo;
            ELStageObjectUtils.updateStageObjectOptionsForTextLayer(objectOptions, textOptions);
            const updatedObject = await this.updateShape(data.stageShapeAndObjectOptions.shape, objectOptions);
            if (updatedObject) {
                data.object = updatedObject;
                data.stageShapeAndObjectOptions.objectOptions = objectOptions;
            }
        } else {
            return Promise.reject("ELStageDocRenderer::setLayerTextOptions Layer not found with id: " + payload.layerId);
        }
    }

    async setClipPathOptions(payload: ELStageDocDataPayload): Promise<void> {
        const data = StageUtils.getRenderedDataByLayerId(this.stageDataList, payload.layerId);
        if (data) {
            if (data.stageShapeAndObjectOptions.clipShape) {
                data.stageShapeAndObjectOptions.clipObjectOptions = ELStageObjectUtils.getStageClipObjectOptions(payload.data as ELStageLayerDataOptions);
                const updatedClipPathObject = await this.updateShape(data.stageShapeAndObjectOptions.clipShape, data.stageShapeAndObjectOptions.clipObjectOptions);
                if (updatedClipPathObject) {
                    data.object.clipPath = updatedClipPathObject;
                }
            } else {
                return Promise.reject("ELStageDocRenderer::setClipPathOptions clipshape not found")
            }
        } else {
            return Promise.reject("ELStageDocRenderer::setClipPathOptions Layer not found with id: " + payload.layerId);
        }
    }

    private async _updateLayerTextProperty(payload: ELStageDocTextPropertyPayload): Promise<void> {
        const data = StageUtils.getRenderedDataByLayerId(this.stageDataList, payload.layerId);
        if (data) {
            const canvasPayload: CanvasUpdateTextPropertyPayload = { object: data.object as ELStageTextObject, key: payload.key, value: payload.value };
            await this.stage.notify({ type: CanvasControllerAction.updateTextProperty, payload: canvasPayload });
        } else {
            return Promise.reject("ELStageDocRenderer::_updateLayerTextProperty Layer not found with id: " + payload.layerId);
        }
    }

    private async _updateActiveObject(layerId: string): Promise<void> {
        const data = StageUtils.getRenderedDataByLayerId(this.stageDataList, layerId);
        if (data) {
            await this.stage.setActiveObject(data.object);
        } else {
            return Promise.reject("ELStageDocRenderer::_updateActiveObject Layer not found with id: " + layerId);
        }
    }

    private _removeLayer(layerId: string): void {
        const data = StageUtils.getRenderedDataByLayerId(this.stageDataList, layerId);
        if (data) {
            this.stage.removeObject(data.object);
            this.stageDataList = StageUtils.getRenderedDataByExclusionLayerId(this.stageDataList, layerId);
        } else {
            Logger.log(LogLevel.WARN, "ELStageDocRenderer::_removeLayer Layer not found with id: " + layerId);
        }
    }

    private async _addLayerByIndex(layer: ILayer, layerIndex: number): Promise<void> {
        const stageShapeAndObjectOptions = await (this.dataParser as ELStageDocDataParser).getStageData(layer); const stageObject = await this.addShape(stageShapeAndObjectOptions, layerIndex);
        const renderedData: RenderedStageData = { stageShapeAndObjectOptions: stageShapeAndObjectOptions, object: stageObject };
        this.stageDataList.splice(this._baseLayerIndex + layerIndex, 0, renderedData);
    }

    private async _linkLayers(payload: ELStageDocLinkLayersPayload): Promise<void> {
        const linkLayers = payload.linkLayers;
        const linkType = payload.linkType;
        const stageObjects: ELStageObject[] = [];
        this.stageDataList.forEach((stageData) => {
            const objectOptions = stageData.stageShapeAndObjectOptions.objectOptions;
            if (!objectOptions.data || !objectOptions.data.payload) {
                return;
            }
            const layerId = objectOptions.data.payload as string;
            if (linkLayers.some(data => data.layerId === layerId && data.isClipPath === false)) {
                stageObjects.push(stageData.object)
            }
            if (linkLayers.some(data => data.layerId === layerId && data.isClipPath === true) && stageData.object.clipPath) {
                stageObjects.push(stageData.object.clipPath)
            }
        });
        const canvasLinkObjectsPayload: CanvasLinkObjectsPayload = { objects: stageObjects, linkType: linkType };
        await this.stage.notify({ type: CanvasControllerAction.linkObjects, payload: canvasLinkObjectsPayload });
    }

    async update(updateData: RendererUpdateData): Promise<void> {
        switch (updateData.type) {
            case ELStageDocActions.updateLayerData: {
                await this._updateLayerData(updateData.payload as ELStageDocDataPayload);
                break;
            }

            case ELStageDocActions.addLayer: {
                const layerId = updateData.payload as string;
                const layer = await this._doc.getLayerById(layerId);

                const stageShapeAndObjectOptions = await (this.dataParser as ELStageDocDataParser).getStageData(layer)
                const object = await this.addShape(stageShapeAndObjectOptions);

                this.stageDataList.push({ object: object, stageShapeAndObjectOptions: stageShapeAndObjectOptions });
                break;
            }

            case ELStageDocActions.removeLayer: {
                this._removeLayer(updateData.payload as string);
                break;
            }

            case ELStageDocActions.addLayerByIndex: {
                const layer = (updateData.payload as ELStageDocAddLayerByIndexPayload).layer;
                const layerIndex = (updateData.payload as ELStageDocAddLayerByIndexPayload).layerIndex;
                await this._addLayerByIndex(layer, layerIndex);
                break;
            }

            default: {
                Logger.log(LogLevel.WARN, "ELStageDocRenderer::update Invalid update action type: " + updateData.type);
            }
        }
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELStageDocActions.linkLayers: {
                await this._linkLayers(action.payload as ELStageDocLinkLayersPayload);
                handled = true;
                break;
            }
            case ELStageDocActions.updateTextProperty: {
                await this._updateLayerTextProperty(action.payload as ELStageDocTextPropertyPayload);
                handled = true;
                break;
            }
            case ELStageDocActions.updateActiveObject: {
                await this._updateActiveObject(action.payload as string);
                handled = true;
                break;
            }
        }
        if (!handled) {
            handled = await super.notify(action);
        }
        return handled;
    }

    destroy(): void {
        super.destroy();
        this.stage.destroy();
    }
}