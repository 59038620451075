/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { ReactNode, useRef, useState } from 'react';

//Adobe Internal
import { StyleProps } from '@react-types/shared';
import { useStyleProps } from "@react-spectrum/utils";
import { useButton } from "@react-aria/button";

//Application Specific
import { ELIcon } from '../el-icon/ELIconView';
import { IconType } from '../../../../assets/IconConstants';

import "./ELVideo.scss";

export interface ELVideoProps extends StyleProps {
    posterURL?: string,
    src: string,
    type: string,
    variant: "native" | "customContained"
    children?: ReactNode,
    autoPlay?: boolean,
    loop?: boolean,
    muted?: boolean,
    hideControls?: boolean,
    onPlayCallback?: () => void
}

interface ELPlayControlProps {
    isPlaying: boolean,
    onPlay: () => void
}

const ELPlayControl = (props: ELPlayControlProps): React.ReactElement => {
    const [sIsHover, setHover] = useState(false);
    const playRef = useRef(null);
    const { buttonProps } = useButton({
        onPress: () => props.onPlay(),
    }, playRef);

    const getPlayControlClass = (): string => {
        if (props.isPlaying) {
            return "no-display";
        } else if (sIsHover) {
            return "video-play-icon-dark";
        } else {
            return "video-play-icon";
        }
    }

    return (
        <div className={getPlayControlClass()} ref={playRef} {...buttonProps}
            onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
            <ELIcon iconkey={IconType.playIcon} />
        </div>
    )
}

interface ELPauseControlProps {
    isPlaying: boolean,
    onPause: () => void
}

const ELPauseControl = (props: ELPauseControlProps): React.ReactElement => {
    const pauseRef = useRef(null);
    const { buttonProps } = useButton({
        onPress: () => props.onPause(),
    }, pauseRef);

    return (
        <div className={props.isPlaying ? "video-pause-icon" : "no-display"} ref={pauseRef} {...buttonProps}>
            <ELIcon iconkey={IconType.pauseIcon} width="1.2rem" height="1.2rem" />
        </div>
    )
}


const ELVideo = (props: ELVideoProps): React.ReactElement => {
    const {
        posterURL,
        src,
        type,
        variant,
        children,
        autoPlay,
        loop,
        muted,
        hideControls,
        ...otherProps
    } = props;
    const { styleProps } = useStyleProps(otherProps);
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);

    const onPlay = (): void => {
        if (videoRef.current) {
            const video = videoRef.current as HTMLVideoElement;
            if (video) {
                if (video.paused) {
                    video.play();
                    setIsPlaying(true);
                    if (props.onPlayCallback)
                        props.onPlayCallback();
                }
            }
        }
    }

    const onPause = (): void => {
        if (videoRef.current) {
            const video = videoRef.current as HTMLVideoElement;
            if (video) {
                if (video.played) {
                    video.pause();
                    setIsPlaying(false);
                }
            }
        }
    }

    const getVideo = (): ReactNode => {
        if (variant === "customContained") {
            return (<div className="video-box">
                <video data-testid="el-video-player" ref={videoRef} {...styleProps} onContextMenu={(e) => e.preventDefault()} onEnded={() => setIsPlaying(false)}
                    preload="metadata" poster={posterURL} crossOrigin="anonymous">
                    <source src={src} type={type} />
                </video>
                <ELPlayControl isPlaying={isPlaying} onPlay={() => onPlay()} />
                <ELPauseControl isPlaying={isPlaying} onPause={() => onPause()} />
            </div>)
        } else if (variant === "native") {
            return (<video data-testid="el-video-player" autoPlay={autoPlay} muted={muted} controls={!hideControls} loop={loop} {...styleProps} onContextMenu={(e) => e.preventDefault()} disablePictureInPicture preload="auto"
                poster={posterURL} controlsList="nodownload noplaybackrate" playsInline crossOrigin="anonymous">
                <source src={src + "#t=0.001"} type={type} />
            </video>)
        } else {
            return null;
        }
    }

    return (
        <>
            {getVideo()}
        </>
    )
}

ELVideo.defaultProps = {
    posterURL: ""
}

export default ELVideo;
