/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import { v4 as uuid } from "uuid";

//Adobe Internal
import { isAdobeDCXError } from "@dcx/error";
import { AdobeDCXError } from "@dcx/dcx-js";

/**
 * Non-required options to pass into the constructor.
 * @typeParam category - category of the error that can be used to club multiple errors for metrics
 * @typeParam previousError - previous error which caused this error
 * @typeParam isExpected - expected error e.g. lack of access to a resource,
 * @typeParam logMetadata - bag of additional data to be logged with this error
 */

export type LogMetadata = Record<string, unknown>;

export interface ELErrorOptions {
    category?: string;
    previousError?: Readonly<Error>;
    isExpected?: boolean;
}

/**
 * ELError is an Error subclass that provides a number of features that be useful in
 * in logging or in other contexts.
 */
export class ELError extends Error {
    private _uuid?: string;

    /**
     * The previous error that was the source of this error.
     */
    private _previousError?: Readonly<Error>;

    /**
     * A category value that can be used to bucketise errors.
     */
    private _category?: string;

    /**
     * Arbitrary metadata to use if this error is logged. Use when
     * you might want to know additional info without making the
     * error message highly dynamic which impacts error aggregation
     * in analytics.
     */
    private _logMetadata?: LogMetadata;

    /**
     * Constructor
     * @param _code - The error code associated with this error. Useful for grouping similar errors
     *    in logs and elsewhere.
     * @param message - The message associated with this error.
     * @param options - An options object, or an error object to set to previousError (legacy signature)
     */
    constructor(message: string, logMetadata?: LogMetadata, options?: ELErrorOptions | Error) {
        super(message);
        this._logMetadata = logMetadata;
        if (options) {
            if (options instanceof Error) {
                this._previousError = options;
            } else {
                this._previousError = options.previousError;
                this._category = options.category;
            }
        }
    }

    
    /**
     * Returns the full stack trace of the given AdobeDCXError or Error object.
     * If the error is an AdobeDCXError and has an underlying error, the full stack trace
     * of the underlying error is also included.
     *
     * @param error - The AdobeDCXError or Error object.
     * @returns The full stack trace of the error, including any underlying errors.
     */
    private _getDCXErrorFullStack(error: AdobeDCXError | Error): string | undefined {
        let stack = error.stack;
        if (isAdobeDCXError(error) && error.underlyingError) {
            stack += `\nFrom underlying error: ${this._getDCXErrorFullStack(error.underlyingError)}`;
        }
        return stack;
    }

    /**
     * Returns the error message with the error code, if available.
     * If the error is an instance of AdobeDCXError, the error code will be included in the message.
     * If the error is a generic Error, only the error name and message will be included in the message.
     * 
     * @param error - The error object to retrieve the message from.
     * @returns The error message with the error code, if available.
     */
    private _getErrorMessageWithCode(error: AdobeDCXError | Error): string {
        if (isAdobeDCXError(error)) {
            return `${error.name}: ${error.code}: ${error.message}`;
        }
        return `${error.name}: ${error.message}`;
    }


    /**
     * Retrieves the underlying error message for an AdobeDCXError or Error object.
     * If the error is an AdobeDCXError and has an underlying error, the method recursively
     * retrieves the underlying error message.
     * 
     * @param error - The AdobeDCXError or Error object.
     * @returns The error message with the error code and, if applicable, the underlying error message.
     */
    private _getDCXUnderlyingErrorMessage(error: AdobeDCXError | Error): string {
        let result = this._getErrorMessageWithCode(error);
        if (isAdobeDCXError(error) && error.underlyingError) {
            result += `\nFrom underlying error: ${this._getDCXUnderlyingErrorMessage(error.underlyingError)}`;
        }
        return result;
    }

    /**
     * An id, generated on demand, associated with this error. This could be useful
     * to determine if the same error that is caught and thrown has been processed
     * in multiple places.
     */
    
    get uuid(): string {
        if (!this._uuid) {
            this._uuid = uuid();
        }
        return this._uuid;
    }
    
    set uuid(value: string) {
        this._uuid = value;
    }
    
    /**
     * Get the complete stack of this error and the previousError chain.
     */
    get fullStack(): string {
        let result = this.stack || "";
        const IMS_TOKEN_PARAM_REGEX = /access_token=[0-9A-Za-z-._%=]+/;

        if (this._previousError) {
            result += "\nFrom previous error: ";
            if (this._previousError instanceof ELError) {
                result += this._previousError.fullStack;
            } else if (isAdobeDCXError(this._previousError)) {
                result += this._getDCXErrorFullStack(this._previousError);
            } else {
                result += this._previousError.stack;
            }
        }
        // remove access token from the result before returning
        return result.replace(IMS_TOKEN_PARAM_REGEX, "");
    }

    /**
     * @returns a string describing the error including any related codes and qualifiers
     */
    get messageWithCode(): string {
        if (this._category) {
            return `${this._category}: ${this.message}`;
        } else {
            return `${this.message}`;
        }
    }

    /**
     * Get a concatenation of messageWithCode including this error and the previousError chain.
     */
    get fullMessageWithCode(): string {
        let result = this.messageWithCode;

        if (this._previousError) {
            result += "\nFrom previous error: ";
            if (this._previousError instanceof ELError) {
                result += this._previousError.fullMessageWithCode;
            } else if (isAdobeDCXError(this._previousError)) {
                result += this._getDCXUnderlyingErrorMessage(this._previousError);
            } else {
                result += this._previousError;
            }
        }
        return result;
    }

    /**
     * Get an object containing logMetadata for this object and nested errors.
     */
    get fullLogMetadata(): LogMetadata | undefined {
        let metadata = this._logMetadata;
        if (this._previousError instanceof ELError) {
            const prevMetadata = this._previousError.fullLogMetadata;
            if (prevMetadata) {
                // Don't mutate this object's metadata (not that it matters really).
                metadata = Object.assign({}, this._logMetadata);
                metadata.previousLogMetadata = prevMetadata;
            }
        }
        return metadata;
    }

    get previousError(): Readonly<Error> | undefined {
        return this._previousError;
    }

    get category(): string | undefined {
        return this._category;
    }
}
