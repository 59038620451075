/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import {
    CreationType,
    ExportOperationTypeInfo,
    ExportType,
    OutputType,
    ImageFormat,
    CreationOperationSubTypeConfiguration,
    PhotoTextCreationOperationSubTypeInfo,
} from "@elements/elementswebcommon";

//Application Specific
import { CreationsJobCreator } from "../../../utils/CreationsJobCreator";
import { ELImageExportConfigurationParams, IClientCreationRequestParams } from "../../../../../common/interfaces/creations/ELCreationsJobTypes";
import { ELStageLayerDataOptions } from "../../../../../common/interfaces/editing/layer/ELStageLayerTypes";
import Utils from "../../../../../utils/Utils";

export class PhotoTextJobCreator extends CreationsJobCreator {
    private readonly _defaultCreationFileName = "PhotoText.jpg";
    private readonly _defaultExportHeight = 720;
    private readonly _defaultExportWidth = 1440;

    private _createImageExportOperationParams(): ELImageExportConfigurationParams[] {
        const imageExportOperationSubTypeConfigurationParams: ELImageExportConfigurationParams[] = [];

        const outputTypes = [OutputType.preview];

        outputTypes.forEach((outputType) => {
            const fileName = this._defaultCreationFileName;
            const exportFormat = ImageFormat.jpg
            imageExportOperationSubTypeConfigurationParams.push({
                fileName: fileName,
                outputType: outputType,
                exportFormat: exportFormat,
                exportHeight: this._defaultExportHeight,
                exportWidth: this._defaultExportWidth
            });
        });

        return imageExportOperationSubTypeConfigurationParams;
    }

    private _createConfigurations(layerDataOptionsList: ELStageLayerDataOptions[]): CreationOperationSubTypeConfiguration[] {

        const randomUniqueId = Utils.getRandomUUID();

        const singleConfigObj = new CreationOperationSubTypeConfiguration();
        singleConfigObj.version = this.configurationVersion;
        singleConfigObj.id = randomUniqueId;
        singleConfigObj.representativeFolderPath = randomUniqueId;
        singleConfigObj.documentEdit = layerDataOptionsList;

        return [singleConfigObj];
    }

    protected createOperationSubTypeInfo(requestParameters: IClientCreationRequestParams): PhotoTextCreationOperationSubTypeInfo {
        const operationSubTypeInfo = new PhotoTextCreationOperationSubTypeInfo();
        operationSubTypeInfo.version = this.operationSubTypeInfoVersion;
        if (requestParameters.layerDataOptionsList) {
            operationSubTypeInfo.configurations = this._createConfigurations(requestParameters.layerDataOptionsList);
        }
        return operationSubTypeInfo;
    }

    protected createExportOperationTypeInfo(requestParameters: IClientCreationRequestParams): ExportOperationTypeInfo | undefined {
        const exportOperationTypeInfo = new ExportOperationTypeInfo();
        exportOperationTypeInfo.operationSubType = ExportType.image;
        exportOperationTypeInfo.operationSubTypeInfo = this.createImageExportOperationSubTypeInfo(this._createImageExportOperationParams());
        return exportOperationTypeInfo;
    }

    protected getCreationType(): CreationType {
        return CreationType.photoText;
    }
}