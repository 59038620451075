/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { CreationsStatus } from "../../common/interfaces/creations/CreationTypes";
import { ELRecommendationWorkflowActionTypes } from "../../common/interfaces/creations/ELRecommendationsWorkflowTypes";
import IAction from "./IAction";

export type RecommendationWorkflowActionPayload = CreationsStatus;

export class RecommendationWorkflowAction {

    public static updateRecommendationStatus(status: CreationsStatus): IAction<CreationsStatus> {
        return {
            type: ELRecommendationWorkflowActionTypes.UPDATE_RECOMMENDATION_STATUS,
            payload: status
        };
    }

    public static updateSelectedOverlayId(overlayId?: string): IAction<string> {
        return {
            type: ELRecommendationWorkflowActionTypes.UPDATE_SELECTED_OVERLAY_ID,
            payload: overlayId
        };
    }

    public static updateSelectedOverlayName(overlayName?: string): IAction<string> {
        return {
            type: ELRecommendationWorkflowActionTypes.UPDATE_SELECTED_OVERLAY_NAME,
            payload: overlayName
        };
    }

    public static updateContentSource(contentSource?: string): IAction<string> {
        return {
            type: ELRecommendationWorkflowActionTypes.UPDATE_CONTENT_SOURCE,
            payload: contentSource
        }
    }

    public static updateProjectTitle(title: string): IAction<string> {
        return {
            type: ELRecommendationWorkflowActionTypes.UPDATE_PROJECT_TITLE,
            payload: title
        }
    }

    public static updateProjectStatus(status: CreationsStatus): IAction<CreationsStatus> {
        return {
            type: ELRecommendationWorkflowActionTypes.UPDATE_PROJECT_STATUS,
            payload: status
        }
    }

    public static updateUITitle(uiTitle: string): IAction<string> {
        return {
            type: ELRecommendationWorkflowActionTypes.UPDATE_UI_TITLE,
            payload: uiTitle
        }
    }
}