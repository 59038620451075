/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { API } from "@elements/elementswebcommon";

//Application Specific
import Logger, { LogLevel } from "../../utils/Logger";
import IMS from "../IMS";

export const TRIAL_DAYS_NOT_RECEIVED = "Trial days not present in API response";
const ELEMENTS_TRIAL_ENDPOINT = "trial";

export class TrialService {
    private _serviceAPI: API;

    constructor() {
        if (process.env.REACT_APP_ELEMENTS_V1_URL === undefined) {
            throw new Error("API server URL not found in env");
        }

        if (process.env.REACT_APP_ELEMENTS_API_KEY === undefined) {
            throw new Error("API Key is not set");
        }

        this._serviceAPI = new API(`${process.env.REACT_APP_ELEMENTS_V1_URL}`);
    }

    private _getElementsTrialAPIConfig(): Record<string, Record<string, string>> {
        return {
            headers: {
                "Content-Type": "application/json",
                "X-api-key": `${process.env.REACT_APP_ELEMENTS_API_KEY}`,
                "Authorization": `Bearer ${IMS.getInstance().getUserAccessToken()}`
            }
        }
    }

    async getDaysInTrial(): Promise<number> {
        try {
            const response = await this._serviceAPI.get(ELEMENTS_TRIAL_ENDPOINT as string, this._getElementsTrialAPIConfig());
            const daysInTrial = response.data.daysInTrial;
            return daysInTrial;
        } catch (error: any) {
            Logger.log(LogLevel.WARN, "TrialService:getDaysInTrial: ", "error getting trial info", error.code);
            return Promise.reject(error);
        }
    }

    async startTrial(): Promise<void> {
        try {
            await this._serviceAPI.post({}, this._getElementsTrialAPIConfig(), ELEMENTS_TRIAL_ENDPOINT as string);
            return Promise.resolve();
        } catch (error: any) {
            Logger.log(LogLevel.ERROR, "TrialService:startTrial: ", "error starting trial for user", error);
            return Promise.reject(error);
        }

    }
}