/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import _ from "lodash";

//Application Specific
import { CreationsMode, CreationsStatusPayload, CreationsURLParams } from "../../../../../common/interfaces/creations/CreationTypes";
import { ELAdobeAsset } from "../../../../../common/interfaces/storage/AssetTypes";
import { GIF_FILE_FORMAT, PSD_FILE_FORMATS, PSDC_FILE_FORMAT } from "../../../../../common/interfaces/storage/FileTypes";
import { FilterMediaData, FilterMediaUiProp, GridMediaType, MediaGridConfig, MediaGridSortBy } from "../../../../../stores/actions/mediaGridConfigActions";
import { GRID_CONFIG_KEY } from "../../../../../stores/reducers/mediaGridConfigReducer";
import store from "../../../../../stores/store";
import Utils from "../../../../../utils/Utils";
import { WorkspaceAction, WorkspacePayload } from "../../../../IBaseWorkspace";
import { WorkflowsName } from "../../../../IWorkflow";
import { AssetStorageUtils } from "../../../../../utils/AssetStorageUtils";
import CreationUtils from "../../../utils/CreationUtils";
import Logger, { LogLevel } from "../../../../../utils/Logger";
import { Routes } from "../../../../../app/AppRoute";


export default class PhotoTextUtils {
    static getMediaGridConfig(): MediaGridConfig {
        const mediaGridConfig = _.cloneDeep(store.getState().mediaConfigReducer[GRID_CONFIG_KEY]);
        mediaGridConfig.sortBy = MediaGridSortBy.importDate;
        mediaGridConfig.mediaType = GridMediaType.eImageOnly;
        const filterData: FilterMediaData = {
            format: [PSDC_FILE_FORMAT, GIF_FILE_FORMAT, ...PSD_FILE_FORMATS],
            uiProp: FilterMediaUiProp.hide
        }
        mediaGridConfig.filterMedia = filterData;
        mediaGridConfig.maxMediaCount = 1;
        mediaGridConfig.maxImageCount = 1;
        mediaGridConfig.minImageCount = 1;
        mediaGridConfig.maxVideoCount = 0;
        mediaGridConfig.maxTotalMediaSize = 400; //in MB
        mediaGridConfig.maxVideoLength = 0;
        mediaGridConfig.selectUploadedMedia = true;
        return mediaGridConfig;
    }

    static isOpeningExistingProject(workspaceAction: WorkspaceAction): boolean {
        return workspaceAction.initMode === CreationsMode.open;
    }

    static getProjectIdFromHref(): string {
        const projectId = Utils.getLinkParamValue(window.location.href, CreationsURLParams.projectId);
        return projectId ?? Utils.getNilUUID();
    }

    static getPayload(): WorkspacePayload {
        const projectId = PhotoTextUtils.getProjectIdFromHref();
        let mode = CreationsMode.open;
        let payload: CreationsStatusPayload | ELAdobeAsset[] | null = {
            projectId: projectId
        };

        if (projectId === Utils.getNilUUID()) {
            mode = CreationsMode.create;
            payload = store.getState().selectedMediaListReducer;
        }

        const workspacePayload: WorkspacePayload = {
            startWorkflow: WorkflowsName.photoText,
            initMode: mode,
            payload: payload
        }

        return workspacePayload;
    }

    static hasPhotoTextUrl(): boolean {
        const workflow = Utils.getLinkParamValue(window.location.href, CreationsURLParams.workflow);
        return (workflow !== null && WorkflowsName.photoText.toLowerCase() === workflow.toLowerCase());
    }

    static getHistoryState(projectId: string): string {
        const historyState = Routes.CREATIONS + "?" + CreationsURLParams.workflow + "=" + WorkflowsName.photoText.toLowerCase() +
            "&" + CreationsURLParams.projectId + "=" + projectId;
        return historyState;
    }

    static getPhotoTextIdFromHref(): string {
        const projectId = Utils.getLinkParamValue(window.location.href, CreationsURLParams.projectId);
        return projectId ?? Utils.getNilUUID();
    }

    static canStart(selectedAssets: ELAdobeAsset[]): boolean {
        const minImageCount = PhotoTextUtils.getMediaGridConfig().minImageCount;
        if (minImageCount && selectedAssets.length >= minImageCount)
            return true;
        return false;
    }

    static isMaxImageLimitExceeded(selectedAssets: ELAdobeAsset[]): boolean {
        const maxImageCount = PhotoTextUtils.getMediaGridConfig().maxImageCount;
        if ((maxImageCount !== undefined) && selectedAssets.length > maxImageCount)
            return true;
        return false;
    }

    static async getFilteredAssetList(assets: ELAdobeAsset[], mediaGridConfig: MediaGridConfig): Promise<ELAdobeAsset[]> {
        let filteredAssets = AssetStorageUtils.filterRemoveVideoAssets(assets);
        filteredAssets = AssetStorageUtils.filterRemoveGifAssets(filteredAssets);
        filteredAssets = CreationUtils.filterImagesByCount(filteredAssets, mediaGridConfig);

        Logger.log(LogLevel.INFO, "PhotoTextUtils - getFilteredAssetList: ", filteredAssets);
        return filteredAssets;
    }
}