/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import { fabric } from "fabric";

//Application Specific
import { ELSize } from "../../../common/interfaces/geometry/ELGeometry";
import { ELFabricInfo } from "../../../common/interfaces/stage/StageTypes";
import { ELLayoutInfo, ELLayoutModeLayerName } from "../../../common/interfaces/creations/ELSocialLayoutTypes";
import { RenderedShapesName } from "../../../common/interfaces/renderer/RendererTypes";
import store from "../../../stores/store";
import MathUtils from "../../../utils/MathUtils";

export default class ELFabricLayoutHandler {
    private _resetBackgroundImageState(backgroundImage: fabric.Object, fabricInfo: ELFabricInfo): void {
        backgroundImage.scale(fabricInfo.imageAreaTransformScale);
        backgroundImage.left = fabricInfo.imageAreaTransformOffset[0];
        backgroundImage.top = fabricInfo.imageAreaTransformOffset[1];
    }

    private _resetLayoutClipState(clipPath: fabric.Rect, fabricInfo: ELFabricInfo): void {
        clipPath.set({
            left: fabricInfo.imageAreaTransformOffset[0],
            top: fabricInfo.imageAreaTransformOffset[1],
            width: fabricInfo.docSize.width,
            height: fabricInfo.docSize.height
        });
    }

    startLayoutWorkflow(canvas: fabric.Canvas, fabricInfo: ELFabricInfo): void {
        const clipWidth = fabricInfo.layoutInfo ? (fabricInfo.layoutInfo.right - fabricInfo.layoutInfo.left) * fabricInfo.docSize.width * fabricInfo.layoutInfo.scaleX : fabricInfo.docSize.width;
        const clipHeight = fabricInfo.layoutInfo ? (fabricInfo.layoutInfo.bottom - fabricInfo.layoutInfo.top) * fabricInfo.docSize.height * fabricInfo.layoutInfo.scaleY : fabricInfo.docSize.height;
        const clipLeft = fabricInfo.imageAreaTransformOffset[0] + ((fabricInfo.docSize.width - clipWidth) * fabricInfo.imageAreaTransformScale) / 2;
        const clipTop = fabricInfo.imageAreaTransformOffset[1] + ((fabricInfo.docSize.height - clipHeight) * fabricInfo.imageAreaTransformScale) / 2;

        const clipArea = new fabric.Rect({
            objectCaching: false,
            originX: "left",
            originY: "top",
            left: clipLeft,
            top: clipTop,
            width: clipWidth,
            height: clipHeight,
            absolutePositioned: true,
            selectable: false,
            scaleX: fabricInfo.imageAreaTransformScale,
            scaleY: fabricInfo.imageAreaTransformScale,
            inverted: true
        });

        const layoutFloor = new fabric.Rect({
            objectCaching: false,
            name: ELLayoutModeLayerName.floor,
            originX: "left",
            originY: "top",
            left: fabricInfo.imageAreaTransformOffset[0],
            top: fabricInfo.imageAreaTransformOffset[1],
            width: fabricInfo.docSize.width,
            height: fabricInfo.docSize.height,
            absolutePositioned: true,
            selectable: false,
            fill: '#F5F5F5',
            scaleX: fabricInfo.imageAreaTransformScale,
            scaleY: fabricInfo.imageAreaTransformScale,
            opacity: 0.7,
            clipPath: clipArea
        });

        const imageLeft = fabricInfo.layoutInfo ? clipLeft - fabricInfo.layoutInfo.left * fabricInfo.docSize.width * fabricInfo.layoutInfo.scaleX * fabricInfo.imageAreaTransformScale : clipLeft;
        const imageTop = fabricInfo.layoutInfo ? clipTop - fabricInfo.layoutInfo.top * fabricInfo.docSize.height * fabricInfo.layoutInfo.scaleY * fabricInfo.imageAreaTransformScale : clipTop;

        const imageBackground = canvas.getObjects().filter((object) => object.name === RenderedShapesName.imageAreaWithLayout)[0];

        imageBackground.set({
            name: ELLayoutModeLayerName.image,
            selectable: true,
            hasControls: false,
            borderColor: "transparent",
            left: imageLeft,
            top: imageTop,
            scaleX: (fabricInfo.layoutInfo?.scaleX ?? 1) * fabricInfo.imageAreaTransformScale,
            scaleY: (fabricInfo.layoutInfo?.scaleY ?? 1) * fabricInfo.imageAreaTransformScale
        });

        canvas.clear();
        canvas.add(imageBackground);
        canvas.add(layoutFloor);
        canvas.setActiveObject(imageBackground);
    }

    private _clampLayoutInfo(layoutInfo: ELLayoutInfo): void {
        layoutInfo.left = MathUtils.clampValue(layoutInfo.left, 0, 1);
        layoutInfo.right = MathUtils.clampValue(layoutInfo.right, 0, 1);
        layoutInfo.bottom = MathUtils.clampValue(layoutInfo.bottom, 0, 1);
        layoutInfo.top = MathUtils.clampValue(layoutInfo.top, 0, 1);
    }

    getLayoutInfo(canvas: fabric.Canvas): ELLayoutInfo | undefined {
        const objects = canvas.getObjects();
        const layoutFloor = objects.filter((val) => { return val.name === ELLayoutModeLayerName.floor })[0] as fabric.Rect;
        const backgroundImage = objects.filter((val) => { return val.name === ELLayoutModeLayerName.image })[0];

        const layoutRectangle = layoutFloor.clipPath;
        if (layoutRectangle) {
            layoutFloor.setCoords();
            backgroundImage.setCoords();
            layoutRectangle.setCoords();

            const layoutFloorRectangle = layoutFloor.getBoundingRect();
            const layoutBoundingRectangle = layoutRectangle.getBoundingRect();
            const imageBoundingRectangle = backgroundImage.getBoundingRect();

            const layoutInfo: ELLayoutInfo = {
                left: (layoutBoundingRectangle.left - imageBoundingRectangle.left) / imageBoundingRectangle.width,
                right: (layoutBoundingRectangle.left + layoutBoundingRectangle.width - imageBoundingRectangle.left) / imageBoundingRectangle.width,
                top: (layoutBoundingRectangle.top - imageBoundingRectangle.top) / imageBoundingRectangle.height,
                bottom: (layoutBoundingRectangle.top + layoutBoundingRectangle.height - imageBoundingRectangle.top) / imageBoundingRectangle.height,
                scaleX: imageBoundingRectangle.width / layoutFloorRectangle.width,
                scaleY: imageBoundingRectangle.height / layoutFloorRectangle.height,
                layoutId: store.getState().layoutReducer.selectedLayout
            };
            this._clampLayoutInfo(layoutInfo);
            return layoutInfo;
        }
        return undefined;
    }

    changeLayoutWorkflow(canvas: fabric.Canvas, fabricInfo: ELFabricInfo, aspectRatio: ELSize): void {
        const objects = canvas.getObjects();
        const layoutFloor = objects.filter((val) => { return val.name === ELLayoutModeLayerName.floor })[0] as fabric.Rect;
        const backgroundImage = objects.filter((val) => { return val.name === ELLayoutModeLayerName.image })[0];
        if (backgroundImage) {
            const docSize = fabricInfo.docSize;
            let rectWidth: number, rectHeight: number;
            if (docSize.width / docSize.height >= aspectRatio.width / aspectRatio.height) {
                rectHeight = docSize.height;
                rectWidth = (rectHeight * aspectRatio.width) / aspectRatio.height;
            } else {
                rectWidth = docSize.width;
                rectHeight = (rectWidth * aspectRatio.height) / aspectRatio.width;
            }

            if (layoutFloor.clipPath instanceof fabric.Rect) {
                layoutFloor.clipPath.set({
                    left: docSize.width === rectWidth ? fabricInfo.imageAreaTransformOffset[0] : fabricInfo.imageAreaTransformOffset[0] + ((docSize.width - rectWidth) * fabricInfo.imageAreaTransformScale) / 2,
                    top: docSize.height === rectHeight ? fabricInfo.imageAreaTransformOffset[1] : fabricInfo.imageAreaTransformOffset[1] + ((docSize.height - rectHeight) * fabricInfo.imageAreaTransformScale) / 2,
                    width: rectWidth,
                    height: rectHeight,
                });
            }

            this.onBackgroundImageMoving(canvas);
            canvas.requestRenderAll();
        }
    }

    scale(canvas: fabric.Canvas, fabricInfo: ELFabricInfo, val: number): void {
        const objects = canvas.getObjects();
        const backgroundImage = objects.filter((val) => { return val.name === ELLayoutModeLayerName.image })[0];
        if (backgroundImage) {
            canvas.setActiveObject(backgroundImage);
            backgroundImage.setCoords();
            const center = backgroundImage.getCenterPoint();
            backgroundImage.scale(fabricInfo.imageAreaTransformScale * (1 + val / 100));
            backgroundImage.setPositionByOrigin(center, 'center', 'center');
            this.onBackgroundImageMoving(canvas);
            canvas.requestRenderAll();
        }
    }

    revertLayoutWorkflow(canvas: fabric.Canvas, fabricInfo: ELFabricInfo): void {
        const objects = canvas.getObjects();
        const layoutFloor = objects.filter((val) => { return val.name === ELLayoutModeLayerName.floor })[0] as fabric.Rect;
        const backgroundImage = objects.filter((val) => { return val.name === ELLayoutModeLayerName.image })[0];
        if (backgroundImage && layoutFloor.clipPath) {
            this._resetBackgroundImageState(backgroundImage, fabricInfo);
            this._resetLayoutClipState(layoutFloor.clipPath, fabricInfo);
            canvas.requestRenderAll();
        }
    }

    onBackgroundImageMoving(canvas: fabric.Canvas): void {
        const objects = canvas.getObjects();
        const layoutFloor = objects.filter((val) => { return val.name === ELLayoutModeLayerName.floor })[0] as fabric.Rect;
        const backgroundImage = objects.filter((val) => { return val.name === ELLayoutModeLayerName.image })[0];
        const rectBoundary = layoutFloor.clipPath;
        if (backgroundImage && rectBoundary &&
            backgroundImage.left && backgroundImage.top && rectBoundary.left && rectBoundary.top) {
            backgroundImage.setCoords();
            rectBoundary.setCoords();

            if (backgroundImage.left > rectBoundary.left) {
                backgroundImage.left = rectBoundary.left;
            } else if (backgroundImage.left + backgroundImage.getScaledWidth() < rectBoundary.left + rectBoundary.getScaledWidth()) {
                backgroundImage.left = rectBoundary.left + rectBoundary.getScaledWidth() - backgroundImage.getScaledWidth();
            }

            if (backgroundImage.top > rectBoundary.top) {
                backgroundImage.top = rectBoundary.top;
            } else if (backgroundImage.top + backgroundImage.getScaledHeight() < rectBoundary.top + rectBoundary.getScaledHeight()) {
                backgroundImage.top = rectBoundary.top + rectBoundary.getScaledHeight() - backgroundImage.getScaledHeight();
            }
        }
    }
}