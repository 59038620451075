/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from 'react';
import ReactDOM from 'react-dom';

//Application Specific
import { ControllerAction } from '../../../IViewController';
import { CreationMediaActionType } from '../el-creation-media-panel/ELCreationMediaView';
import { ViewAction } from '../../../IBaseController';
import Logger, { LogLevel } from '../../../../utils/Logger';
import ITemplateViewController from '../../../ITemplateViewController';
import { ELSingleImageMediaManagerView } from './ELSingleImageMediaManagerView';
import { CreationReducers } from '../../../../common/interfaces/creations/CreationTypes';

export class ELSingleImageMediaManager extends ITemplateViewController {

    createView(container: HTMLElement): void {
        throw new Error("It is not called currently, viewProps needs to be added to createView for using this.");
    }

    destroyView(): void {
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container);
        }
        super.destroyView();
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    destroy(): void {
        super.destroy();
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case CreationMediaActionType.replaceMedia:
                {
                    this._owner.notify(action);
                    handled = true;
                    break;
                }
            default:
                {
                    Logger.log(LogLevel.WARN, "ELSingleImageMediaManager - notify : Invalid action" + action);
                }
        }
        return handled;
    }

    getView(viewProps?: unknown): React.ReactElement {
        const currentReducer = viewProps as CreationReducers;
        return React.createElement(ELSingleImageMediaManagerView, {
            controller: this, currentReducer: currentReducer
        })
    }
}
