/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";

//Application Specific
import { ViewAction } from "../../../IBaseController";
import ITemplateViewController from "../../../ITemplateViewController";
import { ELReplaceBackgroundContentPanelView } from "./ELReplaceBackgroundContentPanelView";
import { ControllerAction } from "../../../IViewController";
import { ELPreviewCreationThumbData, ELRecommendationWorkflowControllerActions } from "../../../../common/interfaces/creations/ELRecommendationsWorkflowTypes";
import store from "../../../../stores/store";
import { RecommendationWorkflowAction } from "../../../../stores/actions/RecommendationWorkflowAction";
import { ELReplaceBackgroundContentSource } from "../../../../common/interfaces/creations/ELReplaceBackgroundTypes";
import { ELStockThumbData, ELStockWorkflowActions } from "../../../../common/interfaces/services/StockServiceTypes";
import { AdobeStockAction } from "../../../../stores/actions/AdobeStockAction";

export class ELReplaceBackgroundContentPanel extends ITemplateViewController {

    createView(container: HTMLElement): void {
        throw new Error("It is not called currently, viewProps needs to be added to createView for using this.");
    }

    destroyView(): void {
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container);
        }
        super.destroyView();
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    destroy(): void {
        super.destroy();
    }

    getView(viewProps?: unknown): React.ReactNode {
        return React.createElement(ELReplaceBackgroundContentPanelView, {
            controller: this
        })
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELRecommendationWorkflowControllerActions.overlayClicked: {
                const overlayName = (action.payload as ELPreviewCreationThumbData).name;
                store.dispatch(RecommendationWorkflowAction.updateSelectedOverlayName(overlayName));
                handled = await this._owner.notify(action);
                break;
            }
            case ELStockWorkflowActions.stockThumbnailClicked: {
                const payload = action.payload as ELStockThumbData;
                store.dispatch(RecommendationWorkflowAction.updateContentSource(ELReplaceBackgroundContentSource.adobeStockPreview));
                store.dispatch(AdobeStockAction.updateSelectedStockThumbnail(payload.thumbnailUrl));
                store.dispatch(AdobeStockAction.updateSelectedStockId([payload.id]));
                handled = await this._owner.notify(action);
                break;
            }
            case ELStockWorkflowActions.applyStockLicensedImage: {
                store.dispatch(RecommendationWorkflowAction.updateContentSource(ELReplaceBackgroundContentSource.adobeStock));
                store.dispatch(AdobeStockAction.updateSelectedStockId([action.payload as string]));
                handled = await this._owner.notify(action);
                break;
            }
        }
        if (!handled)
            handled = await this._owner.notify(action);

        return handled;
    }
}
