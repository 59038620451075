/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { OutputType, Resolution } from "@elements/elementswebcommon";

//Application Specific
import { IClientCreationRequestParams } from "./ELCreationsJobTypes";
import { ELAdobeAsset } from "../storage/AssetTypes";

export interface ELReplaceBackgroundCreationRequestParams extends IClientCreationRequestParams {
    backgroundId: string[],
    outputTypes: OutputType[],
    contentResolution: Resolution,
    contentSource?: ELReplaceBackgroundContentSource
}

export enum ELReplaceBackgroundProjectActionMode {
    regular = "regular",
    openInDesktop = "openInDesktop"
}

export interface ELReplaceBackgroundUpdateDocumentPayload {
    mapOfTemplateIdToAsset: Map<string, ELAdobeAsset>,
    overlayId: string
}

export enum ELReplaceBackgroundContentSource {
    adobeStock = "adobeStock",
    adobeStockPreview = "adobeStockPreview",
    default = "default"
}