/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { Asset, OperationWorker, OutputType, Version } from "@elements/elementswebcommon";

//Application Specefic
import { ELCreationsPathResolver } from "./ELCreationsPathResolver";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { ELAssetPathOrId } from "../../../../common/interfaces/storage/AssetTypes";

export class ELMovingOverlayPathResolver extends ELCreationsPathResolver {

    private async _getMasterAssetData(): Promise<Asset[]> {
        try {
            const requestObj = await this.getRequestObjectFromProjectData();
            const result = new OperationWorker(requestObj.operations[0]);
            const assets = result.getOutputTypeAssets(OutputType.full);
            return assets;
        } catch (e) {
            Logger.log(LogLevel.ERROR,
                "ELMovingOverlayPathResolver: _getMasterAssetPath, not able to get request object from project data");
            return Promise.reject();
        }
    }

    async getCreationMasterAssetData(): Promise<Asset[]> {
        const requestVersion = this.getCreationVersion();
        switch (requestVersion) {
            case Version.V3: {
                const assets = await this._getMasterAssetData();
                return assets;
            }
            default: {
                Logger.log(LogLevel.ERROR, "ELMovingOverlayPathResolver: getCreationMasterAssetData, wrong version, ", requestVersion);
                return Promise.reject();
            }
        }
    }

    async getCreationPreviewAssetPathOrId(): Promise<ELAssetPathOrId> {
        const requestVersion = this.getCreationVersion();
        switch (requestVersion) {
            case Version.V3: {
                //TODO: Sundaram
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return this.getAssetIdFromOutputInfo(this.creationsData.outputs!.preview!);
            }
            default: {
                Logger.log(LogLevel.ERROR, "ELMovingOverlayPathResolver: getCreationPreviewAssetPathOrId, wrong version, ", requestVersion);
                return Promise.reject();
            }
        }
    }

    async getCreationFullAssetPathOrId(): Promise<ELAssetPathOrId> {
        const requestVersion = this.getCreationVersion();
        switch (requestVersion) {
            case Version.V3: {
                //TODO: Sundaram
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return this.getAssetIdFromOutputInfo(this.creationsData.outputs!.media!);
            }
            default: {
                Logger.log(LogLevel.ERROR, "ELMovingOverlayPathResolver: getCreationFullAssetPathOrId, wrong version, ", requestVersion);
                return Promise.reject();
            }
        }
    }
}