/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//Adobe internal
import { Flex, IllustratedMessage } from "@adobe/react-spectrum";
import DropZone from "@react/react-spectrum/DropZone";

//Adobe Internal
import { IconType } from "../../../../assets/IconConstants";
import { ELIcon } from "../../atoms/el-icon/ELIconView";
import IViewController from "../../../IViewController";
import ImportButton from "../el-import-button/ELImportButton";
import { RootState } from "../../../../stores/store";

import "./ELDropZone.scss";

interface ELDropZoneViewProps {
    controller: IViewController,
    allowedFilesFilter: (file: File) => boolean,
    handleDroppedFiles: (files: File[]) => void,
    dragAndDropHeading: string,
    dragAndDropContentText: string,
    dragAndDropFilledText: string,
}

export const ELDropZoneView = (props: ELDropZoneViewProps): React.ReactElement => {
    const [isDropped, setIsDropped] = useState<boolean>(false);
    const isImportInProgress = useSelector((state: RootState) => state.mediaGridToolbarStateReducer?.importInProgress ?? false);

    useEffect(() => {
        if (!isImportInProgress)
            setIsDropped(false);
    }, [isImportInProgress]);

    const getFilledDropZoneView = (): React.ReactElement => {
        return (
            <Flex direction="column" alignItems="center" justifyContent="center"   height="18.6rem" >
                <span className="drop-zone__heading">{props.dragAndDropFilledText}</span>
            </Flex>
        );
    };

    const getUnFilledDropZoneView = (): React.ReactElement => {
        return (
            <IllustratedMessage UNSAFE_className="drop-zone__illustration-message" justifySelf="start">
                <ELIcon className="drop-zone__illustration-image" iconkey={IconType.emptyELMediaGrid} width="6rem" height="10rem"/>
                <span className="drop-zone__heading">{props.dragAndDropHeading}</span>
                <span className="drop-zone__content">{props.dragAndDropContentText}</span>
                <ImportButton controller={props.controller} isDisabled={isImportInProgress} variant={"upload-media"} />
            </IllustratedMessage>
        );
    };

    const onDropHandler = (e: React.DragEvent<Element>): void => {
        const files = e.dataTransfer.files;

        const allowedFiles = Array.from(files).filter((file) => {
            return props.allowedFilesFilter(file);
        });

        if (allowedFiles.length > 0) {
            setIsDropped(true);
            props.handleDroppedFiles(allowedFiles);
        }
    };

    return (
        <DropZone className="drop-zone__container" onDrop={(e) => onDropHandler(e)}>
            {isDropped ? getFilledDropZoneView() : getUnFilledDropZoneView()}
        </DropZone>
    );
};