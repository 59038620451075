/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

//Adobe internal
import { Grid, ProgressCircle, repeat, Text } from "@adobe/react-spectrum";

//Application specific
import ELPanelHeader from "../../atoms/el-panel-header/ELPanelHeader";
import ELScrollPanel from "../../atoms/el-scroll-panel/ELScrollPanel";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { ELTransparentCard } from "../../organism/el-transparent-card/ELTransparentCard";
import store, { RootState } from "../../../../stores/store";
import { TRANSPARENT_BACKGROUND_ID, TRANSPARENT_BACKGROUND_NAME } from "../../../../common/interfaces/creations/CreationTypes";
import { ELRecommendationWorkflowControllerActions } from "../../../../common/interfaces/creations/ELRecommendationsWorkflowTypes";
import { ELReplaceBackgroundContentPanel } from "./ELReplaceBackgroundContentPanel";
import ELButton from "../../atoms/el-button/ELButtonView";
import { FeaturesManager } from "../../../../modules/floodgate/Featuresmanager";
import { FeatureName } from "../../../../services/Floodgate/FloodgateConstants";
import { useDynamicLayout } from "../../../../utils/hooks/useDynamicLayout";
import ELStockLicenseAndDownloadDialog from "../../organism/el-stock-license-download-dialog/ELStockLicenseAndDownloadDialog";
import ELCustomIcon from "../../molecules/el-custom-icon/ELCustomIcon";
import { Theme } from "../../../../utils/Theme";
import { IconType } from "../../../../assets/IconConstants";
import { StockServiceUtils } from "../../../../utils/StockServiceUtils";
import { ToastUtils } from "../../../../utils/ToastUtils";
import { ELStockWorkflowActions } from "../../../../common/interfaces/services/StockServiceTypes";
import Utils from "../../../../utils/Utils";
import { AdobeStockAction } from "../../../../stores/actions/AdobeStockAction";

//CSS
import "./ELReplaceBackgroundContentPanel.scss";

interface ELReplaceBackgroundContentPanelViewProps {
    controller: ELReplaceBackgroundContentPanel;
}

const ELReplaceBackgroundContentPanelView = (props: ELReplaceBackgroundContentPanelViewProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();

    const specLayoutConfig = { TILE_W: 5, TILE_H: 5, DIST_BETWEEN_TILE: 1 };
    const gridRef = useRef(null);
    const scrollParentRef = useRef(null);
    const viewLayoutConfig = useDynamicLayout({
        specLayoutConfig: specLayoutConfig,
        observerRef: scrollParentRef,
        gridRef: gridRef
    });

    const [featuredBackgrounds, setFeaturedBackgrounds] = useState<any[]>([]);
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [recentSearches, setRecentSearches] = useState<string[]>([]);
    const [viewMode, setViewMode] = useState<"default" | "searchResults">("default");
    const [isRegionDisabled, setIsRegionDisabled] = useState(true);
    const [selectedImageId, setSelectedImageId] = useState<string>("");
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [isLoadingBackgrounds, setIsLoadingBackgrounds] = useState(false);
    const [offset, setOffset] = useState<number>(0);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [totalResults, setTotalResults] = useState<number>(0);
    const limit = 64;

    const recommendationWorkflowReducer = useSelector((state: RootState) => state.recommendationWorkflowReducer);
    const adobeStockReducer = useSelector((state: RootState) => state.adobeStockReducer);


    useEffect(() => {
        setDialogOpen(adobeStockReducer.isAdobeStockDialogVisible === "true");
    }, [adobeStockReducer.isAdobeStockDialogVisible]);

    useEffect(() => {
        const selectedImageIdString = adobeStockReducer.selectedStockIds;
        const imageId = selectedImageIdString ? JSON.parse(selectedImageIdString)[0] : "";
        setSelectedImageId(imageId);
    }, [adobeStockReducer.selectedStockIds]);

    useEffect(() => {
        const loadRecentSearches = () => {
            const storedSearches = JSON.parse(localStorage.getItem("RECENT_SEARCHES_KEY") || "[]");
            setRecentSearches(storedSearches);
        };

        const initializeAndFetchBackgrounds = async () => {
            props.controller.initialize();
            await fetchFeaturedBackgrounds();
        };

        loadRecentSearches();
        initializeAndFetchBackgrounds();

        return () => {
            props.controller.destroy();
        };
    }, [props.controller]);

    const shouldShowDialog = (): boolean => {
        if (selectedImageId !== "" && !isRegionDisabled) {
            return dialogOpen;
        }
        return false;
    }

    const fetchFeaturedBackgrounds = async () => {
        setIsLoadingBackgrounds(true);
        try {
            const response = await StockServiceUtils.fetchImages("backgrounds", Utils.getCurrentLocaleInSnakeCase(), 0, limit);
            setTotalResults(response.nb_results)
            setFeaturedBackgrounds(response.files);
            setSearchResults(response.files);
        } catch (error) {
            console.error("Error fetching featured backgrounds:", error);
        } finally {
            setIsLoadingBackgrounds(false);
        }
    };

    const fetchBackgrounds = async (query: string, newOffset: number) => {

        const allBackgroundsFetched = newOffset >= totalResults;
        if (allBackgroundsFetched) {
            return;
        }
        try {
            setIsFetching(true);
            const response = await StockServiceUtils.fetchImages(query, Utils.getCurrentLocaleInSnakeCase(), newOffset, limit);
            setSearchResults((prevResults) => [...prevResults, ...response.files]);
            setOffset(newOffset + limit);
            setTotalResults(response.nb_results);
        } catch (error) {
            console.error("Error fetching backgrounds:", error);
        } finally {
            setIsFetching(false);
        }
    };

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        const buffer = 200;
        if (scrollTop + clientHeight + buffer >= scrollHeight && !isFetching) {
            fetchBackgrounds(searchQuery, offset);
        }
    };

    const handleSearchChange = async (value: string) => {
        setSearchQuery(value);
        if (!value.trim()) {
            setSearchResults(featuredBackgrounds);
            return;
        }
        setShowSuggestions(!!value.trim() || recentSearches.length > 0);
    };

    const handleSearchSubmit = async (query: string) => {
        setViewMode("searchResults");
        setShowSuggestions(false);
        setSearchQuery(query);
        setIsLoadingBackgrounds(true);
        const response = await StockServiceUtils.fetchImages(query, Utils.getCurrentLocaleInSnakeCase(), offset, limit);
        setIsLoadingBackgrounds(false);
        setSearchResults(response.files);
        setOffset(offset + limit);
        setTotalResults(response.nb_results);
        setShowSuggestions(false);

        const updatedSearches = [query, ...recentSearches.filter((item) => item !== query)].slice(0, 5);
        setRecentSearches(updatedSearches);
        localStorage.setItem("RECENT_SEARCHES_KEY", JSON.stringify(updatedSearches));

        props.controller.notify({ type: ELStockWorkflowActions.stockSearchTriggered, payload: query })
    };

    const handleViewAllClick = () => {
        setViewMode("searchResults");
        setShowSuggestions(false);
    }

    const handleBackToDefault = (): void => {
        setShowSuggestions(false);
        if (selectedImageId !== "" && !isRegionDisabled) {
            setDialogOpen(true);
        } else {
            setViewMode("default");
            setSearchQuery("");
            setSearchResults(featuredBackgrounds);
        }
        props.controller.notify({ type: ELStockWorkflowActions.stockBackButtonClicked })
    };

    const handleImageClick = async (imageInfo: any) => {
        props.controller.notify({ type: ELStockWorkflowActions.stockImageClicked });
        const isImageLicensed = await StockServiceUtils.isImageLicensed(imageInfo.id);
        setIsRegionDisabled(isImageLicensed);
        setSelectedImageId(imageInfo.id);
        if (isImageLicensed) {
            props.controller.notify({ type: ELStockWorkflowActions.applyStockLicensedImage, payload: imageInfo.id });
        } else {
            props.controller.notify({
                type: ELStockWorkflowActions.stockThumbnailClicked,
                payload: {
                    thumbnailUrl: imageInfo.thumbnail_url,
                    id: imageInfo.id
                }
            });
        }
    };

    const handleLearnMoreClick = () => {
        Utils.openInNewTab(process.env.REACT_APP_ADOBE_STOCK_URL ? process.env.REACT_APP_ADOBE_STOCK_URL : "https://stock.adobe.com");
    }

    const handleLicenseForFreeClick = async () => {
        setIsRegionDisabled(true);
        setDialogOpen(false);
        await props.controller.notify({ type: ELStockWorkflowActions.licenseForFree, payload: selectedImageId });
        ToastUtils.success(intlHandler.formatMessage("adobe-stock-photo-licensed"));
    };

    const handleEnterClick = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && searchQuery) {
            handleSearchSubmit(searchQuery);
        }
    }

    const handleDiscardClick = async () => {
        setDialogOpen(false);
        setViewMode("default");
        setSearchQuery("");
        setIsRegionDisabled(true);
        setSearchResults(featuredBackgrounds);
        transparentCardClicked();
        store.dispatch(AdobeStockAction.updateAdobeStockDialogVisibility("false"));
        props.controller.notify({ type: ELStockWorkflowActions.discardLicense });
    }

    const handleDialogDismiss = () => {
        setDialogOpen(false);
        store.dispatch(AdobeStockAction.updateAdobeStockDialogVisibility("false"));
    }

    const transparentCardClicked = (): void => {
        const payload = { id: TRANSPARENT_BACKGROUND_ID, name: TRANSPARENT_BACKGROUND_NAME };
        props.controller.notify({ type: ELRecommendationWorkflowControllerActions.overlayClicked, payload });
    };

    const shouldShowAdobeStockFlow = (): boolean => {
        return FeaturesManager.getInstance().IsFeatureActive(FeatureName.eStockInAutoBackground);
    }

    const renderCustomSection = () => (
        <div ref={gridRef} className="replace-background-content-panel__custom-section">
            <Text UNSAFE_className="replace-background-content-panel__custom-section-header">{intlHandler.formatMessage("custom")}</Text>
            <Grid columns="repeat(2, 1fr)" gap="1rem" justifyContent="center">
                <ELTransparentCard
                    width={55}
                    height={55}
                    textWidth={10}
                    textHeight={10}
                    onPress={transparentCardClicked}
                    selected={TRANSPARENT_BACKGROUND_ID === recommendationWorkflowReducer.selectedOverlayId}
                />
            </Grid>
        </div>
    );

    const renderAdobeStockSection = () => (
        <div className="replace-background-content-panel__adobe-stock-section">
            <div className="replace-background-content-panel__adobe-stock-section-header">
                <ELCustomIcon variant="independent" width={"10rem"} height={"2rem"} iconkey={IconType.adobeStock} />
            </div>
            <div className="replace-background-content-panel__adobe-stock-search">
                <div className="replace-background-content-panel__stock-search-bar-container">
                    <input
                        aria-label={intlHandler.formatMessage("search-backgrounds")}
                        placeholder={intlHandler.formatMessage("search-backgrounds")}
                        value={searchQuery}
                        onChange={(e) => handleSearchChange(e.target.value)}
                        onFocus={() => setShowSuggestions(true)}
                        onBlur={() => setShowSuggestions(false)}
                        onKeyDown={(e) => handleEnterClick(e)}
                    />
                    {searchQuery && (
                        <button
                            className="replace-background-content-panel__stock-search-clear-button"
                            onClick={() => {
                                setSearchQuery("");
                                fetchFeaturedBackgrounds();
                            }}
                        >
                            ✕
                        </button>
                    )}
                </div>
                {showSuggestions && renderSearchSuggestions()}
            </div>
            {renderFeaturedFreeBackgrounds()}
        </div>
    );

    const renderSearchSuggestions = () => (
        <div className="replace-background-content-panel__search-suggestions" onMouseDown={(e) => e.preventDefault()}>
            {searchQuery && (
                <div
                    className="replace-background-content-panel__search-suggestion-item"
                    onClick={() => handleSearchSubmit(searchQuery)}
                >
                    Search for &quot;{searchQuery}&quot;
                </div>
            )}
            {recentSearches.length > 0 && (
                <>
                    {recentSearches.map((query) => (
                        <div
                            key={query}
                            className="replace-background-content-panel__search-suggestion-item"
                            onClick={() => handleSearchSubmit(query)}
                        >
                            {query}
                        </div>
                    ))}
                </>
            )}
        </div>
    );

    const renderFeaturedFreeBackgrounds = () => (
        <div className="replace-background-content-panel__featured-free-backgrounds">
            <div className="replace-background-content-panel__featured-free-header">
                <Text UNSAFE_className="replace-background-content-panel__featured-free-text">{intlHandler.formatMessage("featured-free")}</Text>
                <text className="replace-background-content-panel__view-all" onClick={() => handleViewAllClick()}>{intlHandler.formatMessage("view-all")}</text>
            </div>
            <div className="replace-background-content-panel__featured-free-grid">
                {isLoadingBackgrounds ? (
                    <div className="replace-background-content-panel__loader">
                        <ProgressCircle
                            aria-label={intlHandler.formatMessage("fetching-backgrounds")}
                            isIndeterminate
                            size="L"
                        />
                    </div>
                ) : (
                    (searchResults.length > 0 ? searchResults : featuredBackgrounds).slice(0, 20).map((background) => (
                        <div key={background.id} className="replace-background-content-panel__featured-free-background-item">
                            <img
                                src={background.thumbnail_url}
                                alt={background.title}
                                onClick={() => handleImageClick(background)}
                                crossOrigin="anonymous"
                            />
                        </div>
                    ))
                )}
            </div>
        </div>
    );

    const renderActionRegion = () => (
        <div className={`replace-background-content-panel__action-region ${isRegionDisabled ? "disabled" : ""}`}>
            <hr className="replace-background-content-panel__bottom-section-divider" />
            <Text UNSAFE_className="replace-background-content-panel__action-region-text">{intlHandler.formatMessage("license-region-text")}</Text>
            <div className="replace-background-content-panel__action-region-buttons">
                <ELButton variant="primary" onClick={() => handleDiscardClick()} aria-disabled={isRegionDisabled}>
                    {intlHandler.formatMessage("discard")}
                </ELButton>
                <ELButton variant="cta" onClick={() => handleLicenseForFreeClick()} aria-disabled={isRegionDisabled}>
                    {intlHandler.formatMessage("license-free")}
                </ELButton>
            </div>
        </div>
    );

    const renderSearchResultsHeader = () => (
        <>
            <div className="replace-background-content-panel__header">
                <div className="replace-background-content-panel__back-button">
                    <ELCustomIcon variant="independent" iconkey={IconType.creationsHeaderBack} color={Theme.dark.gray_controls_color_N}
                        hoverColor={Theme.dark.gray_controls_color_N} width={"2rem"} height={"2rem"}
                        onPress={handleBackToDefault} />
                    {
                        shouldShowDialog() &&
                        <ELStockLicenseAndDownloadDialog
                            heading={intlHandler.formatMessage("license-download")}
                            headingDescription={intlHandler.formatMessage("adobe-stock")}
                            dialogDescription={intlHandler.formatMessage("license-region-dialog-text")}
                            dontSaveButtonText={intlHandler.formatMessage("discard")}
                            saveButtonText={intlHandler.formatMessage("license-free")}
                            onDismiss={handleDialogDismiss}
                            onSave={handleLicenseForFreeClick}
                            onDontSave={handleDiscardClick}
                        />
                    }
                </div>
                <div className="replace-background-content-panel__header-content">
                    <ELCustomIcon variant="independent" iconkey={IconType.adobeStock} width={"10rem"} height={"3rem"} />
                </div>
            </div>
            <div className="replace-background-content-panel__header-divider"></div>
        </>
    );

    const renderSearchBar = () => (
        <div className="replace-background-content-panel__stock-search-bar-container">
            <input
                aria-label={intlHandler.formatMessage("search-backgrounds")}
                placeholder={intlHandler.formatMessage("search-backgrounds")}
                value={searchQuery}
                onChange={(e) => handleSearchChange(e.target.value)}
                onFocus={() => setShowSuggestions(true)}
                onBlur={() => setShowSuggestions(false)}
                onKeyDown={(e) => handleEnterClick(e)}
            />
            {searchQuery && (
                <button
                    className="replace-background-content-panel__stock-search-clear-button"
                    onClick={() => {
                        setSearchQuery("");
                        fetchFeaturedBackgrounds();
                    }}
                >
                    ✕
                </button>
            )}
            {showSuggestions && renderSearchSuggestions()}
        </div>
    );

    const renderSearchResultsText = () => (
        <Text UNSAFE_className="replace-background-content-panel__search-results-text">
            Results for &quot;{searchQuery}&quot;
        </Text>
    );

    const renderSearchResultsGrid = () => (
        <div onScroll={handleScroll} className="replace-background-content-panel__search-results-grid">
            {isLoadingBackgrounds ? (
                <div className="replace-background-content-panel__loader">
                    <ProgressCircle
                        aria-label={intlHandler.formatMessage("fetching-backgrounds")}
                        isIndeterminate
                        size="L"
                    />
                </div>
            ) : (
                searchResults.map((result) => (
                    <div key={result.id} className="replace-background-content-panel__search-results-item">
                        <img
                            src={result.thumbnail_url}
                            alt={result.title}
                            onClick={() => handleImageClick(result)}
                            crossOrigin="anonymous"
                        />
                    </div>
                ))
            )}
        </div>
    );

    const renderPoweredBySection = () => (
        <div className="replace-background-content-panel__powered-by">
            <Text>{intlHandler.formatMessage("adobe-stock-powered-photos")}</Text>
            <text className="replace-background-content-panel__learn-more" onClick={handleLearnMoreClick}>{intlHandler.formatMessage("learn-more")}</text>
        </div>
    );

    const renderDefaultView = () => (
        <>
            <ELPanelHeader title={intlHandler.formatMessage("background")} alignTitle="center" />
            <ELScrollPanel
                forwardedRef={scrollParentRef}
                UNSAFE_className="replace-background-content-panel-scroll"
                scrollY={true}
                width="100%"
            >
                <div className="replace-background-content-panel-content-box">
                    {renderCustomSection()}
                    <hr className="replace-background-content-panel__section-divider" />
                    {renderAdobeStockSection()}
                    {renderPoweredBySection()}
                    {renderActionRegion()}
                </div>
            </ELScrollPanel>
            {
                shouldShowDialog() &&
                <ELStockLicenseAndDownloadDialog
                    heading={intlHandler.formatMessage("license-download")}
                    headingDescription={intlHandler.formatMessage("adobe-stock")}
                    dialogDescription={intlHandler.formatMessage("license-region-text")}
                    dontSaveButtonText={intlHandler.formatMessage("discard")}
                    saveButtonText={intlHandler.formatMessage("license-free")}
                    onDismiss={handleDialogDismiss}
                    onSave={handleLicenseForFreeClick}
                    onDontSave={handleDiscardClick}
                />
            }
        </>
    );

    const renderSearchResultsView = () => (
        <>
            {renderSearchResultsHeader()}
            <ELScrollPanel
                forwardedRef={scrollParentRef}
                UNSAFE_className="replace-background-content-panel-scroll"
                scrollY={true}
                width="100%"
            >
                <div className="replace-background-content-panel-content-box">
                    {renderSearchBar()}
                    {searchQuery.length > 0 && renderSearchResultsText()}
                    {renderSearchResultsGrid()}
                    {renderPoweredBySection()}
                    {renderActionRegion()}
                </div>
            </ELScrollPanel>
        </>
    );

    return shouldShowAdobeStockFlow() ? (
        <div className="replace-background-content-panel-box">
            {viewMode === "default" && renderDefaultView()}
            {viewMode === "searchResults" && renderSearchResultsView()}
        </div>
    ) : (
        <div className="replace-background-content-panel-box">
            <ELPanelHeader title={intlHandler.formatMessage("background")} alignTitle="center" />
            <ELScrollPanel
                forwardedRef={scrollParentRef}
                UNSAFE_className="replace-background-content-panel-scroll"
                scrollY={true}
                width="100%"
            >
                <div className="replace-background-content-panel-content-box">
                    <div ref={gridRef}>
                        <Grid
                            marginTop={"1rem"}
                            columns={repeat("auto-fit", viewLayoutConfig.TILE_W)}
                            justifyContent="center"
                            autoRows={viewLayoutConfig.TILE_W}
                            gap={viewLayoutConfig.DIST_BETWEEN_TILE}
                        >
                            <ELTransparentCard
                                width={viewLayoutConfig.TILE_W}
                                height={viewLayoutConfig.TILE_H}
                                textWidth={18}
                                textHeight={22}
                                onPress={() => transparentCardClicked()}
                                selected={TRANSPARENT_BACKGROUND_ID === recommendationWorkflowReducer.selectedOverlayId}
                            />
                        </Grid>
                    </div>
                </div>
            </ELScrollPanel>
        </div>
    );
};

export { ELReplaceBackgroundContentPanelView };