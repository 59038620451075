/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useReducer, ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Adobe internal
import { Flex } from "@adobe/react-spectrum";
import { Text } from '@react-spectrum/text';

//Application Specific
import ELVideo from "../../../../view/components/atoms/el-video/ELVideo";
import ELProgressBanner from "../../../../view/components/organism/el-progress-banner/ELProgressBanner";
import ELGenericProgressContentLoop from "../../../../view/components/organism/el-progress-content-loop/ELProgressContentLoop";
import ELButton from "../../../../view/components/atoms/el-button/ELButtonView";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { CreationsMode, CreationsStatus } from "../../../../common/interfaces/creations/CreationTypes";
import { CreationStatusPayload, CreationsVideoRendition } from "../../../../stores/actions/CreationsAction";
import { ViewAction } from "../../../../view/IBaseController";
import Utils from "../../../../utils/Utils";
import { RootState } from "../../../../stores/store";
import { SlideshowControllerActions, SlideshowViewActions } from "../../../../common/interfaces/creations/SlideshowTypes";
import { ELIcon } from "../../../../view/components/atoms/el-icon/ELIconView";
import { IconType } from "../../../../assets/IconConstants";
import IViewController from "../../../../view/IViewController";
import Constants from "../../../../utils/Constants/Constants";

import "./Slideshow.scss";

interface ISlideshowProps {
    controller: IViewController,
    mode: CreationsMode,
    status?: CreationsStatus
}

interface ELSlideshowState {
    id: string,
    status: CreationsStatus,
    slideshowThumbData: CreationsVideoRendition
}

const initialState: ELSlideshowState = {
    id: Utils.getNilUUID(),
    status: CreationsStatus.requested,
    slideshowThumbData: { id: Utils.getNilUUID(), renditionData: {} }
}

const TAKING_LONG_TIMEOUT = 1000 * 12;

const SlideshowView = (props: ISlideshowProps): React.ReactElement => {
    const reducer = (state: ELSlideshowState, action: ViewAction): ELSlideshowState => {
        switch (action.type) {
            case SlideshowViewActions.slideshowStatus: {
                const data = action.payload as CreationStatusPayload;
                return {
                    ...state,
                    id: data.id,
                    status: data.status as CreationsStatus
                };
            }
            case SlideshowViewActions.slideshowThumbData:
                return { ...state, slideshowThumbData: (action.payload as CreationsVideoRendition) };
            default:
                return state;
        }
    }

    const [state, viewDispatch] = useReducer(reducer, initialState);
    // @ts-ignore //TODO: Vibhuti
    const [hideTakingLongText, setHideTakingLongText] = useState(true);
    const slideshowStoreData = useSelector((state: RootState) => state.creationsReducer);
    const intlHandler = IntlHandler.getInstance();
    const progressPercentage = slideshowStoreData.activeSlideshowId ? slideshowStoreData.data[slideshowStoreData.activeSlideshowId].progress : 0;

    useEffect(() => {
        props.controller.initialize(viewDispatch);
        initializeSlideshowStatus();
        startTakingLongTimer();
        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    useEffect(() => {
        if (slideshowStoreData.activeSlideshowId && slideshowStoreData.data[slideshowStoreData.activeSlideshowId]) {
            viewDispatch({
                type: SlideshowViewActions.slideshowStatus,
                payload: {
                    id: slideshowStoreData.activeSlideshowId,
                    status: slideshowStoreData.data[slideshowStoreData.activeSlideshowId].status as CreationsStatus
                } as CreationStatusPayload
            });
        }
    }, [slideshowStoreData]);

    useEffect(() => {
        startTakingLongTimer();
    }, [state.status]);

    useEffect(() => {
        if (props.status) {
            initializeSlideshowStatus();
        }
    }, [props.status])

    const isSlideshowInProgressOrOutdated = (): boolean => {
        return slideshowInProgress() ||
            slideshowEditInProgress() ||
            isSlideshowOutdated();
    }

    const startTakingLongTimer = (): void => {
        if (isSlideshowInProgressOrOutdated()) {
            setHideTakingLongText(true);
            setTimeout(() => {
                setHideTakingLongText(false);
            }, TAKING_LONG_TIMEOUT);
        } else {
            setHideTakingLongText(true);
        }
    }

    const initializeSlideshowStatus = (): void => {
        const slideshowStatusPayload = {
            id: state.id,
            status: (props.mode === CreationsMode.create ? CreationsStatus.requested :
                (props.status ? props.status : CreationsStatus.success))
        }
        viewDispatch({ type: SlideshowViewActions.slideshowStatus, payload: slideshowStatusPayload });
    }

    const slideshowSucceeded = (): boolean => {
        return state.status === CreationsStatus.success;
    }

    const isSlideshowOutdated = (): boolean => {
        return state.status === CreationsStatus.oldVersion;
    }

    const slideshowInProgress = (): boolean => {
        return state.status === CreationsStatus.requested;
    }

    const slideshowEditInProgress = (): boolean => {
        return state.status === CreationsStatus.editing;
    }

    const hasSlideshowFailed = (): boolean => {
        return isSlideshowError() || isSlideshowApiError();
    }

    const canRetrySlideshow = (): boolean => {
        return state.status === CreationsStatus.retry;
    }

    const isSlideshowError = (): boolean => {
        return state.status === CreationsStatus.error || state.id === Utils.getNilUUID();
    }

    const isSlideshowApiError = (): boolean => {
        return state.status === CreationsStatus.apiError;
    }

    const onNotifyMeViaEmail = (): void => {
        props.controller.notify({ type: SlideshowControllerActions.notifyOnEmail });
    }

    const onBackSlidshowView = (): void => {
        props.controller.notify({ type: SlideshowControllerActions.backSlideshowView });
    }

    const shouldHideNotifyViaEmail = (): boolean => {
        return (state.id === Utils.getNilUUID() || (!slideshowInProgress() && !slideshowEditInProgress()));
    }

    const isSlideshowRenditionAvailable = (): boolean => {
        if (slideshowSucceeded() && state.slideshowThumbData.id !== Utils.getNilUUID() && state.slideshowThumbData.renditionData.videoData)
            return true;
        return false;
    }

    const hasInvalidSlideshowRendition = (): boolean => {
        return slideshowSucceeded() && state.slideshowThumbData.id !== Utils.getNilUUID() &&
            state.slideshowThumbData.renditionData.videoData === undefined;
    }

    const getSlideshowVideoRendition = (): ReactNode => {
        if (state.slideshowThumbData.id !== Utils.getNilUUID() &&
            state.slideshowThumbData.renditionData.videoData) {
            return (<ELVideo UNSAFE_className="slideshow-video" variant="native" type="video/mp4"
                posterURL={state.slideshowThumbData.renditionData.imgData}
                src={state.slideshowThumbData.renditionData.videoData} />);
        }

        return (<></>);
    }

    const showSlideshowProgress = (): ReactNode => {
        if (slideshowInProgress()) {
            return getProgressBanner("100%");
        } else if (isSlideshowOutdated()) {
            return getProgressBanner("100%");
        } else if (hasInvalidSlideshowRendition()) {
            return getFailedSlideshowBanner(intlHandler.formatMessage("invalid-slideshow-rendition"));
        } else if (slideshowSucceeded() && state.slideshowThumbData.id === Utils.getNilUUID()) {
            return getProgressBanner("100%");
        } else if (hasSlideshowFailed()) {
            return getFailedSlideshowBanner(intlHandler.formatMessage("failed-slideshow"));
        } else if (canRetrySlideshow()) {
            return getFailedSlideshowBanner(intlHandler.formatMessage("can-retry-slideshow"));
        } else if (slideshowEditInProgress()) {
            return getProgressBanner("100%");
        }
        return (<></>);
    }

    const showSlideshowVideo = (): ReactNode => {
        if (isSlideshowRenditionAvailable()) {
            return getSlideshowVideoRendition();
        }
        return <></>;
    }

    const showSlideshowProgressNumber = (): ReactNode => {
        if (isSlideshowInProgressOrOutdated() && progressPercentage) {
            return (<Text marginTop="0.8rem" UNSAFE_className="failed-slideshow__secondary_text">
                {progressPercentage}{Constants.PERCENT_SYMBOL}
            </Text>);
        }
        return <></>;
    }

    const getProgressBanner = (bannerHeight: string, progressContent = ""): ReactNode => {
        return (
            <div className="slideshow-progress-container">
                <ELProgressBanner key="slideshow_progress_banner" height={bannerHeight} width="100%">
                    <ELGenericProgressContentLoop contentHeader={progressContent} progressPercentNode={showSlideshowProgressNumber()}>
                        <Flex direction="row" marginTop="size-400" gap="1rem">
                            <ELButton dataTestId="slideshow-notify" isHidden={shouldHideNotifyViaEmail()} size="L" variant="accent" onClick={onNotifyMeViaEmail}>
                                <Text UNSAFE_className="failed-slideshow__cta-text">{intlHandler.formatMessage("notify-me-via-email")} </Text>
                            </ELButton>
                        </Flex>
                    </ELGenericProgressContentLoop>
                </ELProgressBanner>
            </div>
        );
    }

    const getFailedSlideshowBanner = (primaryText: string): ReactNode => {
        return (<Flex UNSAFE_className="failed-slideshow__box" direction="column" alignItems="center"
            justifyContent="center" height="100%">
            <ELIcon iconkey={IconType.negativeSentimentIcon} width="3.375rem" height="3.375rem" />
            <Flex direction="column" marginTop="size-300" alignItems="center" justifyContent="center">
                <Text data-testid="failed-slideshow-text" UNSAFE_className="failed-slideshow__primary_text">
                    {primaryText}
                </Text>
            </Flex>
            <Flex direction="row" marginTop="size-1200" gap="1rem">
                <ELButton dataTestId="slideshow-back" size="L" variant="accent" onClick={onBackSlidshowView}>
                    <Text UNSAFE_className="failed-slideshow__cta-text">{intlHandler.formatMessage("back")} </Text>
                </ELButton>
            </Flex>
        </Flex>);
    }

    return (
        <div id="slideshow-container">
            <div id="slideshow-header-container" />
            <Flex direction="column">
                <div className="slideshow-main-content-box">
                    <div id="slideshow-loading-circle-box">
                        {showSlideshowProgress()}
                    </div>
                    <div id="slideshow-video-container">
                        {showSlideshowVideo()}
                    </div>
                    <div id="slideshow-right-panel-container" />
                    <div className="slideshow-feedback-icon-container" id="feedback-popover-container" />
                </div>
            </Flex>
        </div>
    )
};

export default SlideshowView;
