/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

type Key = string;
type Value = string | number;

const StockServiceConstants: Record<Key, Value> = {
    "STOCK_SEARCH_SERVICE_ENDPOINT": "/Media/1/Search/Files",
    "STOCK_LICENSE_INFO_ENDPOINT": "/Libraries/1/Content/Info",
    "STOCK_ACQUIRE_LICENSE_ENDPOINT": "/Libraries/1/Content/License",
    "STOCK_DOWNLOAD_IMAGE_ENDPOINT": "/Libraries/Download",
    "STOCK_STANDARD_LICENSE": "Standard",
    "STOCK_IMAGE_PURCHASED": "purchased",
    "STOCK_IMAGE_NOT_PURCHASED": "not_purchased",
    "STOCK_IMAGE_SIZE_FOR_REPLACE_BACKGROUND": 1600,
    "STOCK_IMAGE_SIZE_DEFAULT": 400
}

export default StockServiceConstants;