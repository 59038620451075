/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { RepoResponseResult } from "@dcx/assets";
import { AdobeResponseType, RenditionType } from "@dcx/common-types";
import { error, success } from "@react/react-spectrum/Toast";
import { Asset, Constants as ELCommonConstants, Request as ELServiceRequest, ELRenditionType } from "@elements/elementswebcommon";

//Application Specific
import {
    CreationsData,
    CreationsErrorMsg,
    CreationsErrorCode,
    CreationsStatus,
    CreationsJobProjectSubType,
    CURRENT_CREATION_VERSION,
    CreationsOutputConfigResultPath,
    CreationName,
    CreationsURLParams
} from "../../../common/interfaces/creations/CreationTypes";
import ElementsCreationsService from "../../../services/ElementsServices/ElementsCreationsService";
import Logger, { LogLevel } from "../../../utils/Logger";
import { RenditionSource, Utils as ELWebCommonUtils } from "@elements/elementswebcommon";
import { StorageService } from "../../../services/StorageServiceWrapper";
import { ELAdobeAsset, ELAssetPathOrId, ELAssetWithRepoAndPathOrId } from "../../../common/interfaces/storage/AssetTypes";
import { CreationStatusData } from "../../../common/interfaces/creations/CreationInAppNotifierTypes";
import IWorkspace from "../../IWorkspace";
import { IntlHandler } from "../../../modules/intlHandler/IntlHandler";
import SlideshowUtils from "../workflows/slideshow/utils/SlideshowUtils";
import Constants, { UrlOpenTargets } from "../../../utils/Constants/Constants";
import { Routes } from "../../../app/AppRoute";
import { SIVUtils } from "./../../../utils/SIVUtils";
import { CreationsAssetResolver } from "./CreationsPathResolver/CreationsAssetPathResolver";
import { ToastUtils } from "../../../utils/ToastUtils";
import { MediaGridConfig } from "../../../stores/actions/mediaGridConfigActions";
import { AssetStorageUtils } from "../../../utils/AssetStorageUtils";
import Utils from "../../../utils/Utils";
import { RecentCreationSortOrderKey } from "../../../common/interfaces/creations/ELRecentCreationsTypes";
import { WorkflowsName } from "../../IWorkflow";
import CreationPreviewUtils from "../workflows/preview/utils/CreationPreviewUtils";
import CollageUtils from "../workflows/collage/utils/CollageUtils";
import MovingOverlayUtils from "../workflows/moving-overlay/utils/MovingOverlayUtils";
import PatternOverlayUtils from "../workflows/pattern-overlay/utils/PatternOverlayUtils";
import ReplaceBackgroundUtils from "../workflows/replace-background/utils/ReplaceBackgroundUtils";
import PeekThroughUtils from "../workflows/peekThrough/utils/PeekThroughUtils";
import { LocaleUtils } from "../../../utils/LocaleUtils";
import ELAssetExportHandler from "../../../modules/assetExporter/AssetExporter";
import { ELExportOption, ELExportInfo } from "../../../common/interfaces/export/ELExportTypes";
import PhotoTextUtils from "../workflows/photoText/utils/PhotoTextUtils";
import store from "../../../stores/store";
import { ELReplaceBackgroundContentSource } from "../../../common/interfaces/creations/ELReplaceBackgroundTypes";

const operationSubTypeToDownloadNameMap: Map<CreationsJobProjectSubType, CreationName> = new Map();

operationSubTypeToDownloadNameMap.set(CreationsJobProjectSubType.slideshow, CreationName.slideshow);
operationSubTypeToDownloadNameMap.set(CreationsJobProjectSubType.photoCollage, CreationName.photoCollage);
operationSubTypeToDownloadNameMap.set(CreationsJobProjectSubType.patternOverlay, CreationName.patternOverlay);
operationSubTypeToDownloadNameMap.set(CreationsJobProjectSubType.movingOverlay, CreationName.movingOverlay);
operationSubTypeToDownloadNameMap.set(CreationsJobProjectSubType.replaceBackground, CreationName.replaceBackground);
operationSubTypeToDownloadNameMap.set(CreationsJobProjectSubType.magicalBackdrop, CreationName.replaceBackground);
operationSubTypeToDownloadNameMap.set(CreationsJobProjectSubType.photoText, CreationName.photoText);

const FEEDBACK_LAST_LAUNCH_KEY = "FEEDBACK_LAST_LAUNCH_TIME";
const FEEDBACK_LAST_LAUNCH_DAYS_DIFFERENCE = 7;

export default class CreationUtils {
    private static _getDownloadName(creationsData: CreationsData): string {
        const operationSubType = creationsData.operationSubType as CreationsJobProjectSubType;
        switch (operationSubType) {
            case CreationsJobProjectSubType.slideshow:
            case CreationsJobProjectSubType.photoCollage:
            case CreationsJobProjectSubType.movingOverlay:
            case CreationsJobProjectSubType.replaceBackground:
            case CreationsJobProjectSubType.magicalBackdrop:
            case CreationsJobProjectSubType.patternOverlay:
            case CreationsJobProjectSubType.peekThrough:
            case CreationsJobProjectSubType.photoText: {
                return creationsData.title as string;
            }
            default: {
                return operationSubTypeToDownloadNameMap.get(operationSubType) as string;
            }
        }
    }

    private static _isCreationAppActive(creationsApp: IWorkspace): boolean {
        return creationsApp.getActiveWorkflow !== undefined;
    }

    private static _shouldShowCreationCreatedNotification(creationsApp: IWorkspace): boolean {
        return !CreationUtils._isCreationAppActive(creationsApp);
    }

    private static _getCreationsOutputExtension(creationsData: CreationsData): string {
        const operationSubType = creationsData.operationSubType as CreationsJobProjectSubType;
        switch (operationSubType) {
            case CreationsJobProjectSubType.slideshow:
                return ".mp4";
            case CreationsJobProjectSubType.movingOverlay:
                return ".mp4";
            default:
                return ".jpg";
        }
    }

    static compareCreationDates(date1: string, date2: string, order: RecentCreationSortOrderKey): number {
        if (order === RecentCreationSortOrderKey.ascending) {
            return new Date(date1).getTime() - new Date(date2).getTime();
        } else {
            return new Date(date2).getTime() - new Date(date1).getTime();
        }
    }

    static compareCreationTitles(title1: string, title2: string, order: RecentCreationSortOrderKey): number {
        if (order === RecentCreationSortOrderKey.ascending) {
            return title1.localeCompare(title2);
        } else {
            return title2.localeCompare(title1);
        }
    }

    static async deleteRecommendations(requestId: string, action: "all" | "active" = "all"): Promise<boolean> {
        const deleteStatus = await ElementsCreationsService.getInstance().deleteRecommendations(requestId, action);
        return deleteStatus;
    }

    static async deleteCreation(project_id: string, action: "all" | "active" = "all"): Promise<boolean> {
        const deleteStatus = await ElementsCreationsService.getInstance().deleteCreation(project_id, action);
        return deleteStatus;
    }

    static async updateCreationStatus(project_id: string, status: CreationsStatus): Promise<boolean> {
        const updateStatus = await ElementsCreationsService.getInstance().updateCreationStatus(project_id, status);
        return updateStatus;
    }

    static async getAppMetadata(creationsData: CreationsData): Promise<undefined | string | Record<string, unknown>> {
        try {
            const asset = await CreationUtils.getCreationOutputAsset(creationsData);
            return await StorageService.getInstance().getAppMetadata(asset);
        } catch (error) {
            Logger.log(LogLevel.WARN, "CreationUtils:getAppMetadata: ", error, CreationsErrorMsg.failedAppMetada);
            return Promise.reject(undefined);
        }
    }

    static async getCreationOutputAssetPathOrId(creationsData: CreationsData): Promise<ELAssetPathOrId> {
        if (!creationsData.outputs) {
            Logger.log(LogLevel.WARN, "CreationUtils:getCreationOutputAssetPath: ", CreationsErrorMsg.failedOutput);
            throw new Error(CreationsErrorMsg.failedOutput);
        }
        const outputAssetResolverObj = new CreationsAssetResolver();
        const pathOrId = await outputAssetResolverObj.getFullAssetData(creationsData);
        return pathOrId;
    }

    static async getCreationPreviewAssetPathOrId(creationsData: CreationsData): Promise<ELAssetPathOrId> {
        if (!creationsData.outputs || !creationsData.outputs.preview) {
            Logger.log(LogLevel.WARN, "CreationUtils:getCreationPreviewAssetPathOrId: ", CreationsErrorMsg.invalidOutputPreview);
            throw new Error(CreationsErrorMsg.invalidOutputPreview);
        }

        const outputAssetResolverObj = new CreationsAssetResolver();
        const pathOrId = await outputAssetResolverObj.getPreviewAssetData(creationsData);
        return pathOrId;
    }

    static async getCreationPreviewAsset(creationsData: CreationsData): Promise<ELAdobeAsset> {
        if (!creationsData.outputs || !creationsData.outputs.preview) {
            Logger.log(LogLevel.WARN, "CreationUtils:getCreationPreviewAsset: ", CreationsErrorMsg.invalidOutputPreview);
            return Promise.reject(CreationsErrorCode.invalidOutputPreview);
        }

        const pathOrId = await CreationUtils.getCreationPreviewAssetPathOrId(creationsData);

        const asset = {
            repositoryId: "",
            ...pathOrId
        } as ELAssetWithRepoAndPathOrId;

        try {
            const outputAsset = await StorageService.getInstance().resolveAsset(asset);
            return Promise.resolve(outputAsset);
        } catch (e) {
            Logger.log(LogLevel.WARN, "CreationUtils:getCreationPreviewAsset: ", CreationsErrorMsg.invalidOutput);
            return Promise.reject(CreationsErrorCode.invalidOutput);
        }
    }

    static async getCreationOutputAssetFromPathOrId(pathOrId: ELAssetPathOrId): Promise<ELAdobeAsset> {
        const asset = {
            repositoryId: "",
            ...pathOrId
        } as ELAssetWithRepoAndPathOrId;

        try {
            const outputAsset = await StorageService.getInstance().resolveAsset(asset);
            return Promise.resolve(outputAsset);
        } catch (e) {
            Logger.log(LogLevel.WARN, "CreationUtils:getCreationOutputAssetFromPathOrId: ", CreationsErrorMsg.invalidOutput);
            return Promise.reject(CreationsErrorCode.invalidOutput);
        }
    }

    static async getCreationOutputAsset(creationsData: CreationsData): Promise<ELAdobeAsset> {
        if (!creationsData.outputs) {
            Logger.log(LogLevel.WARN, "CreationUtils:getCreationOutputAsset: ", CreationsErrorMsg.failedOutput);
            return Promise.reject(CreationsErrorCode.failedOutput);
        }

        const pathOrId = await CreationUtils.getCreationOutputAssetPathOrId(creationsData);
        try {
            const outputAsset = await CreationUtils.getCreationOutputAssetFromPathOrId(pathOrId);
            return Promise.resolve(outputAsset);
        } catch (error) {
            Logger.log(LogLevel.WARN, "CreationUtils:getCreationOutputAsset: ", error, CreationsErrorMsg.invalidOutput);
            return Promise.reject(CreationsErrorCode.invalidOutput);
        }
    }

    static async getCreationRenditionAsset(creationsData: CreationsData): Promise<ELAdobeAsset> {
        if (creationsData.outputs && creationsData.outputs.preview && (creationsData.version === 2 || creationsData.version === 3)) {
            return CreationUtils.getCreationPreviewAsset(creationsData);
        }

        return CreationUtils.getCreationOutputAsset(creationsData);
    }

    static async getProjectData(projectId: string): Promise<CreationsData> {
        try {
            const projectData = await ElementsCreationsService.getInstance().getProjectData(projectId);
            return Promise.resolve(projectData);
        } catch (error) {
            Logger.log(LogLevel.WARN, "CreationUtils:getProjectData: ", CreationsErrorMsg.failedProjectData + error);
            return Promise.reject(CreationsErrorMsg.failedProjectData);
        }
    }

    static async getCreationRendition(outputAsset: ELAdobeAsset, renditionType: RenditionType = ELRenditionType.VIDEO_METADATA, responseType: AdobeResponseType = "json", size?: number): Promise<RepoResponseResult> {
        const renditionOpts = {
            size: size ?? 0,
            type: renditionType as RenditionType
        };

        try {
            const response = await StorageService.getInstance().getRendition(outputAsset, renditionOpts, responseType, RenditionSource.native, SIVUtils.hasSIVUrl(window.location.href));
            return Promise.resolve(response);
        } catch (e) {
            Logger.log(LogLevel.WARN, "CreationUtils:getCreationRendition: ", e);
            return Promise.reject(CreationsErrorCode.invalidRendtion);
        }
    }

    static async getSerializedRequestObject(outputJson: unknown): Promise<ELServiceRequest> {
        const requestObj = new ELServiceRequest();
        try {
            await requestObj.serializeInWithContext(outputJson);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "CreationsUtils: getSerializedRequestObject")
            return Promise.reject();
        }
        return requestObj;
    }

    static async downloadCreation(creationsData: CreationsData): Promise<void> {
        let outputAsset;
        try {
            outputAsset = await CreationUtils.getCreationOutputAsset(creationsData);
        } catch (e) {
            Logger.log(LogLevel.WARN, "CreationUtils:downloadCreation: ", e, CreationsErrorMsg.failedDownloadingCreation, " ",
                CreationsErrorMsg.invalidOutput);
            return Promise.reject(CreationsErrorCode.invalidOutput);
        }

        const asset = { ...outputAsset, name: creationsData.title + CreationUtils._getCreationsOutputExtension(creationsData) };
        const downloadName = CreationUtils._getDownloadName(creationsData) + CreationUtils._getCreationsOutputExtension(creationsData);

        const downloadHandler = new ELAssetExportHandler();
        const exportInfo: ELExportInfo = {
            asset: asset,
            defaultName: downloadName
        }

        try {
            await downloadHandler.downloadFile(exportInfo, ELExportOption.native);
            return Promise.resolve();
        }
        catch (error) {
            Logger.log(LogLevel.WARN, "CreationUtils:downloadCreation: ", error, CreationsErrorMsg.failedDownloadingCreation, " ", outputAsset.assetId);
            return Promise.reject(CreationsErrorCode.failedDownloadingCreation);
        }
    }

    static isCreationSuccess(creationStatusData: CreationStatusData): boolean {
        return creationStatusData.status === CreationsStatus.success;
    }

    static shouldHighlightCreationsTab(pathname: string, creationStatusData: CreationStatusData): boolean {
        return CreationUtils.isCreationSuccess(creationStatusData) && ((pathname as Routes) !== Routes.CREATIONS);
    }

    static showCreationCreatedMessage(creationsApp: IWorkspace, creationStatusData: CreationStatusData): void {
        if (CreationUtils._shouldShowCreationCreatedNotification(creationsApp)) {
            const isCreationSuccess = CreationUtils.isCreationSuccess(creationStatusData);
            if (isCreationSuccess) {
                success(IntlHandler.getInstance().formatMessage("slideshow-created-just-now"), {
                    closable: true,
                    closeOnAction: true,
                    timeout: 0,
                    actionLabel: IntlHandler.getInstance().formatMessage("view"),
                    onAction: () => {
                        window.open(SlideshowUtils.getSlideshowURL(creationStatusData.projectId), UrlOpenTargets.self);
                    }
                });
            } else {
                error(IntlHandler.getInstance().formatMessage("slideshow-error"), {
                    closable: true,
                    closeOnAction: true,
                    timeout: 0
                });
            }
        }
    }

    static getCreationNameFromOperationSubtype(data: CreationsData): CreationName {
        switch (data.operationSubType) {
            case CreationsJobProjectSubType.slideshow:
                return CreationName.slideshow;
            case CreationsJobProjectSubType.photoCollage:
                return CreationName.photoCollage;
            case CreationsJobProjectSubType.peekThrough:
                return CreationName.peekThrough;
            case CreationsJobProjectSubType.patternOverlay:
                return CreationName.patternOverlay;
            case CreationsJobProjectSubType.movingOverlay:
                return CreationName.movingOverlay;
            case CreationsJobProjectSubType.magicalBackdrop:
            case CreationsJobProjectSubType.replaceBackground:
                return CreationName.replaceBackground;
            case CreationsJobProjectSubType.photoText:
                return CreationName.photoText
            default:
                Logger.log(LogLevel.INFO, "CreationUtils:getCreationNameFromOperationSubtype:  unknown creation name");
                return CreationName.creation;
        }
    }

    static isCreationOutdated(projectData: CreationsData): boolean {
        if (projectData.version !== CURRENT_CREATION_VERSION || projectData.status === CreationsStatus.oldVersion) {
            return true;
        }

        return false;
    }

    static getWorkflowNameFromOperationSubtype(data: CreationsData): WorkflowsName {
        switch (data.operationSubType) {
            case CreationsJobProjectSubType.slideshow:
                return WorkflowsName.slideshow;
            case CreationsJobProjectSubType.photoCollage:
                return WorkflowsName.collage;
            case CreationsJobProjectSubType.patternOverlay:
                return WorkflowsName.patternOverlay;
            case CreationsJobProjectSubType.movingOverlay:
                return WorkflowsName.movingOverlay;
            case CreationsJobProjectSubType.replaceBackground:
                return WorkflowsName.replaceBackground;
            case CreationsJobProjectSubType.magicalBackdrop:
                return WorkflowsName.replaceBackground;
            case CreationsJobProjectSubType.peekThrough:
                return WorkflowsName.peekThrough;
            case CreationsJobProjectSubType.photoText:
                return WorkflowsName.photoText;
            default:
                return WorkflowsName.creationsHome;
        }
    }

    static getWorkflowNameFromUrl(url: string): WorkflowsName {
        let workflow: WorkflowsName = WorkflowsName.creationsHome;
        if (CreationPreviewUtils.hasCreationPreviewUrl(url) && CreationPreviewUtils.getCreationPreviewIdFromHref(window.location.href)) {
            workflow = WorkflowsName.creationsPreview;
        } else if (SlideshowUtils.hasSlideshowUrl() && SlideshowUtils.getSlideshowIdFromHref()) {
            workflow = WorkflowsName.slideshow;
        } else if (CollageUtils.hasCollageUrl() && CollageUtils.getCollageIdFromHref()) {
            workflow = WorkflowsName.collage;
        } else if (PatternOverlayUtils.hasPatternOverlayUrl() && PatternOverlayUtils.getPatternOverlayIdFromHref()) {
            workflow = WorkflowsName.patternOverlay;
        } else if (PeekThroughUtils.hasPeekThroughUrl() && PeekThroughUtils.getPeekThroughIdFromHref()) {
            workflow = WorkflowsName.peekThrough;
        } else if (MovingOverlayUtils.hasMovingOverlayUrl() && MovingOverlayUtils.getMovingOverlayIdFromHref()) {
            workflow = WorkflowsName.movingOverlay;
        } else if (ReplaceBackgroundUtils.hasReplaceBackgroundUrl() && ReplaceBackgroundUtils.getReplaceBackgroundIdFromHref()) {
            workflow = WorkflowsName.replaceBackground;
        } else if (PhotoTextUtils.hasPhotoTextUrl() && PhotoTextUtils.getPhotoTextIdFromHref()) {
            workflow = WorkflowsName.photoText;
        }
        return workflow;
    }

    static showErrorMessageForQuotaExceeded(creation: string): void {
        const message = IntlHandler.getInstance().formatMessage("creation-storage-quota-error", { creation: IntlHandler.getInstance().formatMessage(creation).toLowerCase() });
        ToastUtils.error(message, { timeout: Constants.TOAST_DEFAULT_TIME_OUT_LIMIT as number });
    }

    static filterImagesByCount(assets: ELAdobeAsset[], mediaGridConfig: MediaGridConfig): ELAdobeAsset[] {
        if ((mediaGridConfig.maxImageCount !== undefined) && assets.length > mediaGridConfig.maxImageCount) {
            assets.splice(mediaGridConfig.maxImageCount, assets.length - mediaGridConfig.maxImageCount);
        }

        return assets;
    }

    static async getCreationMasterAssetPathOrId(creationsData: CreationsData): Promise<ELAdobeAsset> {
        if (!creationsData.outputs) {
            Logger.log(LogLevel.WARN, "CreationUtils:getCreationMasterAssetPathOrId: ", CreationsErrorMsg.failedOutput);
            throw new Error(CreationsErrorMsg.failedOutput);
        }

        try {
            const assets = await this.getMasterAssetData(creationsData);
            if (assets && assets.length > 0) {
                const assetPath = { path: ELCommonConstants.SEPARATOR + assets[0].assetData?.assetURN };
                const outputAssetPathOrId = await CreationUtils.getCreationOutputAssetFromPathOrId(assetPath);
                return Promise.resolve(outputAssetPathOrId);
            } else {
                Logger.log(LogLevel.ERROR,
                    "CreationUtils: getCreationMasterAssetPathOrId, assets list is empty");
                return Promise.reject();
            }
        } catch (error) {
            Logger.log(LogLevel.WARN, "CreationUtils:getCreationMasterAssetPathOrId: ", error, CreationsErrorMsg.invalidOutput);
            return Promise.reject(CreationsErrorCode.invalidOutput);
        }
    }

    static async getMasterAssetData(creationsData: CreationsData): Promise<Asset[]> {
        if (!creationsData.outputs) {
            Logger.log(LogLevel.WARN, "CreationUtils:getMasterAssetData: ", CreationsErrorMsg.failedOutput);
            return Promise.reject(CreationsErrorMsg.failedOutput);
        }

        try {
            const outputAssetResolverObj = new CreationsAssetResolver();
            const assets = await outputAssetResolverObj.getMasterAssetData(creationsData);
            return assets;
        } catch (error) {
            Logger.log(LogLevel.WARN, "CreationUtils:getMasterAssetData: ", error, CreationsErrorMsg.invalidOutput);
            return Promise.reject(CreationsErrorCode.invalidOutput);
        }
    }

    static async getOutputResultConfigJsonData(rootPath: string, relativeFilePath: string): Promise<unknown> {
        let outputJsonData = await AssetStorageUtils.getAssetJsonData(rootPath, relativeFilePath)
        outputJsonData = ELWebCommonUtils.convertJsonKeys(outputJsonData);
        return outputJsonData;
    }

    static async getJsonFromProjectData(projectData: CreationsData): Promise<unknown> {
        try {
            const outputConfigPath: CreationsOutputConfigResultPath = {
                rootPath: projectData.outputs?.config.root as string,
                relativeFilePath: projectData.outputs?.config.assetURN as string
            }
            const outputJsonData = await this.getOutputResultConfigJsonData(outputConfigPath.rootPath,
                outputConfigPath.relativeFilePath);
            return outputJsonData;
        } catch {
            Logger.log(LogLevel.WARN, "CreationUtils:getJsonFromProjectData: ", "error in getting json");
            return Promise.reject();
        }
    }

    static getCreatedDescription(creationDate: string): string {
        const justNowMinuteElapsed = 10;
        const createdDate = new Date(creationDate);
        const minuteElapsed = Utils.getDateDifference(new Date(), createdDate, "min");
        const intlHandler = IntlHandler.getInstance();

        if (minuteElapsed <= justNowMinuteElapsed) {
            return intlHandler.formatMessage("created-just-now");
        }

        return createdDate.toLocaleDateString(LocaleUtils.DateFormat);
    }

    static isSaveAvailable(isOnline: boolean): boolean {
        if (!isOnline || AssetStorageUtils.hasQuotaExceeded()) {
            return false;
        }
        return true;
    }

    static async getOriginalAssetForSingleAssetBasedProject(projectId: string): Promise<ELAdobeAsset> {
        try {
            const projectData = await CreationUtils.getProjectData(projectId);
            if ((!projectData.assets) || (projectData.assets.length > 1)) {
                return Promise.reject();
            }

            const assetId = projectData.assets[0].assetURN;
            const projectAsset = await StorageService.getInstance().resolveAsset({ assetId }, "id");
            return Promise.resolve(projectAsset);
        } catch (error) {
            Logger.log(LogLevel.ERROR,
                "ELPatternOverlayFullAssetProvider: getOriginalAssetForSingleAssetBasedProject failed to resolve asset with error : " + error);
            return Promise.reject(error);
        }
    }

    static async filterVideoAssets(selectedAssets: ELAdobeAsset[], mediaGridConfig: MediaGridConfig): Promise<ELAdobeAsset[]> {
        const filteredAssets = [];
        let filteredVideoCount = 0;
        const shouldIncludeVideo = (videoDuration: number): boolean => {
            if (mediaGridConfig.maxVideoLength !== undefined && videoDuration > mediaGridConfig.maxVideoLength) {
                return false;
            }
            if (mediaGridConfig.maxVideoCount !== undefined && filteredVideoCount < mediaGridConfig.maxVideoCount) {
                return true;
            } else {
                return false;
            }
        };
        for (let i = 0; i < selectedAssets.length; i++) {
            if (Utils.isVideoMimeType(selectedAssets[i])) {
                try {
                    const embeddedMetadata = await AssetStorageUtils.getAndUpdateMetadata(selectedAssets[i]);
                    const videoDuration = AssetStorageUtils.getVideoDuration(embeddedMetadata);

                    if (shouldIncludeVideo(videoDuration)) {
                        filteredAssets.push(selectedAssets[i]);
                        filteredVideoCount++;
                    }
                } catch (error) {
                    Logger.log(LogLevel.WARN, "CreationUtils:filterVideoAssets: ", error);
                }
            } else {
                filteredAssets.push(selectedAssets[i]);
            }
        }

        return filteredAssets;
    }

    static filterMediaByCount(assets: ELAdobeAsset[], mediaGridConfig: MediaGridConfig): ELAdobeAsset[] {
        if ((mediaGridConfig.maxMediaCount !== undefined) && assets.length > mediaGridConfig.maxMediaCount) {
            assets.splice(mediaGridConfig.maxMediaCount, assets.length - mediaGridConfig.maxMediaCount);
        }

        return assets;
    }

    static filterMediaBySize(assets: ELAdobeAsset[], mediaGridConfig: MediaGridConfig): ELAdobeAsset[] {
        const filteredAssets = [];
        let totalMediaSize = 0;
        for (let i = 0; i < assets.length; i++) {
            const size = assets[i].size;
            totalMediaSize += size ? Utils.getBytesToMB(size) : 0;
            if (!((mediaGridConfig.maxTotalMediaSize !== undefined) && totalMediaSize > mediaGridConfig.maxTotalMediaSize)) {
                filteredAssets.push(assets[i]);
            }
        }

        return filteredAssets;
    }

    static filterMediaByFormat(assets: ELAdobeAsset[], mediaGridConfig: MediaGridConfig): ELAdobeAsset[] {
        const filteredAssets = [];
        for (let i = 0; i < assets.length; i++) {
            const format = assets[i].format;
            if (format) {
                const shouldIncludeMedia = (): boolean => {
                    return !((mediaGridConfig.filterMedia.format !== undefined) && mediaGridConfig.filterMedia.format.includes(format));
                };

                if (shouldIncludeMedia()) {
                    filteredAssets.push(assets[i])
                }
            }
        }

        return filteredAssets;
    }

    static shouldLaunchCreationFeedbackDialog(): boolean {
        const localStorageItem = localStorage.getItem(FEEDBACK_LAST_LAUNCH_KEY);

        if (localStorageItem === null) {
            return true;
        } else {
            const lastLaunchTime = parseInt(localStorageItem, 10);
            const currentTime = new Date().getTime();
            const differenceInTime = currentTime - lastLaunchTime;
            const millisecondsInADay = 1000 * 60 * 60 * 24;
            const daysDifference = Math.floor(differenceInTime / millisecondsInADay);
            if (daysDifference >= FEEDBACK_LAST_LAUNCH_DAYS_DIFFERENCE) {
                return true;
            } else {
                return false;
            }
        }
    }

    static shouldShowStockLicenseButton(): boolean {
        const contentSource = store.getState().recommendationWorkflowReducer.contentSource;
        if (contentSource === ELReplaceBackgroundContentSource.adobeStockPreview) {
            return true;
        }
        return false;
    }

    static setFeedbackLastLaunchTime(): void {
        const currentTime = new Date().getTime();
        localStorage.setItem(FEEDBACK_LAST_LAUNCH_KEY, currentTime.toString());
    }

    static getCreationWorkflowHistoryState(workflow: WorkflowsName, projectId: string): string {
        const historyState = Routes.CREATIONS + "?" + CreationsURLParams.workflow + "=" + workflow.toLowerCase() +
            "&" + CreationsURLParams.projectId + "=" + projectId;
        return historyState;
    }

    static getHighlightCreationHistoryState(workflow: WorkflowsName): string {
        const historyState = Routes.CREATIONS + "?" + CreationsURLParams.highlightCreation + "=" + workflow;
        return historyState;
    }
}
