/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//Adobe Internal
import { RenditionType } from "@dcx/common-types";

import {
    ELRenditionType,
    RepoDirListData
} from "@elements/elementswebcommon";

import MediaOrganizerAction from "../../stores/actions/mediaOrganizerActions";
import MediaThumbAction, { ThumbDataPayload } from "../../stores/actions/mediaThumbAction";
import store, { RootState } from "../../stores/store";
import Logger, { LogLevel } from "../Logger";
import useRepoDirFetch from "../mediaFetchUtils/useRepoDirFetch";
import useSharedMediaFetch from "../mediaFetchUtils/useShareMediaFetch";
import { ERROR_THUMBDATA, MediaThumbSize } from "../Constants/Constants";
import { AssetStorageUtils, DataResponseType, MediaDataResponse } from "../AssetStorageUtils";
import { ELAdobeAsset, ELRenditionOptions, elDeserializeAsset } from "../../common/interfaces/storage/AssetTypes";
import { EmbeddedMetadataType } from "../../common/interfaces/storage/StorageTypes";
import Utils from "../Utils";

// import data from '../../sortByDate';// yaverma -- remove later

// value in ms, if 30ms, total calls in a min -> (1000/30) * 60 = 2k RPM
const THUMB_FETCH_API_CALL_GAP = 30

export interface MediaFetchHook {
    isFetching: boolean,
    fetchData: () => Promise<void>,
    storeKey: string,
    fetchRendition: (asset: ELAdobeAsset, renditionOptions: ELRenditionOptions, responseType: DataResponseType) => Promise<MediaDataResponse>,
    pushAssetInThumbQueue: (asset: ELAdobeAsset) => Promise<void>,
    downloadAsset: (asset: ELAdobeAsset) => Promise<void>,
    fetchAssetEmbeddedData: (asset: ELAdobeAsset) => Promise<EmbeddedMetadataType>,
    deleteAsset: (asset: ELAdobeAsset) => Promise<boolean>,
    hasMoreData: () => boolean
}

export enum MediaSource {
    repoDirDataFetch,
    sharedMediaDirFetch
}

type MediaFetchFuncGetter = (s: string) => MediaFetchParams;

export const MediaFetchHookTypeMap: { [key in MediaSource]: MediaFetchFuncGetter } = {
    [MediaSource.repoDirDataFetch]: useRepoDirFetch,
    [MediaSource.sharedMediaDirFetch]: useSharedMediaFetch
};

export interface PageFetchReturnType {
    stateKey: string,
    dirList: RepoDirListData
}

export interface MediaFetchParams {
    fetchFirstPage: () => Promise<PageFetchReturnType>,
    fetchNextPage: () => Promise<PageFetchReturnType>,
    fetchRendition: (asset: ELAdobeAsset, renditionOptions: ELRenditionOptions, responseType: DataResponseType) => Promise<MediaDataResponse>,
    getStoreKey: () => string // used to identify where the the values are updated in redux-store,
    downloadDeserializedAsset: (asset: ELAdobeAsset) => Promise<void>,
    fetchAssetEmbeddedInfo: (asset: ELAdobeAsset) => Promise<EmbeddedMetadataType>,
    deleteDeserializedAsset: (asset: ELAdobeAsset) => Promise<boolean>
}

let thumbFetchingRequestedArr: { assetId?: string }[] = [];

const filterThumbFetchingRequestedArr = (assetId: string): void => {
    const arr = thumbFetchingRequestedArr.filter((ele) => {
        if (ele.assetId === assetId) {
            return false;
        }
        return true;
    });

    thumbFetchingRequestedArr = arr;
}

const useMediaFetch = (dirPath: string, mediaSourceType: MediaSource): MediaFetchHook => {
    const [sIsLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const intervalRef = useRef<NodeJS.Timer>();
    const thumbFetchQueueRef = useRef<Array<ELAdobeAsset>>([]);
    const rootData = useSelector((state: RootState) => state.mediaOrganizerReducer);
    const thumbData = useSelector((state: RootState) => state.mediaThumbReducer);

    const { fetchFirstPage,
        fetchNextPage,
        fetchRendition,
        getStoreKey,
        downloadDeserializedAsset,
        fetchAssetEmbeddedInfo,
        deleteDeserializedAsset } = MediaFetchHookTypeMap[mediaSourceType](dirPath);

    useEffect(() => {
        fetchThumbsInQueue();
    }, [rootData, intervalRef.current, thumbFetchQueueRef.current.length]);

    const hasMoreData = (): boolean => {
        return !getDirectoryData() || getDirectoryData().hasNextPage;
    }

    const shouldFetchAssetRendition = (asset: ELAdobeAsset): boolean => {
        return !AssetStorageUtils.isAssetPresentInArray(thumbFetchingRequestedArr, asset.assetId) &&
            !AssetStorageUtils.isAssetPresentInArray(thumbData, asset.assetId);
    }

    const fetchThumbsInQueue = (): void => {
        if (intervalRef.current === undefined) {
            Logger.log(LogLevel.DEBUG, "Starting interval");
            intervalRef.current = setInterval(() => {
                const asset = thumbFetchQueueRef.current.shift();

                if (!asset || !shouldFetchAssetRendition(asset)) {
                    if (intervalRef.current && !thumbFetchQueueRef.current.length) {
                        Logger.log(LogLevel.DEBUG, "Clearing interval");
                        clearInterval(intervalRef.current);
                        intervalRef.current = undefined;
                    }
                    return;
                }

                thumbFetchingRequestedArr.push({ assetId: asset.assetId });

                const thumbnailSize = Number(MediaThumbSize);
                const uiScaledRenditionSize = thumbnailSize / Utils.getBaseFontScale();
                const renditionSize = Math.max(thumbnailSize, Math.floor(uiScaledRenditionSize * Utils.getRenderedFactor()));
                const renditionOpts = {
                    size: renditionSize,
                    type: ELRenditionType.IMAGE_JPG as RenditionType
                };
                fetchRendition(asset, renditionOpts, "arraybuffer").then((response: MediaDataResponse) => {
                    const sharedArrayImgData = response.data as SharedArrayBuffer;
                    const objectURL = URL.createObjectURL(new Blob([sharedArrayImgData]));
                    updateThumbDataForAsset(asset, objectURL);
                }).catch((reason: any) => {
                    updateThumbDataForAsset(asset, ERROR_THUMBDATA);
                    Logger.log(LogLevel.WARN, "[useMediaFetch:fetchThumbsInQueue] Unable to fetch thumb", reason, "Loading static thumb");
                }).finally(() => {
                    asset.assetId && filterThumbFetchingRequestedArr(asset.assetId);
                });
            }, THUMB_FETCH_API_CALL_GAP);
        }
    }

    // for testing, remove later -- yaverma
    // const getTestData = (): RepoDirListData => {
    //     const temp = data as AdobeDirectoryData;
    //     const repoData = temp as AdobeDirectoryData;
    //     const childrenList = repoData.children as ELAdobeAsset[];

    //     const dirResult: RepoDirListData = {
    //         repoData: repoData,
    //         children: childrenList,
    //         hasNextPage: true,
    //     };
    //     return dirResult;
    // }

    const fetchData = async function (): Promise<void> {
        if (sIsLoading) {
            return Promise.reject();
        }

        setIsLoading(true);

        try {
            if (!getDirectoryData()) {
                // we don't have any data in the state, fetch data
                await fetchFirst();
            } else {
                if (getDirectoryData().hasNextPage) {
                    await fetchNext();
                }
            }
        } catch (err) {
            Logger.log(LogLevel.ERROR, "useMediaFetch:fetchData: ", err);
            setIsLoading(false);
            return Promise.reject();
        }

        setIsLoading(false);
    };

    const fetchFirst = async (): Promise<void> => {
        const path = getStoreKey();
        if (!rootData[path]) {
            try {
                const page = await fetchFirstPage();
                dispatch(MediaOrganizerAction.updateDirectory(page.stateKey, page.dirList));
                pushDirListDataInThumbQueue(page.dirList);
            } catch (error) {
                dispatch(MediaOrganizerAction.updateDirectory(path, AssetStorageUtils.ErrorRepoDirResult));
                // ingest error here //TODO:diagarwa
                Logger.log(LogLevel.ERROR, "useMediaFetch:fetchDirData: ", error);
            }
        }
    }

    const fetchNext = async (): Promise<void> => {
        const path = getStoreKey();
        const rootDirData = rootData[path];

        if (rootDirData.hasNextPage) {
            try {
                const page = await fetchNextPage();
                dispatch(MediaOrganizerAction.appendChildren(page.stateKey, page.dirList));
                pushDirListDataInThumbQueue(page.dirList);
            } catch (error) {
                Logger.log(LogLevel.ERROR, "useMediaFetch:fetchNext: ", error);
            }
        } else {
            Logger.log(LogLevel.INFO, "useMediaFetch:fetchNext: ", "next page doest not exist");
        }
    }

    const pushDirListDataInThumbQueue = async (dirResult: RepoDirListData): Promise<void> => {
        for (let i = 0; i < dirResult.children.length; i++) {
            const asset = elDeserializeAsset(dirResult.children[i]) as ELAdobeAsset;
            if(shouldFetchAssetRendition(asset))
                thumbFetchQueueRef.current.push(asset);
        }
        return Promise.resolve();
    }

    const pushAssetInThumbQueue = async (asset: ELAdobeAsset): Promise<void> => {
        if (!shouldFetchAssetRendition(asset) || AssetStorageUtils.isAssetPresentInArray(thumbFetchQueueRef.current, asset.assetId))
            return;

        thumbFetchQueueRef.current.push(asset);
    }


    const getDirectoryData = (): RepoDirListData => {
        return rootData[getStoreKey()];
    }

    const updateThumbDataForAsset = (asset: ELAdobeAsset, objectURL: string): void => {
        if (asset.assetId) {
            const thumbData: ThumbDataPayload = {
                assetId: asset.assetId,
                objectURL: objectURL
            }

            dispatch(MediaThumbAction.udpateThumb(thumbData));
        }
    }

    const downloadAsset = (asset: ELAdobeAsset): Promise<void> => {
        const deserializedAsset = elDeserializeAsset(asset);
        return downloadDeserializedAsset(deserializedAsset);

    }

    const fetchAssetEmbeddedData = async (asset: ELAdobeAsset): Promise<EmbeddedMetadataType> => {
        return fetchAssetEmbeddedInfo(asset);
    }

    const deleteAsset = async (asset: ELAdobeAsset): Promise<boolean> => {
        const deserializedAsset = elDeserializeAsset(asset);
        try {
            const response = await deleteDeserializedAsset(deserializedAsset);
            if (response === true) {
                store.dispatch(MediaOrganizerAction.removeAsset(getStoreKey(), deserializedAsset));
                return Promise.resolve(true);
            }
            return Promise.resolve(false);
        }
        catch (error) {
            return Promise.reject(false);
        }

    }

    const useMediaFetchObj: MediaFetchHook = {
        isFetching: sIsLoading,
        fetchData: fetchData,
        storeKey: getStoreKey(),
        fetchRendition: fetchRendition,
        pushAssetInThumbQueue: pushAssetInThumbQueue,
        downloadAsset: downloadAsset,
        fetchAssetEmbeddedData: fetchAssetEmbeddedData,
        deleteAsset: deleteAsset,
        hasMoreData: hasMoreData
    }

    return useMediaFetchObj;
};

export default useMediaFetch;