/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ELPIEDocActions } from "../../../common/interfaces/editing/pie/ELPIEDocTypes";
import { DEFAULT_STAGE_BACKGROUND, RendererUpdateData } from "../../../common/interfaces/renderer/RendererTypes";
import { ELStageObjectOptions } from "../../../common/interfaces/stage/StageTypes";
import IDoc from "../../document/IDoc";
import ILayer from "../../document/layer/ILayer";
import IDocDataParser from "../../document/parser/IDocDataParser";
import IRenderer from "../../renderer/IRenderer";
import IGraphicsStage from "../../stage/IGraphicsStage";
import ELPIEDocDataParser from "../parser/ELPIEDocDataParser";

export default class ELPIEDocRenderer extends IRenderer {
    constructor(doc: IDoc, stage: IGraphicsStage, dataParser: IDocDataParser) {
        super(stage, dataParser);
    }

    hasRenderingError(): boolean {
        for (const stageData of this.stageDataList) {
            if (stageData.stageShapeAndObjectOptions.shape.hasError()) {
                return true;
            }
        }

        return false;
    }

    async update(updateData: RendererUpdateData): Promise<void> {
        switch (updateData.type) {
            case ELPIEDocActions.layerAdded: {
                const stageAndObjectOptions = await (this.dataParser as ELPIEDocDataParser).getStageData<ILayer>(updateData.payload as ILayer);
                await this.addShape(stageAndObjectOptions);
                break;
            }
            case ELPIEDocActions.layerUpdated: {
                for (const stageData of this.stageDataList) {
                    const layer = (updateData.payload as ILayer);
                    if (stageData.object && stageData.object.data && stageData.object.data.payload === layer.getId()) {
                        const options: ELStageObjectOptions = {
                            ...stageData.stageShapeAndObjectOptions.objectOptions,
                            image: await layer.getRendtionURL()
                        };
                        await this.updateShape(stageData.stageShapeAndObjectOptions.shape, options);
                    }
                }
                break;
            }
        }
    }

    destroy(): void {
        super.destroy();
        this.stage.destroy();
    }

    async render(container: HTMLElement): Promise<void> {
        await super.render(container);
        return new Promise((resolve) => {
            this.stage.setBackground(DEFAULT_STAGE_BACKGROUND);
            this.dataParser.parseDocData().then((stageShapeAndObjectOptionsList) => {
                this.drawShapes(stageShapeAndObjectOptionsList).then((stageObjectList) => {
                    this.saveStageData(stageShapeAndObjectOptionsList, stageObjectList);
                    resolve();
                });
            });
        });
    }
}
