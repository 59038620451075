/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect } from "react";
import { Text } from "@react-spectrum/text";

//Adobe Internal
import { ActionButton, DialogTrigger, Divider, Flex } from "@adobe/react-spectrum";

//Application Specific
import { ELIcon } from "../../atoms/el-icon/ELIconView";
import { IconType } from "../../../../assets/IconConstants";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import IViewController from "../../../IViewController";
import { ELUserFeedbackAction, FeedbackIconVariant } from "../../../../common/interfaces/creations/templates/ELFeedbackDialogTypes";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";

import "./ELFeedbackDialogView.scss";

interface ELFeedbackDialogProps {
    controller: IViewController,
    text: string,
    variant: FeedbackIconVariant
}

export const ELFeedbackDialogView = (props: ELFeedbackDialogProps): React.ReactElement => {

    const intlHandler = IntlHandler.getInstance();

    useEffect(() => {
        props.controller.initialize();
        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller])

    const onYesClickHandler = (): void => {
        props.controller.notify({ type: ELUserFeedbackAction.clickedYes });
    }
    const onPressHandler = (): void => {
        props.controller.notify({ type: ELUserFeedbackAction.clicked });
    }

    const onNoClickHandler = (): void => {
        props.controller.notify({ type: ELUserFeedbackAction.clickedNo });
    }

    return (
        <ELReactSpectrumV3Provider>
            {props.variant === FeedbackIconVariant.insideCreation && <Divider size="M" alignSelf="center" />}
            <DialogTrigger placement="left" type="popover" isDismissable={true} >
                <ActionButton data-testid="feedback-action-button" onPress={onPressHandler} UNSAFE_className={"el-feedback-action-button"}>
                    <ELIcon className={"el-feedback-icon"} iconkey={IconType.feedbackNavIcon} />
                </ActionButton>
                {(close) => (
                    <Flex UNSAFE_className="el-feedback-action-top_container" justifyContent={"center"}>
                        <Flex UNSAFE_className="el-feedback-content__body" direction={"column"}>
                            <Flex> <span className="el-feedback-content__heading">{props.text}</span></Flex>
                            <Flex direction={"row"} justifyContent={"center"} gap={"1rem"}>
                                <ActionButton UNSAFE_className="feedback-cta__no" onPress={() => { onNoClickHandler(); close(); }} alignSelf={"center"} >
                                    <Flex>
                                        <ELIcon className="feedback-cta-icon__no" iconkey={IconType.negativeFeedbackFaceIcon} />
                                        <Text UNSAFE_className="feedback-cta-icon__text">{intlHandler.formatMessage("no")}</Text>
                                    </Flex>
                                </ActionButton>
                                <ActionButton UNSAFE_className="feedback-cta__yes" onPress={() => { onYesClickHandler(); close(); }}>
                                    <Flex>
                                        <ELIcon className="feedback-cta-icon__yes" iconkey={IconType.positiveFeedbackFaceIcon} />
                                        <Text UNSAFE_className="feedback-cta-icon__text">{intlHandler.formatMessage("yes")}</Text>
                                    </Flex>
                                </ActionButton>
                            </Flex>
                        </Flex>
                    </Flex>
                )}
            </DialogTrigger>
        </ELReactSpectrumV3Provider>
    );
}