/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";
import ReactDOM from "react-dom";

//Application Specific
import { ELCreationPopUpView } from "./ELCreationPopUpView";
import { ControllerAction } from "../../../IViewController";
import { ELSharePopUpAction } from "../../../../common/interfaces/share/ShareTypes";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectCustomKey, IngestLogObjectValue, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import { Routes } from "../../../../app/AppRoute";
import { IngestLogging } from "../../../../services/IngestWrapper";
import { WorkspacesName } from "../../../../workspaces/IBaseWorkspace";
import { FeaturesManager } from "../../../../modules/floodgate/Featuresmanager";
import { FeatureName } from "../../../../services/Floodgate/FloodgateConstants";
import ITemplateViewController from "../../../ITemplateViewController";

//Utils
import Utils from "../../../../utils/Utils";
import { PlatformUtils } from "../../../../utils/PlatformUtils";

export interface ELCreationPopUpProps {
    showTimeout: number,
    hideTimeout: number,
    screen: string,
    storageKey: string,
    mediaPath: string,
    contentKey: string,
    floodgateKey: FeatureName
}

export class ELCreationPopUp extends ITemplateViewController {
    private _screenInfo?: string;
    // @ts-ignore //TODO: Vishesh Middha
    private _storageKey?: string;

    shouldShowPopup(floodgateKey: FeatureName, storageKey: string): boolean {
        if (FeaturesManager.getInstance().IsFeatureActive(floodgateKey) && !PlatformUtils.isAHandheldDevice(window.innerWidth, window.innerHeight)) {
            const lastShown = localStorage.getItem(storageKey);
            return lastShown !== "true";
        }
        return false;
    }

    updateLastShown(storageKey: string): void {
        if (storageKey) {
            localStorage.setItem(storageKey, "true");
        }
    }

    createView(container: HTMLElement, viewProps?: unknown): void {
        super.createView(container);
        const popUpConfiguration = viewProps as ELCreationPopUpProps;
        this._storageKey = popUpConfiguration.storageKey;
        this._screenInfo = popUpConfiguration.screen;
        if (this.shouldShowPopup(popUpConfiguration.floodgateKey, popUpConfiguration.storageKey)) {
            if (container) {
                const popupView = React.createElement(ELCreationPopUpView, {
                    controller: this,
                    timeout: popUpConfiguration.showTimeout,
                    visible: true,
                    mediaPath: popUpConfiguration.mediaPath,
                    contentKey: popUpConfiguration.contentKey
                });
                ReactDOM.render(popupView, container);
                this.updateLastShown(popUpConfiguration.storageKey);
                this.notify({ type: ELSharePopUpAction.popUpRendered });

                setTimeout(() => {
                    this.destroyView();
                }, popUpConfiguration.hideTimeout);
            }
        }
    }

    destroyView(): void {
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container);
        }
        super.destroyView();
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        const additionalLogInfo: Record<string, string> = {};
        if (this._screenInfo) {
            additionalLogInfo[IngestLogObjectCustomKey.viewType] = this._screenInfo;
        }
        switch (action.type) {
            case ELSharePopUpAction.popUpGetStarted: {
                IngestLogging.getInstance().logEvent(IngestUtils.addWorkspaceDetail(WorkspacesName.ipn,
                    IngestUtils.getPseudoLogObject(IngestWorkflowTypes.popUp, IngestEventTypes.click, IngestEventSubTypes.getStarted, true, additionalLogInfo)));
                Utils.openInNewTab(Routes.CREATIONS);
                this.destroyView();
                handled = true;
                break;
            }
            case ELSharePopUpAction.popUpClosed: {
                IngestLogging.getInstance().logEvent(IngestUtils.addWorkspaceDetail(WorkspacesName.ipn,
                    IngestUtils.getPseudoLogObject(IngestWorkflowTypes.popUp, IngestEventTypes.click, IngestEventSubTypes.close, true, additionalLogInfo)));
                this.destroyView();
                handled = true;
                break;
            }
            case ELSharePopUpAction.popUpRendered: {
                IngestLogging.getInstance().logEvent(IngestUtils.addWorkspaceDetail(WorkspacesName.ipn,
                    IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventTypes.success, IngestEventSubTypes.render, IngestLogObjectValue.popUp, additionalLogInfo)));
                handled = true;
                break;
            }
        }
        return handled;
    }
}