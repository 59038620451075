/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react"

//Adobe Internal
import { Flex } from "@adobe/react-spectrum";

//Application Specific
import IViewController from "../../../IViewController";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { ELDropZoneView } from "../../organism/el-drop-zone/ELDropZone";
import { MediaGridImportControllerAction } from "../../../../common/interfaces/media-import/MediaImportType";

interface ELNoMediaDragDropProps {
    controller: IViewController,
    showAddButton?: boolean
}

export const ELNoMediaDragDropView = (props: ELNoMediaDragDropProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();


    const handleDroppedFiles = (files: File[]): void => {
        props.controller.notify({ type: MediaGridImportControllerAction.startImport, payload: files });
    };
    return (
        <Flex UNSAFE_className="no-media-drag_drop" alignItems="center" justifyContent="center">
            <Flex width="50%" alignItems="center" justifyContent="center" direction="column" marginTop="2.5rem" marginBottom="2rem">
                <ELDropZoneView
                    controller={props.controller}
                    allowedFilesFilter={(file: File) => true} 
                    handleDroppedFiles={handleDroppedFiles}
                    dragAndDropHeading={intlHandler.formatMessage("drag-and-drop-heading-text")}
                    dragAndDropContentText={intlHandler.formatMessage("drag-drop-from-your-computer")}
                    dragAndDropFilledText={intlHandler.formatMessage("media-upload-in-progress")} />
            </Flex>
        </Flex>
    );
}

ELNoMediaDragDropView.defaultProps = {
    showAddButton: true
}
