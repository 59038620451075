/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { CreationsStatus } from "../../common/interfaces/creations/CreationTypes";
import { ELRecommendationWorkflowActionTypes } from "../../common/interfaces/creations/ELRecommendationsWorkflowTypes";
import IAction from "../actions/IAction";
import { RecommendationWorkflowActionPayload } from "../actions/RecommendationWorkflowAction";

interface RecommendationWorkflowReducerData {
    status: CreationsStatus,
    id?: string,
    projectStatus?: CreationsStatus,
    title?: string,
    uiTitle?: string,
    selectedOverlayId?: string,
    selectedOverlayName?: string,
    contentSource?: string
}

const getInitialState = (): RecommendationWorkflowReducerData => {
    return {
        status: CreationsStatus.requested,
    }
}

const updateRecommendationStatus = (state: RecommendationWorkflowReducerData, action: IAction<RecommendationWorkflowActionPayload>): RecommendationWorkflowReducerData => {
    if (action.payload) {
        return {
            ...state,
            status: action.payload as CreationsStatus
        }
    }
    return state;
}

const updateProjectStatus = (state: RecommendationWorkflowReducerData, action: IAction<RecommendationWorkflowActionPayload>): RecommendationWorkflowReducerData => {
    if (action.payload) {
        return {
            ...state,
            projectStatus: action.payload as CreationsStatus
        }
    }
    return state;
}

const updateSelectedOverlayId = (state: RecommendationWorkflowReducerData, action: IAction<RecommendationWorkflowActionPayload>): RecommendationWorkflowReducerData => {
    return {
        ...state,
        selectedOverlayId: action.payload
    }
}

const updateSelectedOverlayName = (state: RecommendationWorkflowReducerData, action: IAction<RecommendationWorkflowActionPayload>): RecommendationWorkflowReducerData => {
    return {
        ...state,
        selectedOverlayName: action.payload
    }
}

const updateContentSource = (state: RecommendationWorkflowReducerData, action: IAction<RecommendationWorkflowActionPayload>): RecommendationWorkflowReducerData => {
    return {
        ...state,
        contentSource: action.payload
    }
}

const updateProjectTitle = (state: RecommendationWorkflowReducerData, action: IAction<RecommendationWorkflowActionPayload>): RecommendationWorkflowReducerData => {
    if (action.payload) {
        const newTitle = action.payload as string;

        if (newTitle.length === 0)
            return state;

        return {
            ...state,
            title: action.payload as string
        }
    }
    return state;
}

const updateUiTitle = (state: RecommendationWorkflowReducerData, action: IAction<string>): RecommendationWorkflowReducerData => {
    const data = action.payload as string;
    return {
        ...state,
        uiTitle: data
    };
}

const recommendationWorkflowReducer = (state: RecommendationWorkflowReducerData = getInitialState(), action: IAction<RecommendationWorkflowActionPayload>): RecommendationWorkflowReducerData => {
    switch (action.type) {
        case ELRecommendationWorkflowActionTypes.UPDATE_RECOMMENDATION_STATUS:
            return updateRecommendationStatus(state, action);
        case ELRecommendationWorkflowActionTypes.UPDATE_SELECTED_OVERLAY_ID:
            return updateSelectedOverlayId(state, action);
        case ELRecommendationWorkflowActionTypes.UPDATE_SELECTED_OVERLAY_NAME:
            return updateSelectedOverlayName(state, action);
        case ELRecommendationWorkflowActionTypes.UPDATE_CONTENT_SOURCE:
            return updateContentSource(state, action);
        case ELRecommendationWorkflowActionTypes.UPDATE_PROJECT_TITLE:
            return updateProjectTitle(state, action);
        case ELRecommendationWorkflowActionTypes.UPDATE_PROJECT_STATUS:
            return updateProjectStatus(state, action);
        case ELRecommendationWorkflowActionTypes.UPDATE_UI_TITLE:
            return updateUiTitle(state, action);
        default:
            return state;
    }
}

export default recommendationWorkflowReducer;