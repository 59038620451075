/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import CookiePreferencesAction from "../stores/actions/CookiePreferencesActions";
import store from "../stores/store";
import Logger, { LogLevel } from "../utils/Logger";
import Utils from "../utils/Utils";
import { CookiePreferences } from "../utils/PrivacyUtils";
import { IngestUtils } from "../utils/IngestUtils";
import { INGEST_PROFILE_WORKSPACE, IngestEventTypes, IngestWorkflowTypes } from "../utils/IngestConstants";
import { IngestLogging } from "./IngestWrapper";
import { AppCode, LocaleUtils } from "../utils/LocaleUtils";
import { WindowUtils } from "../utils/WindowUtils";

declare global {
  interface Window {
    fedsConfig: FedsConfig
    adobePrivacy: {
      activeCookieGroups(): string[];
      showConsentPopup(): void
    };
  }
}

interface FedsConfigContent {
  experience: string;
}

interface FedsFooter {
  regionModal: (event: unknown) => void;
}

export interface FedsConfig {
  locale?: string;
  privacy?: PrivacyConfig
  content?: FedsConfigContent;
  footer?: FedsFooter
}

export interface PrivacyConfig extends Object {
  otDomainId: string | undefined
  footerLinkSelector: string
}


// https://wiki.corp.adobe.com/display/adobedotcom/Privacy#Privacy-changes
export default class AdobePrivacy {
  private static _instance: AdobePrivacy = new AdobePrivacy();
  static getInstance(): AdobePrivacy {
    return AdobePrivacy._instance;
  }

  private constructor() {
    window.fedsConfig = {
      privacy: {
        otDomainId: process.env.REACT_APP_PRIVACY_DOMAIN_ID,
        footerLinkSelector: '[data-feds-action="open-adchoices-modal"]',
      },
    };

  }

  async load(): Promise<void> {
    window.fedsConfig.locale = LocaleUtils.getAppLocale(AppCode.globalNav);
    
    if (!process.env.REACT_APP_PRIVACY_LIB_URL)
      return Promise.reject(new Error('REACT_APP_PRIVACY_LIB_URL not set'));
    try {
      // the script is served with incorrect cache headers which results in CORS issue when
      // the script has first been loaded by a site that has not requested it cors mode.
      // Ideally the script needs to be served with Vary: Origin response header to ensure the browser
      // will use different cache entries. Until then we are appending ?cors-psweb to the script url
      // to ensure we have a different cache entry.
      const subdomain = window.location.hostname.split('.').reverse()[3];
      await Utils.loadScript(
        "adobeprivacy", process.env.REACT_APP_PRIVACY_LIB_URL + `?cors-elweb${subdomain ? `-${subdomain}` : ''}`, true);
    } catch (error) {
      Logger.log(LogLevel.WARN, "Error in loading AdobePrivacy: ", error);
      return Promise.reject(error);
    }
  }

  getActiveCookieGroups(): string[] {
    return window.adobePrivacy.activeCookieGroups();
  }

  isPerformanceCookieActive(): boolean {
    return this.getActiveCookieGroups().includes(CookiePreferences[CookiePreferences.C0002])
  }

  updateCookiePrefs(): void {
    store.dispatch(CookiePreferencesAction.updateCookiePreferences(this.getActiveCookieGroups()));
    AdobePrivacy.getInstance()._addEventListeners();
    const cookies = window.adobePrivacy.activeCookieGroups().toString();
    const cookieIngestObject = IngestUtils.addWorkspaceDetail(INGEST_PROFILE_WORKSPACE, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
      IngestEventTypes.change, IngestEventTypes.cookiepreferences, cookies));
    IngestLogging.getInstance().logEvent(cookieIngestObject);
  }

  private _checkConsentAndReload(): void {
    const activeGroups = this.getActiveCookieGroups();
    const previousActiveGroups = store.getState().cookiePreferencesReducer;
    store.dispatch(CookiePreferencesAction.updateCookiePreferences(activeGroups));
    if (activeGroups.includes(CookiePreferences[CookiePreferences.C0002]) !== previousActiveGroups.includes(CookiePreferences[CookiePreferences.C0002])) {
      WindowUtils.reload();// Need to reload to enable or disable new relic based on cookies
    }
  }

  private _addEventListeners(): void {
    window.addEventListener('adobePrivacy:PrivacyConsent', () => {
      Logger.log(LogLevel.INFO, "all groups and hosts enabled");
      this._checkConsentAndReload();
    });

    window.addEventListener('adobePrivacy:PrivacyCustom', () => {
      Logger.log(LogLevel.INFO, "user selected groups and hosts");
      this._checkConsentAndReload();
    });

    window.addEventListener('adobePrivacy:PrivacyReject', () => {
      Logger.log(LogLevel.INFO, "only core groups and hosts enabled - C0001");
      this._checkConsentAndReload();
    });
  }

  showConsentPopup(): void {
    window.adobePrivacy?.showConsentPopup();
  }
}

