/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ELAdobeAsset } from "../../../common/interfaces/storage/AssetTypes";
import { AdjustmentsEditor } from "../../../editors/adjustments/AdjustmentsEditor";
import AdjustmentsPresets from "../../../editors/adjustments/AdjustmentsPresets";
import AdjustmentsAction from "../../../stores/actions/AdjustmentsActions";
import store from "../../../stores/store";
import { AssetStorageUtils } from "../../../utils/AssetStorageUtils";
import ImageUtils from "../../../utils/ImageUtils";
import Logger, { LogLevel } from "../../../utils/Logger";

export class AdjustmentsPresetsPreviewGenerator {
    private _adjustmentsEditor?: AdjustmentsEditor;

    async initialize(asset: ELAdobeAsset): Promise<void> {
        //GLIA_REVISIT check if we need to initialize the editor here
        this._adjustmentsEditor = new AdjustmentsEditor();
        const imageData = await this._getAssetRendition(asset);
        await this._adjustmentsEditor.initialize();
        this._adjustmentsEditor.setData(imageData);
    }

    private _getAssetRenditionFromStore(assetId: string): string | undefined {
        const assetThumb = store.getState().mediaThumbReducer.filter((ele) => ele.assetId === assetId);
        if (assetThumb.length > 0) {
            return assetThumb[0].objectURL;
        }
        return undefined;
    }

    private async _getAssetRendition(asset: ELAdobeAsset): Promise<ImageData> {
        const assetId = asset?.assetId;
        if (!assetId) {
            return Promise.reject("AdjustmentsPresetsPreviewGenerator: _getAssetRendition: Asset ID is not available");
        }

        try {
            let objectURL = this._getAssetRenditionFromStore(assetId);
            if (!objectURL) {
                objectURL = await AssetStorageUtils.getAndStoreAssetRendition(assetId);
            }
            const imageData = await ImageUtils.createImageData(objectURL);
            return imageData;
        } catch (error) {
            Logger.log(LogLevel.WARN, "AdjustmentsPresetsPreviewGenerator: _getAssetRendition: Error getting asset rendition", error);
            return Promise.reject(error);
        }
    }

    async generateAndSetPreviewURLs(): Promise<void> {
        AdjustmentsPresets.getAdjustmentsPresets().forEach(async (value) => {
            if (this._adjustmentsEditor) {
                try {
                    const presetImageData = await this._adjustmentsEditor.applyEdit({ params: value.params, intensity: 1 });
                    const presetPreviewURL = await ImageUtils.getDataURL(presetImageData);
                    store.dispatch(AdjustmentsAction.updatePresetPreviewURL({ id: value.id, previewUrl: presetPreviewURL }));
                } catch (error) {
                    Logger.log(LogLevel.WARN, "AdjustmentsPresetsPreviewGenerator: generateAndSetPreviewURLs: Error generating preset preview URL", error);
                }
            }
        });
    }
}