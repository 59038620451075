/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { TemplateId, Operation } from "@elements/elementswebcommon";

//Application Specific
import { CSAsset } from "./CreationTypes";
import { ELAdobeAsset } from "../storage/AssetTypes";

export enum ELRecommendationWorkflowViewActions {
    updateAllThumbsData = "UPDATE_ALL_THUMBS_DATA",
    updateSingleThumbData = "UPDATE_SINGLE_THUMBDATA",
    setSelectedOverlayId = "SET_SELECTED_OVERLAY_ID",
    recommendationWorkflowStatus = "PATTERN_OVERLAY_STATUS",
    recommendationWorkflowProgressText = "PATTERN_OVERLAY_PROGRESS_TEXT"
}

export enum ELRecommendationWorkflowActionTypes {
    UPDATE_RECOMMENDATION_STATUS = "UPDATE_RECOMMENDATION_STATUS",
    UPDATE_SELECTED_OVERLAY_ID = "UPDATE_SELECTED_OVERLAY_ID",
    UPDATE_SELECTED_OVERLAY_NAME = "UPDATE_SELECTED_OVERLAY_NAME",
    UPDATE_CONTENT_SOURCE = "UPDATE_CONTENT_SOURCE",
    UPDATE_PROJECT_TITLE = "UPDATE_PROJECT_TITLE",
    UPDATE_UI_TITLE = "UPDATE_UI_TITLE",
    UPDATE_PROJECT_STATUS = "UPDATE_PROJECT_STATUS"
}

export enum ELRecommendationWorkflowControllerActions {
    appendToOverlaysData = "APPEND_TO_OVERLAYS_DATA",
    setSelectedOverlayId = "SET_SELECTED_OVERLAY_ID",
    overlayClicked = "OVERLAY_CLICKED",
    updateSingleOverlayData = "UPDATE_SINGLE_THUMB_DATA",
    putErrorDataInThumbnail = "PUT_ERROR_DATA_IN_THUMB",
    cleanAllThumbsData = "CLEAN_ALL_THUMBS_DATA",
    keepThumbIdOnTop = "KEEP_THUMB_ID_ON_TOP",
    backRecommendationWorkflowView = "BACK_PATTERN_OVERLAY_VIEW",
    endLayoutWorkflow = "END_LAYOUT_WORKFLOW"
}

export interface ELPreviewCreationThumbData {
    id: string,
    name: string,
    assetId?: string,
    imageData?: string
}

export interface ELRecommendationWorkflowCreationState {
    viewData: ELPreviewCreationThumbData[],
    selectedOverlay: string;
}

export interface ELThumbUpdateProps {
    id: TemplateId,
    asset?: ELAdobeAsset
}

export interface ELRecommendationsOutputJsonConfigData {
    id: string,
    title: string,
    assets: CSAsset[],
    language: string,
    operations: Operation[],
    contentSource: string,
    stockAssetIds: string[]
}

export interface ELCreateAndEditProjectParams {
    asset: ELAdobeAsset
}