/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { ReactNode, useEffect, useState } from "react";

//Application Specific
import ELProgressContent from "../el-progress-content/ELProgressContent";
import LoadingIcon1 from "./../../../../assets/progressloading/EL_Loading_1.svg";
import LoadingIcon2 from "./../../../../assets/progressloading/EL_Loading_2.svg";
import LoadingIcon3 from "./../../../../assets/progressloading/EL_Loading_3.svg";
import LoadingIcon4 from "./../../../../assets/progressloading/EL_Loading_4.svg";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";

import "./ELProgressContentLoop.scss";

export interface ELGenericProgressContentLoopProps {
    contentHeader: string,
    loopInterval?: number,
    progressTextImgMapList?: { text: string, img: string }[],
    progressPercentNode?: ReactNode,
    children?: ReactNode
}

const ELGenericProgressContentLoop = (props: ELGenericProgressContentLoopProps): React.ReactElement => {
    const START_COUNTER = 0;
    const DEFAULT_LOOP_INTERVAL = 5000;
    const DEFAULT_PROGRESS_TEXT_IMG_MAP_LIST: { text: string, img: string }[] = [
        {
            text: IntlHandler.getInstance().formatMessage("getting-things-ready-for-you"),
            img: LoadingIcon1
        },
        {
            text: IntlHandler.getInstance().formatMessage("making-sure-everything-looks-just-right"),
            img: LoadingIcon2
        },
        {
            text: IntlHandler.getInstance().formatMessage("adding-the-final-touches"),
            img: LoadingIcon3
        },
        {
            text: IntlHandler.getInstance().formatMessage("finishing-up"),
            img: LoadingIcon4
        }
    ];

    const progressTextImgMapList = props.progressTextImgMapList ?? DEFAULT_PROGRESS_TEXT_IMG_MAP_LIST;
    const progressTextImgMapListLength = progressTextImgMapList.length;
    const loopInterval = props.loopInterval ?? DEFAULT_LOOP_INTERVAL;

    const [counter, setCounter] = useState(START_COUNTER);
    const [progressText, setProgressText] = useState(progressTextImgMapList[START_COUNTER].text);
    const [progressImg, setProgressImg] = useState(progressTextImgMapList[START_COUNTER].img);

    useEffect(() => {
        const intervalId = setInterval(() => {
            // Loop back to 0 after progressTextImgMapList lenght
            setCounter(counter => (counter + 1) % progressTextImgMapListLength);
        }, loopInterval);

        setProgressText(progressTextImgMapList[counter].text);
        setProgressImg(progressTextImgMapList[counter].img);
    
        return () => clearInterval(intervalId);
    }, [counter]);      

    return (
        <ELProgressContent 
            contentHeader={props.contentHeader} 
            progressText={progressText} 
            progressImg={progressImg}
            progressPercentNode={props.progressPercentNode}>
                        {props.children}
        </ELProgressContent>
    )
}

export default ELGenericProgressContentLoop;