/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { PIE, pixels } from "@piewasm/pie-web-npm-package";
import { PlatformUtils } from "../../../utils/PlatformUtils";
import Utils from "../../../utils/Utils";
import { UNTITLED_INTL_KEY } from "../../../common/interfaces/creations/CreationTypes";

export const PSD_NAME_INTL_KEY = UNTITLED_INTL_KEY;

export default class PIEUtils {
    static isPIEWasmSupported(): boolean {
        return (typeof SharedArrayBuffer !== undefined) && !PlatformUtils.isRunningOnSafari();
    }

    //TODO: Nishant
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static async getPIEPixelsFromArrayBuffer(data: ArrayBuffer, pieInstance: PIE, pieFS: any): Promise<pixels> {
        return new Promise((resolve, reject) => {
            const inputFileName = Utils.getRandomUUID() + ".png";
            const stream = pieFS.open(inputFileName, "w");
            const array = new Int8Array(data);
            pieFS.write(stream, array, 0, array.length, 0);
            pieFS.close(stream);
            //pieFS.syncfs(false, async (err: any) => {
            //if (err) {
            //reject(err);
            //} else {
                    const idbFile = (pieFS as any).findObject(
                        inputFileName
                    );

                    if (!idbFile) {
                        reject("couldn't find valid inputFileName!");
                    }

                    const refImage = new pieInstance.image(inputFileName);
                    if (!refImage) {
                        reject("Couldn't create image for layer!");
                    }

                    const pixels = refImage.get_composite();
                    resolve(pixels);
            //}
            //});
        });
    }
}

