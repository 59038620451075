/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { StockImageLicenseInfo, StockImagesResponse } from "../common/interfaces/services/StockServiceTypes";
import { ElementsStockServiceWrapper } from "../services/ElementsStockServiceWrapper";
import StockServiceConstants from "./Constants/StockServiceConstants";
import Logger, { LogLevel } from "./Logger";

export class StockServiceUtils {

    private static _stockServiceWrapper = ElementsStockServiceWrapper.getInstance();

    static async fetchImages(query: string, locale?: string, offset?: number, limit?: number): Promise<StockImagesResponse> {
        try {
            Logger.log(LogLevel.INFO, `Stock Service Utils: Fetching images for query: ${query}`);
            const images = await this._stockServiceWrapper.getStockImagesData(query, locale ? locale : "en_US", offset ? offset : 0, limit ? limit : 40);
            return images;
        } catch (error) {
            Logger.log(LogLevel.ERROR, "Error fetching images in Stock Service Utils", error);
            throw error;
        }
    }

    static async fetchLicenseInfo(imageId: string): Promise<StockImageLicenseInfo> {
        try {
            Logger.log(LogLevel.INFO, `Stock Service Utils: Fetching license info for imageId: ${imageId}`);
            const licenseInfo = await this._stockServiceWrapper.getStockImageLicenseInfo(imageId);
            return Promise.resolve(licenseInfo);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "Error fetching license info in Stock Service Utils", error);
            return Promise.reject(error);
        }
    }

    static async isImageLicensed(imageId: string): Promise<boolean> {
        const imageLicenseDetails = await this.fetchLicenseInfo(imageId);
        let purchaseState = StockServiceConstants.STOCK_IMAGE_NOT_PURCHASED;
        if (imageLicenseDetails !== undefined) {
            purchaseState = imageLicenseDetails.contents[imageId].purchase_details.state;
        }
        return Promise.resolve(purchaseState === StockServiceConstants.STOCK_IMAGE_PURCHASED);
    }

    static async licenseStockImage(imageId: string): Promise<any> {
        await this._stockServiceWrapper.acquireStockImageLicense(imageId);
    }

    static async downloadImage(imageId: string, imageSize?: number): Promise<any> {
        const downloadImageResponse = await this._stockServiceWrapper.downloadStockImage(imageId, imageSize ? imageSize : StockServiceConstants.STOCK_IMAGE_SIZE_DEFAULT as number);
        return downloadImageResponse;
    }
}