/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";

//Application Specific
import AppView from "./AppView";
import IViewController, { ControllerAction } from "../view/IViewController";
import { ViewAction } from '../view/IBaseController';
import { Routes } from "./AppRoute";
import store from "../stores/store";
import AppAction from "../stores/actions/AppActions";
import { LoadTimeMarkers } from "../common/interfaces/performance/ELPerfTypes";

//service
import IMS from "./../services/IMS";
import { IngestLogging } from "../services/IngestWrapper";
import { TrialService } from "../services/ElementsServices/TrialService";
import { ElementsContentManagerWrapper } from "../services/ElementsServices/ElementsContentManagerWrapper";
import { EditingEngineManager } from "../editors/editingEngines/EditingEngineManager";
import ElementsContentService from "../services/ElementsServices/ElementsContentService";
import { FontsStore } from "../services/font/ELFontStore";

//components
import Navbar from "../view/components/organism/el-navbar/ELNavbar";
import { ELToast } from "../view/components/molecules/el-toast/ELToast";

//modules and utils
import { FeaturesManager } from "../modules/floodgate/Featuresmanager";
import { IntlHandler } from "../modules/intlHandler/IntlHandler";
import Logger, { LogLevel } from "../utils/Logger";
import Utils from "../utils/Utils";
import CreationInAppNotifier from "../workspaces/creations/utils/CreationInAppNotifier";
import { CreationAppSubscriberType, CreationInAppNotifierAction, CreationStatusData } from "../common/interfaces/creations/CreationInAppNotifierTypes";
import { IngestUtils } from "../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes, INGEST_APP_WORKSPACE, IngestLogObjectKey, IngestLogObjectCustomKey } from "../utils/IngestConstants";
import { UrlOpenTargets } from "../utils/Constants/Constants";
import CreationUtils from "../workspaces/creations/utils/CreationUtils";
import ELBroadcastChannel from "../../src/modules/broadcastChannel/ELBroadcastChannel";
import { AppViewActions } from "../common/interfaces/app/AppTypes";
import AppRouteUtil from "./AppRouteUtil";
import NewRelic from "../newRelic/NewRelic";
import { TrialUtils } from "../utils/TrialUtils";
import { UserUtils } from "../utils/UserUtils";
import AdobePrivacy from "../services/AdobePrivacy";
import { WorkflowActionType } from "../workspaces/IWorkflow";
import PIEUtils from "../editors/pie/utils/PIEUtils";
import { LocaleCompiler } from "../modules/intlHandler/LocaleCompiler";
import { PerfMonitorModel, PerfMonitorModelOptions } from "../modules/adobeInternal/hz/perfMonitor/PerfMonitorModel";
import { EditingEngineType } from "../common/interfaces/editing/editingEngines/EditingEnginesTypes";
import { LoginUtils } from "../utils/LoginUtils";
import { RedirectToWebWorkflowsUtils } from "../utils/RedirectToWebWorkflowsUtils";
import { AppInitializer } from "./AppInitializer";

//Workspaces
import Creations from "../workspaces/creations/Creations";
import Organizer from "../workspaces/organizer/Organizer";
import { LinkPreview } from "../workspaces/LinkPreview/LinkPreview";
import { UserHome } from "../workspaces/Home/UserHome";
import Jarvis from "../services/Jarvis";
import { ELCreateOnDemandAction } from "../common/interfaces/creations/ELCreateOnDemandTypes";
import EditWorkspace from "../workspaces/edit/EditWorkspace";
import { AccessProfileManager } from "../modules/accessProfile/AccessProfile";
import { ELCreationPopUp } from "../view/components/organism/el-pop-up/ELCreationPopUp";
import { CreationPopUpAction } from "../common/interfaces/creation-popup/CreationPopUpTypes";
import { MediaExistUtil } from "../workspaces/creations/utils/MediaExistUtils";

declare global {
    // eslint-disable-next-line no-var -- must declare perfMonitor on global scope
    var perfMonitor: PerfMonitorModel
}

export default class App extends IViewController {
    private _navbar?: Navbar;
    private _creations: Creations;
    private _organizer: Organizer;
    private _editWorkspace: EditWorkspace;
    private _linkPreview: LinkPreview;
    private _userHome: UserHome;
    private _toast?: ELToast;
    // @ts-ignore TODO: Ashish
    private _activeRoute: string;
    private _creationPopUp?: ELCreationPopUp;

    constructor() {
        super();
        this._navbar = new Navbar(this);
        this._creations = new Creations(this);
        this._organizer = new Organizer(this);
        this._editWorkspace = new EditWorkspace(this);
        this._linkPreview = new LinkPreview(this);
        this._userHome = new UserHome(this);
        this._toast = new ELToast();
        this._activeRoute = '/';
        this._creationPopUp = new ELCreationPopUp(this);
        const perfMonitorModelOptions: PerfMonitorModelOptions = {
            maxActions: 50,
            markAllActions: false
        };
        globalThis.perfMonitor = new PerfMonitorModel(perfMonitorModelOptions);
        ELBroadcastChannel.createInstance();
        MediaExistUtil.getInstance().subscribeToStore();
    }

    private _setTrialRefreshing(): void {
        window.addEventListener("focus", this.refreshTrialInfo.bind(this));
    }

    private _resetTrialRefreshing(): void {
        window.removeEventListener("focus", this.refreshTrialInfo.bind(this));
    }

    private _logAccessInfo(): void {
        const daysInTrial = store.getState().appReducer.daysInTrial;
        const trialTypeValue = UserUtils.getTrialTypeValue(daysInTrial);
        const accessObject = IngestUtils.addWorkspaceDetail(INGEST_APP_WORKSPACE, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.trial,
            IngestEventSubTypes.info, IngestEventTypes.trialType, trialTypeValue));

        IngestLogging.getInstance().logEvent(accessObject);
    }

    private _logTrialExpiredTransition(): void {
        const trialExpiredObject = IngestUtils.addWorkspaceDetail(INGEST_APP_WORKSPACE, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.refreshTrial,
            IngestEventTypes.trialExpired, IngestEventSubTypes.success, true));

        IngestLogging.getInstance().logEvent(trialExpiredObject);
    }

    private _logSignInInfo(): void {
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.eventCount] = sessionStorage.getItem("linkSource") ?? "";
        additionalLogInfo[IngestLogObjectKey.contentName] = IngestLogObjectCustomKey.source;
        const signInIngestObject = IngestUtils.addWorkspaceDetail(INGEST_APP_WORKSPACE, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.app,
            IngestEventSubTypes.success, IngestEventTypes.signIn, true, additionalLogInfo));
        IngestLogging.getInstance().logEvent(signInIngestObject);
    }

    private async _initModules(): Promise<void> {
        try {
            globalThis.perfMonitor.beginStartupAction(LoadTimeMarkers.initModules);
            IMS.getInstance().load().then(() => {
                IMS.getInstance().initialize().then(async () => {
                    await FontsStore.getInstance().initTypeKit();
                });
            });

            const adobePrivacyLoadPromise = AdobePrivacy.getInstance().load();
            adobePrivacyLoadPromise.then(() => {
                NewRelic.enableNewRelicTracking();
                AdobePrivacy.getInstance().updateCookiePrefs();
            });

            //TODO: For performance gain, since dictionary json will be changed only if dictionaries are deployed,
            //check if we can cache it
            await ElementsContentManagerWrapper.instance.initialize();
            await IntlHandler.getInstance().init({ localeUrlMap: LocaleCompiler.instance.dictMap });
            ElementsContentService.getInstance().initialize(Utils.getCurrentLocaleInSnakeCase());

            const initModulesPromises: Promise<any>[] = [];
            initModulesPromises.push(IMS.getInstance().ready());
            initModulesPromises.push(adobePrivacyLoadPromise);
            await Promise.allSettled(initModulesPromises);
            // PIE_WASM_REVISIT Since wasm script is loaded in async way pie-web.ts, getting pie engine at app init
            // such that till the time, we use PIE, wasm has already been loaded
            if (PIEUtils.isPIEWasmSupported()) {
                EditingEngineManager.getEditingEngine(EditingEngineType.pie);
            }
            globalThis.perfMonitor.endStartupAction(LoadTimeMarkers.initModules);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "App:_initModules: ", "Error in initializing modules", error);
            return;
        }
    }

    private _handleIMSRedirection(): void {
        const href = window.location.href;
        if (RedirectToWebWorkflowsUtils.isIMSLoginRedirection(href)) {
            AppInitializer.logIMSRedirectionInfo();
            if (!IMS.getInstance().isSignedInUser()) {
                this.viewDispatcher?.call(this.viewDispatcher, { type: AppViewActions.redirectToIMSLogin, payload: true });
                LoginUtils.signIn();
            }
        }
    }

    private async _handleUserSignIn(): Promise<void> {
        await this.refreshUserAccessInfo();
        this._logSignInInfo();
        this._logAccessInfo();
    }

    get getCreationsApp(): Creations {
        return this._creations;
    }

    get getOrganizerApp(): Organizer {
        return this._organizer;
    }

    get getEditWorkspace(): EditWorkspace {
        return this._editWorkspace;
    }

    get getLinkPreviewWorkspace(): LinkPreview {
        return this._linkPreview;
    }

    get getUserHomeApp(): UserHome {
        return this._userHome;
    }

    async createView(container: HTMLElement): Promise<void> {
        ReactDOM.render(
            React.createElement(AppView, {
                controller: this
            }),
            container
        );
    }

    async initialize(dispatch?: React.Dispatch<ViewAction>): Promise<void> {
        super.initialize(dispatch);
        AppInitializer.preInit(window.location.href);

        await this._initModules();
        this._handleIMSRedirection();
        this.viewDispatcher?.call(this.viewDispatcher, { type: AppViewActions.imsInitialized, payload: true });
        if (IMS.getInstance().isSignedInUser()) {
            await this._handleUserSignIn();
            this.viewDispatcher?.call(this.viewDispatcher, { type: AppViewActions.loginState, payload: true });
        } else {
            this.viewDispatcher?.call(this.viewDispatcher, { type: AppViewActions.loginState, payload: false });
        }

        Jarvis.getInstance().loadAndInitializeJarvis();
        //Add ingest logging here
        const url = window.location.href;

        AppInitializer.processUrl(url);

        this._toast?.createView(this.ensureHTMLElement("global-toast-container"));
        AppRouteUtil.createView(this.ensureHTMLElement("route-change-container"));
        CreationInAppNotifier.subscribe(this, CreationAppSubscriberType.statusChange);
    }

    destroy(): void {
        super.destroy();
        this.destroyView();

        this._navbar = undefined;
        this._toast = undefined;
        CreationInAppNotifier.unsubscribe(this, CreationAppSubscriberType.statusChange);
        ELBroadcastChannel.getInstance()?.closeBroadcastChannel();
    }

    destroyView(): void {
        super.destroyView();
    }

    destroyNavbarView(): void {
        this._navbar?.destroyView();
    }

    createNavbarView(): void {
        this._navbar?.createView(this.ensureHTMLElement("navbar-container"));
    }

    routeChanged(path: string): void {
        this._activeRoute = path;
    }

    async refreshTrialInfo(): Promise<void> {
        const currentDaysInTrial = store.getState().appReducer.daysInTrial;

        if (!UserUtils.isUserPaid() && !TrialUtils.isTrialExpired(currentDaysInTrial)) {
            try {
                const trialService = new TrialService();
                const daysInTrial = await trialService.getDaysInTrial();
                store.dispatch(AppAction.setDaysInTrial(daysInTrial));
                if (TrialUtils.isTrialExpired(daysInTrial)) {
                    this._logTrialExpiredTransition();
                }
            } catch (error: any) {
                Logger.log(LogLevel.WARN, "App:refreshTrialInfo: ", "Error getting trial days for user", error);
            }
        }
        else {
            this._resetTrialRefreshing();
        }
    }

    async refreshUserAccessInfo(): Promise<void> {
        const promises = [] as Promise<void>[];
        promises.push(FeaturesManager.getInstance().refreshFloodgateFeatures());
        promises.push(this.refreshTrialInfo());
        promises.push(AccessProfileManager.getInstance().refreshUserAccessProfile());

        await Promise.allSettled(promises);
        this._setTrialRefreshing();

        if (!UserUtils.isUserPaid()) {
            Logger.log(LogLevel.INFO, "Web Feature not available to the user");
        }

        this.viewDispatcher?.call(this.viewDispatcher, { type: AppViewActions.userAccessInfoFetched, payload: true });
    }

    home(): void {
        window.open(Routes.HOME, UrlOpenTargets.blank);
        return;
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case CreationInAppNotifierAction.creationStatusChanged: {
                Logger.log(LogLevel.INFO, "App (notify - creationStatusChanged)", action);
                const creationStatusData = action.payload as CreationStatusData;
                CreationUtils.showCreationCreatedMessage(this._creations, creationStatusData);
                handled = true;
                break;
            }
            case ELCreateOnDemandAction.workflowThumb: {
                await this._creations.notify(action);
                handled = true;
                break;
            }
            case AppViewActions.updateProgressText: {
                this.viewDispatcher?.call(this.viewDispatcher, { type: AppViewActions.updateProgressText, payload: action.payload });
                handled = true;
                break;
            }
            case WorkflowActionType.ingest: {
                if (!Utils.isInputWellDefined(action.payload))
                    return handled;
                IngestLogging.getInstance().logEvent(IngestUtils.addWorkspaceDetail(INGEST_APP_WORKSPACE, action.payload as Record<string, string>));
                handled = true;
                break;
            }
            case CreationPopUpAction.showCreationPopUp: {
                this._creationPopUp?.createView(this.ensureHTMLElement("global-popup-container"), action.payload);
                handled = true;
                break;
            }
            default: {
                Logger.log(LogLevel.WARN, "App - (notify)", "Bad action: ", action);
            }
        }
        return handled;
    }
}
