/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { ContentEntity, ContentType } from "@elements/elementswebcommon";
import { ELAdobeAsset } from "../../../../common/interfaces/storage/AssetTypes";
import IDoc, { DocumentType } from "../../../../editors/document/IDoc";
import IBaseWorkspace from "../../../IBaseWorkspace";
import ImageUtils from "../../../../utils/ImageUtils";
import DocumentFactory, { DocumentFactoryPayload } from "../../../../editors/document/DocumentFactory";
import { AssetStorageUtils } from "../../../../utils/AssetStorageUtils";
import { ELLayerKind, ELStageLayerData } from "../../../../common/interfaces/editing/layer/ELStageLayerTypes";
import ELContentCreationsCreator from "./utils/ELContentCreationsCreator";
import { ELContentCreationCutoutData, ELContentCreationMediaData } from "../../../../common/interfaces/creations/client/ELContentCreationsCreatorTypes";
import { ELStageDocPayload } from "../../../../common/interfaces/document/ELStageDocTypes";
import RecommendationsWorkflow from "../RecommendationsWorkflow";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { WorkflowsName } from "../../../IWorkflow";

export default abstract class ELClientRecommendations<DocPayloadType> extends RecommendationsWorkflow<DocPayloadType> {
    protected maskPathPromise?: Promise<string>;
    protected maskAsset?: ELAdobeAsset;
    protected inputMedia?: ELAdobeAsset;
    protected contentCreationsCreator: ELContentCreationsCreator;
    protected exportPromises: Promise<ImageData>[] = [];
    protected exportInputData?: ImageData;
    protected exportCutoutData?: ImageData;
    protected exportDocumentsURLMap: Map<string, string> = new Map();
    protected recommendationWidth = 420;
    protected maskAssetName = "mask.png";

    constructor(owner: IBaseWorkspace, workflowName: WorkflowsName) {
        super(owner, workflowName);
        this.contentCreationsCreator = new ELContentCreationsCreator();
    }

    protected abstract generateRecommendations(contentType: ContentType): Promise<void>;
    protected abstract createRecommendationDocuments(background: ImageData[]): Promise<IDoc[]>;
    protected abstract onRecommendationsGenerated(recommendationDocs: IDoc[], contentData: ContentEntity[]): Promise<void>;
    protected abstract createExportDocumentForContent(contentId: string): Promise<IDoc>;
    protected abstract createExportDocumentURLForContent(contentId: string): Promise<string>;
    protected abstract createExportDocuments(): Promise<void>;

    protected async cleanUpData(): Promise<void> {
        this.exportPromises = [];
        this.maskAsset = this.maskPathPromise = this.exportInputData = this.exportCutoutData = undefined;
        this.exportDocumentsURLMap.clear();
    }

    protected async addLayerToRecommendationDocuments(documents: IDoc[], layerData: ELStageLayerData): Promise<void> {
        for (const document of documents) {
            await document.addLayer(layerData);
        }
        return Promise.resolve();
    }

    protected async getRecommendationsDocumentURL(recommendationDocs: IDoc[]): Promise<string[]> {
        const recommendationURLPromises = [];
        for (const doc of recommendationDocs) {
            recommendationURLPromises.push(this.contentCreationsCreator.generateCreations(doc));
        }

        const recommendationURLs = await Promise.all(recommendationURLPromises);
        return Promise.resolve(recommendationURLs);
    }

    protected async createTemporaryDocument(background: ImageData, docPayload: ELStageDocPayload = { data: background, layerKind: ELLayerKind.pixel }): Promise<IDoc> {
        const docFactoryPayload: DocumentFactoryPayload = {
            docPayload: docPayload
        };

        const doc = await DocumentFactory.createDocument(DocumentType.stageDoc, this, docFactoryPayload);
        return Promise.resolve(doc);
    }

    protected async createExportData(): Promise<void> {
        const imageDatas = await Promise.all(this.exportPromises);

        const creationCutoutData: ELContentCreationCutoutData = {
            mask: imageDatas[1],
            source: imageDatas[0]
        }

        Logger.log(LogLevel.DEBUG, "createExportData exportPromises: ", imageDatas);

        this.exportCutoutData = await this.contentCreationsCreator.generateCutoutFromMask(creationCutoutData);
        this.exportInputData = imageDatas[0];
    }

    protected async createExportPromises(): Promise<void> {
        if (!this.inputMedia || !this.maskAsset) {
            return Promise.reject("Input asset or mask asset not set correctly");
        }

        this.exportPromises = [];

        const inputDownloadURIPromise = AssetStorageUtils.getDownloadURI(this.inputMedia);
        const maskDownloadURIPromise = AssetStorageUtils.getDownloadURI(this.maskAsset);
        const downloadURIDatas = await Promise.all([inputDownloadURIPromise, maskDownloadURIPromise]);

        this.exportPromises.push(ImageUtils.createImageData(downloadURIDatas[0]));
        this.exportPromises.push(ImageUtils.createImageData(downloadURIDatas[1]));
    }

    protected async getContentsData(contentData: ContentEntity[]): Promise<ImageData[]> {
        const contentDataPromises = [];

        for (const content of contentData) {
            const contentURL = await content.getContentURL("");
            contentDataPromises.push(ImageUtils.createImageData(contentURL));
        }

        const contentsData = await Promise.all(contentDataPromises);

        return Promise.resolve(contentsData);
    }

    protected async getMediaLayerData(contentMediaData: ELContentCreationMediaData): Promise<ImageData> {
        const mediaData = await this.contentCreationsCreator.getImageDataFromAsset(contentMediaData);
        return Promise.resolve(mediaData);
    }

    protected async createCutoutForAsset(contentMaskData: ELContentCreationMediaData): Promise<ImageData> {
        if (!this.inputMedia) {
            return Promise.reject("inputMedia not set!");
        }

        const mediaData = await this.getMediaLayerData({ ...contentMaskData, media: this.inputMedia });
        const cutoutData = await this.getCutoutLayerData(contentMaskData, mediaData);

        return Promise.resolve(cutoutData);
    }

    protected async getCutoutLayerData(contentMaskData: ELContentCreationMediaData, mediaData: ImageData): Promise<ImageData> {
        const maskData = await this.contentCreationsCreator.getImageDataFromAsset(contentMaskData);

        const creationCutoutData: ELContentCreationCutoutData = {
            mask: maskData,
            source: mediaData
        }

        Logger.log(LogLevel.DEBUG, "getCutoutLayerData creationCutoutData: ", creationCutoutData);

        const cutoutData = await this.contentCreationsCreator.generateCutoutFromMask(creationCutoutData);

        return Promise.resolve(cutoutData);
    }
}