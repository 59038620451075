/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from 'react';
import Logger, { LogLevel } from '../../utils/Logger';
import { ViewAction } from '../../view/IBaseController';

//Application Specific
import IWorkflow, { WorkflowActionType, WorkflowsName } from "../IWorkflow";
import { WorkspaceAction, WorkspacesName, WorkspaceActionType } from "../IBaseWorkspace";
import WorkflowFactory from "../WorkflowFactory";
import { ModalWorkspaceName } from "../IModalWorkspace";
import ModalWorkspaceFactory from "../ModalWorkspaceFactory";
import SlideshowUtils from "./workflows/slideshow/utils/SlideshowUtils";
import { ControllerAction } from "../../view/IViewController";
import App from "../../app/App";
import IWorkspace from "../IWorkspace";
import CollageUtils from './workflows/collage/utils/CollageUtils';
import { AssetStorageUtils } from '../../utils/AssetStorageUtils';
import { IngestUtils } from '../../utils/IngestUtils';
import { IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes } from '../../utils/IngestConstants';
import PatternOverlayUtils from './workflows/pattern-overlay/utils/PatternOverlayUtils';
import CreationUtils from './utils/CreationUtils';
import PeekThroughUtils from './workflows/peekThrough/utils/PeekThroughUtils';
import MovingOverlayUtils from './workflows/moving-overlay/utils/MovingOverlayUtils';
import ReplaceBackgroundUtils from './workflows/replace-background/utils/ReplaceBackgroundUtils';
import CreationPreviewUtils from './workflows/preview/utils/CreationPreviewUtils';
import { ELCreateOnDemandAction } from '../../common/interfaces/creations/ELCreateOnDemandTypes';
import { WorkflowSwitcher } from '../common/WorkflowSwitcher';
import { AppViewActions } from '../../common/interfaces/app/AppTypes';
import { IntlHandler } from '../../modules/intlHandler/IntlHandler';
import store from '../../stores/store';
import Constants from '../../utils/Constants/Constants';
import { MediaRepoDirFetchUtils } from '../../utils/mediaFetchUtils/MediaRepoDirFetchUtils';
import { FeaturesManager } from '../../modules/floodgate/Featuresmanager';
import { FeatureName } from '../../services/Floodgate/FloodgateConstants';
import { MediaExistUtil } from './utils/MediaExistUtils';
import PhotoTextUtils from './workflows/photoText/utils/PhotoTextUtils';
import SelectedMediaListAction from '../../stores/actions/selectedMediaListActions';
import { HistoryUtils } from '../../utils/HistoryUtils';
import { Routes } from '../../app/AppRoute';

export default class Creations extends IWorkspace {
    private _creationHome: IWorkflow;
    private _slideshow: IWorkflow;
    private _collage: IWorkflow;
    private _patternOverlay: IWorkflow;
    private _peekThrough: IWorkflow;
    private _preview: IWorkflow;
    private _workflowNameToWorkflowMap: Map<WorkflowsName, IWorkflow>;
    private _intlHandler = IntlHandler.getInstance();

    private readonly _workflowContainerId = "creation-app-container";
    private readonly _fullScreenWorkflowContainerId = "full-screen-workflow-container";
    private readonly _modalAppContainerId = "creations-modal-app-container";

    constructor(owner: App) {
        super(owner);
        this.workspaceName = WorkspacesName.creations;
        this._creationHome = WorkflowFactory.createWorkflow(WorkflowsName.creationsHome, this);
        this._slideshow = WorkflowFactory.createWorkflow(WorkflowsName.slideshow, this);
        this._preview = WorkflowFactory.createWorkflow(WorkflowsName.creationsPreview, this);
        this._collage = WorkflowFactory.createWorkflow(WorkflowsName.collage, this);
        this._patternOverlay = WorkflowFactory.createWorkflow(WorkflowsName.patternOverlay, this);
        this._peekThrough = WorkflowFactory.createWorkflow(WorkflowsName.peekThrough, this);
        this._workflowNameToWorkflowMap = new Map();
        this._intializeWorkflowMap();
    }

    private _intializeWorkflowMap(): void {
        this._workflowNameToWorkflowMap.set(WorkflowsName.slideshow, this._slideshow);
        this._workflowNameToWorkflowMap.set(WorkflowsName.creationsPreview, this._preview);
        this._workflowNameToWorkflowMap.set(WorkflowsName.creationsHome, this._creationHome);
        this._workflowNameToWorkflowMap.set(WorkflowsName.collage, this._collage);
        this._workflowNameToWorkflowMap.set(WorkflowsName.patternOverlay, this._patternOverlay);
        this._workflowNameToWorkflowMap.set(WorkflowsName.peekThrough, this._peekThrough);
    }

    private _ingest(payload: Record<string, string>): void {
        this.notify({
            type: WorkspaceActionType.ingest,
            payload: payload
        });
    }

    private _showSampleMediaForWorkflow(workflowName?: WorkflowsName): boolean {
        const shouldUseSampleMediaForCreation = (workflowName?: WorkflowsName): boolean => {
            if ((workflowName === WorkflowsName.movingOverlay) || (workflowName === WorkflowsName.patternOverlay)
                || (workflowName === WorkflowsName.replaceBackground) || (workflowName === WorkflowsName.peekThrough)
                || workflowName === WorkflowsName.photoText) {
                return true;
            }
            return false;
        };
        if ((FeaturesManager.getInstance().IsFeatureActive(FeatureName.eSampleMediaInCreations))
            && shouldUseSampleMediaForCreation(workflowName))
            return true;
        return false;
    }

    private _startMediaManagerWorkflow(action: WorkspaceAction): void {
        this.modalWorkspace = ModalWorkspaceFactory.createModalWorkspace(ModalWorkspaceName.modalWrapper,
            this, this.ensureHTMLElement("root") as HTMLDivElement);
        this.modalWorkspace.startWorkspace(this._modalAppContainerId, WorkflowsName.mediaManager, action);
    }

    private _startSampleMediaManagerWorkflow(action: WorkspaceAction): void {
        this.modalWorkspace = ModalWorkspaceFactory.createModalWorkspace(ModalWorkspaceName.sampleMedia,
            this, this.ensureHTMLElement("root") as HTMLDivElement);
        const modalWorkflowSwitcher = WorkflowSwitcher.getInstance();
        modalWorkflowSwitcher.initialize(WorkflowsName.mediaManager, action);
        this.modalWorkspace.startWorkspace(this._modalAppContainerId, WorkflowsName.sampleMediaManager, action);
    }

    private _startMediaOrSampleMediaManagerWorkflow(isMediaManagerWorkflow: boolean, action: WorkspaceAction): void {
        const { type, ...workflowPayload } = action;
        const workflowAction = { type: WorkflowActionType.initialize, ...workflowPayload };

        if (isMediaManagerWorkflow) {
            this._startMediaManagerWorkflow(workflowAction);
        }
        else {
            this._startSampleMediaManagerWorkflow(workflowAction);
        }
    }

    private _updateProgressText(progressText: string): void {
        this._owner.notify({ type: AppViewActions.updateProgressText, payload: progressText });
    }

    private _startCreationMediaManagerWorkflow(action: WorkspaceAction): void {
        const storeKey = MediaRepoDirFetchUtils.getStoreKeyForPath((Constants.ELEMENTS_PHOTOS_PATH as string),
            store.getState().mediaConfigReducer);
        const mediaOrganizerData = store.getState().mediaOrganizerReducer[storeKey];
        const numberOfMedia: number = mediaOrganizerData ? mediaOrganizerData.children.length : 0;
        if (numberOfMedia > 0 || !this._showSampleMediaForWorkflow(action.nextWorkflow)) {
            this._startMediaOrSampleMediaManagerWorkflow(true, action);
        } else {
            this._updateProgressText(this._intlHandler.formatMessage("loading"));
            const result = MediaExistUtil.getInstance().doesMediaExist();
            result.then((mediaExists) => {
                this._startMediaOrSampleMediaManagerWorkflow(mediaExists, action);
                this._updateProgressText("");
            }).catch(() => {
                // In case of error in checking media existence, start media manager workflow
                this._startMediaOrSampleMediaManagerWorkflow(true, action);
                this._updateProgressText("");
            });
        }
    }

    private _checkAndHandleQuotaExceededForCreation(ingestWorkflowType: string, creationNameForShowingError: string): boolean {
        const quotaExceeded = AssetStorageUtils.hasQuotaExceeded();
        if (quotaExceeded) {
            HistoryUtils.replaceHistory(Routes.CREATIONS);
            store.dispatch(SelectedMediaListAction.updateSelectedMediaList([]));
            this._ingest(IngestUtils.getPseudoLogObject(ingestWorkflowType, IngestEventTypes.create, IngestEventSubTypes.error, "Quota storage exceeded"));
            CreationUtils.showErrorMessageForQuotaExceeded(creationNameForShowingError);
        }
        return quotaExceeded;
    }

    private _startWorkflows(action: WorkspaceAction): void {
        switch (action.startWorkflow) {

            case WorkflowsName.creationsPreview: {
                const { type, ...workflowPayload } = action;
                const workflowAction = { type: WorkflowActionType.initialize, ...workflowPayload };
                this.startWorkflow(this._fullScreenWorkflowContainerId, this._preview, workflowAction);
                break;
            }

            case WorkflowsName.slideshow: {
                const { type, ...workflowPayload } = action;
                if (!SlideshowUtils.isOpeningSlideshow(action) &&
                    this._checkAndHandleQuotaExceededForCreation(IngestWorkflowTypes.slideshow, "slideshow")) {
                    return;
                }
                const workflowAction = { type: WorkflowActionType.initialize, ...workflowPayload };
                this.startWorkflow(this._fullScreenWorkflowContainerId, this._slideshow, workflowAction);
                break;
            }
            case WorkflowsName.patternOverlay: {
                const { type, ...workflowPayload } = action;
                if (!PatternOverlayUtils.isOpeningExistingPatternOverlay(action) &&
                    this._checkAndHandleQuotaExceededForCreation(IngestWorkflowTypes.patternOverlay, "pattern-overlay-creation")) {
                    return;
                }
                const workflowAction = { type: WorkflowActionType.initialize, ...workflowPayload };
                this.startWorkflow(this._fullScreenWorkflowContainerId, this._patternOverlay, workflowAction);
                break;
            }
            case WorkflowsName.movingOverlay: {
                const { type, ...workflowPayload } = action;
                if (!MovingOverlayUtils.isOpeningExistingMovingOverlay(action) &&
                    this._checkAndHandleQuotaExceededForCreation(IngestWorkflowTypes.movingOverlay, "moving-overlay-creation")) {
                    return;
                }
                const workflowAction = { type: WorkflowActionType.initialize, ...workflowPayload };
                const movingOverlay = WorkflowFactory.createWorkflow(WorkflowsName.movingOverlay, this);
                this.startWorkflow(this._fullScreenWorkflowContainerId, movingOverlay, workflowAction);
                break;
            }
            case WorkflowsName.replaceBackground: {
                const { type, ...workflowPayload } = action;
                if (!ReplaceBackgroundUtils.isOpeningExistingReplaceBackground(action) &&
                    this._checkAndHandleQuotaExceededForCreation(IngestWorkflowTypes.replaceBackground, "replace-background-creation")) {
                    return;
                }
                const workflowAction = { type: WorkflowActionType.initialize, ...workflowPayload };
                const replaceBackground = WorkflowFactory.createWorkflow(WorkflowsName.replaceBackground, this);
                this.startWorkflow(this._fullScreenWorkflowContainerId, replaceBackground, workflowAction);
                break;
            }
            case WorkflowsName.photoText: {
                const { type, ...workflowPayload } = action;
                if (!PhotoTextUtils.isOpeningExistingProject(action) &&
                    this._checkAndHandleQuotaExceededForCreation(IngestWorkflowTypes.photoText, "photo-text-creation")) {
                    return;
                }
                const workflowAction = { type: WorkflowActionType.initialize, ...workflowPayload };
                const photoText = WorkflowFactory.createWorkflow(WorkflowsName.photoText, this);
                this.startWorkflow(this._fullScreenWorkflowContainerId, photoText, workflowAction);
                break;
            }
            case WorkflowsName.collage: {
                const { type, ...workflowPayload } = action;
                if (!CollageUtils.isOpeningExistingCollage(action) &&
                    this._checkAndHandleQuotaExceededForCreation(IngestWorkflowTypes.collage, "photo-collage")) {
                    return;
                }
                const workflowAction = { type: WorkflowActionType.initialize, ...workflowPayload };
                this.startWorkflow(this._fullScreenWorkflowContainerId, this._collage, workflowAction);
                break;
            }
            case WorkflowsName.peekThrough: {
                const { type, ...workflowPayload } = action;
                if (!PeekThroughUtils.isOpeningExistingPeekThrough(action) &&
                    this._checkAndHandleQuotaExceededForCreation(IngestWorkflowTypes.peekThrough, "peek-through")) {
                    return;
                }
                const workflowAction = { type: WorkflowActionType.initialize, ...workflowPayload };
                this.startWorkflow(this._fullScreenWorkflowContainerId, this._peekThrough, workflowAction);
                break;
            }
            case WorkflowsName.mediaManager: {
                this._startCreationMediaManagerWorkflow(action);
                break;
            }
            case WorkflowsName.replaceMediaManager: {
                const { type, ...workflowPayload } = action;
                const workflowAction = { type: WorkflowActionType.initialize, ...workflowPayload };

                this.modalWorkspace = ModalWorkspaceFactory.createModalWorkspace(ModalWorkspaceName.modalWrapper, this, this.ensureHTMLElement("root") as HTMLDivElement);
                this.modalWorkspace.startWorkspace(this._modalAppContainerId, WorkflowsName.replaceMediaManager, workflowAction);
                break;
            }
            case WorkflowsName.creationsHome: {
                this.startWorkflow(this._workflowContainerId, this._creationHome);
                break;
            }
            default: {
                Logger.log(LogLevel.WARN, "Creations:_startWorkflows: ", "Invalid next workflow name");
                break;
            }
        }
    }

    private _startModalWorkspace<T extends WorkspaceAction>(action: T): void {
        switch (action.startModalWorkspace) {
            case ModalWorkspaceName.share: {
                this.modalWorkspace = ModalWorkspaceFactory.createModalWorkspace(ModalWorkspaceName.share, this, this.ensureHTMLElement("root") as HTMLDivElement);
                this.modalWorkspace.startWorkspace(this._modalAppContainerId);
                break;
            }
        }
    }

    private async _startCreationWorkflow(workflow: WorkflowsName): Promise<void> {
        switch (workflow) {
            case WorkflowsName.slideshow:
                {
                    const workspacePayload = SlideshowUtils.getSlideshowPayload();
                    const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
                    this._startWorkflows(workspaceAction);
                    break;
                }
            case WorkflowsName.collage:
                {
                    const workflowPayload = CollageUtils.getCollagePayload();
                    const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workflowPayload };
                    this._startWorkflows(workspaceAction);
                    break;
                }
            case WorkflowsName.patternOverlay:
                {
                    const workflowPayload = PatternOverlayUtils.getPatternOverlayPayload();
                    const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workflowPayload };
                    this._startWorkflows(workspaceAction);
                    break;
                }
            case WorkflowsName.peekThrough:
                {
                    const workflowPayload = PeekThroughUtils.getPeekThroughPayload();
                    const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workflowPayload };
                    this._startWorkflows(workspaceAction);
                    break;
                }
            case WorkflowsName.movingOverlay:
                {
                    const workflowPayload = MovingOverlayUtils.getMovingOverlayPayload();
                    const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workflowPayload };
                    this._startWorkflows(workspaceAction);
                    break;
                }
            case WorkflowsName.replaceBackground:
                {
                    const workflowPayload = ReplaceBackgroundUtils.getReplaceBackgroundPayload();
                    const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workflowPayload };
                    this._startWorkflows(workspaceAction);
                    break;
                }
            case WorkflowsName.photoText:
                {
                    const workflowPayload = PhotoTextUtils.getPayload();
                    const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workflowPayload };
                    this._startWorkflows(workspaceAction);
                    break;
                }
            case WorkflowsName.creationsPreview:
                {
                    const workspacePayload = await CreationPreviewUtils.getCreationPreviewPayload();
                    const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
                    this._startWorkflows(workspaceAction);
                    break;
                }
            case WorkflowsName.creationsHome:
            default:
                {
                    this.startWorkflow(this._workflowContainerId, this._creationHome);
                    break;
                }
        }
    }

    async initialize(dispatch?: React.Dispatch<ViewAction>): Promise<void> {
        super.initialize(dispatch);
        this._owner.createNavbarView();
        const workflow = CreationUtils.getWorkflowNameFromUrl(window.location.href);
        await this._startCreationWorkflow(workflow);
    }

    destroy(): void {
        this._owner.destroyNavbarView();
        super.destroy();
    }

    endCurrentWorkflow(): IWorkflow | undefined {
        return super.endCurrentWorkflow();
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        return this.notifyWorkspace(action as WorkspaceAction);
    }

    protected async notifyWorkspace<T extends WorkspaceAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case WorkspaceActionType.startWorkflow: {
                this._startWorkflows(action);
                handled = true;
                break;
            }
            case WorkspaceActionType.startPreviousWorkflow: {
                super.startPrevWorkflow(this._workflowContainerId, action);
                handled = true;
                break;
            }
            case WorkspaceActionType.endModalWorkspace: {
                super.endModalWorkspace();
                handled = true;
                break;
            }
            case WorkspaceActionType.startModalWorkspace: {
                this._startModalWorkspace(action);
                handled = true;
                break;
            }
            case ELCreateOnDemandAction.workflowThumb: {
                await this._creationHome.notify(action);
                handled = true;
                break;
            }
            case WorkspaceActionType.ingest:
            default: {
                handled = await super.notify(action as WorkspaceAction);
                break;
            }
        }
        return handled;
    }
}
