/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import React from "react";

//Application specific
import App from "../../app/App";
import { Routes } from "../../app/AppRoute";
import { WorkspaceAction, WorkspaceActionType, WorkspacesName } from "../IBaseWorkspace";
import IWorkspace from "../IWorkspace";
import { ViewAction } from "../../view/IBaseController";
import IWorkflow, { WorkflowActionType, WorkflowsName } from "../IWorkflow";
import ImageEditWorkflow from "./workflows/ImageEditWorkflow";
import WorkflowFactory from "../WorkflowFactory";
import { EditWorkspaceAction } from "../../common/interfaces/editing/editWorkspace/EditWorkspaceTypes";
import { ControllerAction } from "../../view/IViewController";
import { EditWorkspaceUtils } from "./utils/EditWorkspaceUtils";
import ImageEditWorkflowUtils from "./utils/ImageEditWorkflowUtils";
import { StorageService } from "../../services/StorageServiceWrapper";
import AppRouteUtil from "../../app/AppRouteUtil";
import SelectedMediaListAction from "../../stores/actions/selectedMediaListActions";
import store from "../../stores/store";
import { ModalWorkspaceName } from "../IModalWorkspace";
import ModalWorkspaceFactory from "../ModalWorkspaceFactory";
import Logger, { LogLevel } from "../../utils/Logger";
import { IntlHandler } from "../../modules/intlHandler/IntlHandler";
import { ToastUtils } from "../../utils/ToastUtils";

export default class EditWorkspace extends IWorkspace {
    // @ts-ignore //TODO: Vibhuti
    private _route: Routes;
    private _imageEditWorkflow: ImageEditWorkflow;
    private _originURL?: string;

    private readonly _workflowContainerId = "edit-workspace-container";
    private readonly _modalAppContainerId = "edit-workspace-modal-container";

    constructor(owner: App) {
        super(owner);

        this.workspaceName = WorkspacesName.edit;
        this._imageEditWorkflow = WorkflowFactory.createWorkflow(WorkflowsName.imageEditWorkflow, this) as ImageEditWorkflow;
        this._route = Routes.EDIT;
    }

    private async _startImageEditWorkflow(): Promise<void> {
        try {
            const assetURN = ImageEditWorkflowUtils.getAssetURNFromUrl(window.location.href);
            const resolveAsset = await StorageService.getInstance().resolveAsset({ assetId: assetURN }, "id");
            const workflowAction = { type: WorkflowActionType.initialize, payload: resolveAsset };
            const imageEditWorkflow = WorkflowFactory.createWorkflow(WorkflowsName.imageEditWorkflow, this) as ImageEditWorkflow;
            this.startWorkflow(this._workflowContainerId, imageEditWorkflow, workflowAction);
        } catch (error) {
            const message = IntlHandler.getInstance().formatMessage("media-not-found");
            ToastUtils.error(message);
            return Promise.reject(error);
        }
    }

    private _startModalWorkspace<T extends WorkspaceAction>(action: T): void {
        switch (action.startModalWorkspace) {
            case ModalWorkspaceName.share: {
                this.modalWorkspace = ModalWorkspaceFactory.createModalWorkspace(ModalWorkspaceName.share, this, this.ensureHTMLElement("root") as HTMLDivElement);
                this.modalWorkspace.startWorkspace(this._modalAppContainerId);
                break;
            }
        }
    }

    private _startWorkflows(action: WorkspaceAction): void {
        switch (action.startWorkflow) {
            case WorkflowsName.replaceMediaManager: {
                const { type, ...workflowPayload } = action;
                const workflowAction = { type: WorkflowActionType.initialize, ...workflowPayload };

                this.modalWorkspace = ModalWorkspaceFactory.createModalWorkspace(ModalWorkspaceName.modalWrapper, this, this.ensureHTMLElement("root") as HTMLDivElement);
                this.modalWorkspace.startWorkspace(this._modalAppContainerId, WorkflowsName.replaceMediaManager, workflowAction);
                break;
            }
            default: {
                Logger.log(LogLevel.WARN, "EditWorkspace: _startWorkflows: ", `Unknown workflow: ${action.startWorkflow}`);
                break;
            }
        }
    }

    private async _startEditWorkflow(workflow: WorkflowsName): Promise<void> {
        switch (workflow) {
            case WorkflowsName.imageEditWorkflow:
                {
                    await this._startImageEditWorkflow();
                    break;
                }
            default:
                {
                    this.notify({ type: WorkspaceActionType.endWorkspace });
                    break;
                }
        }
    }

    async initialize(dispatch?: React.Dispatch<ViewAction> | undefined): Promise<void> {
        super.initialize(dispatch);
        this._originURL = window.history.state?.state?.from;
        const workflow = EditWorkspaceUtils.getEditWorkflowNameFromUrl(window.location.href);
        try {
            await this._startEditWorkflow(workflow);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "EditWorkspace: initialize: " + error);
            this.notify({ type: WorkspaceActionType.endWorkspace });
        }
    }

    destroy(): void {
        super.destroy();
        return;
    }

    endCurrentWorkflow(): IWorkflow | undefined {
        return super.endCurrentWorkflow();
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        return this.notifyWorkspace(action as WorkspaceAction);
    }

    protected async notifyWorkspace<T extends WorkspaceAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case EditWorkspaceAction.startImageEditWorkflow: {
                const workflowPayload = action.payload;
                const workflowAction = { type: WorkflowActionType.initialize, payload: workflowPayload };
                this._imageEditWorkflow.startWorkflow(this._workflowContainerId, undefined, workflowAction);
                handled = true;
                break;
            }
            case EditWorkspaceAction.exitImageEditWorkflow: {
                this.notify({ type: WorkspaceActionType.endWorkspace });
                handled = true;
                break;
            }
            case WorkspaceActionType.startWorkflow: {
                this._startWorkflows(action);
                handled = true;
                break;
            }
            case WorkspaceActionType.startModalWorkspace: {
                this._startModalWorkspace(action);
                handled = true;
                break;
            }
            case WorkspaceActionType.endModalWorkspace: {
                super.endModalWorkspace();
                handled = true;
                break;
            }
            case WorkspaceActionType.endWorkspace: {
                this.destroy();
                store.dispatch(SelectedMediaListAction.updateSelectedMediaList([]));
                if(this._originURL) {
                    window.history.back();
                } else {
                    AppRouteUtil.changeRoute(Routes.MEDIA);
                }
               
                handled = true;
                break;
            }
            default: {
                handled = await super.notify(action as WorkspaceAction);
                break;
            }
        }
        return handled;
    }
}