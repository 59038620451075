/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { DocumentConvertibleDataType, editorInputToDocumentDataTypeMap } from "../../common/interfaces/document/DocumentTypes";
import { ELStageDocActions, ELStageDocConversionPayload } from "../../common/interfaces/document/ELStageDocTypes";
import { Edit, EditTypeToConfigMap, editTypeToEditorMap, EditTypeToInitPropsMap } from "../../common/interfaces/editing/EditorTypes";
import { editTypeToDefaultContext } from "../../common/interfaces/editing/editingManagers/StageDocEditingTypes";
import { ELLayerKind } from "../../common/interfaces/editing/layer/ELStageLayerTypes";
import ImageUtils from "../../utils/ImageUtils";
import Logger, { LogLevel } from "../../utils/Logger";
import { IEditor } from "../IEditor";
import ELStageDoc from "../client/document/ELStageDoc";
import { ELStageDocDataConverterFactory } from "../document/dataConverter/ELStageDocDataConverterFactory";
import { DocEditingManager } from "./DocEditingManager";

export const enum ELStageDocEditingContextId {
    document = "document",
    layer = "layer"
}

/**
 * Represents the editing context for the ELStageDocEditingManager.
 */
export interface ELStageDocEditingContext {
    /**
     * The ID of the editing context. It can be from ELStageDocEditingContextId
     */
    contextId: ELStageDocEditingContextId;

    /**
     * An array of layer IDs. Only applicable when the contextId is "layer".
     */
    layerIds?: string[];

    /**
     * The name of the layer to be added that will contain edit. Only applicable when the contextId is "document".
     */
    layerName?: string;

    /**
     * The kind of the layer to be added that will contain edit. Only applicable when the contextId is "document".
     */
    layerKind?: ELLayerKind;

    /**
     * Specifies whether to delete an existing layer if layer with same name is found at the top. Only applicable when the contextId is "document".
     */
    deleteExistingLayer?: boolean;
    /**
     * Specifies whether the edit is permanent and should be saved in the document. Only applicable when the contextId is "document".
     */
    applyEditDirectlyOnDoc?: boolean;
}

export interface ELStageDocEditingConfig {
    editParams: EditTypeToConfigMap[Edit];
    docEditingContext: ELStageDocEditingContext;
}

interface AppliedEditsInfo {
    editName: Edit,
    config: ELStageDocEditingConfig
}

export class ELStageDocEditingManager extends DocEditingManager<ELStageDoc, ELStageDocEditingConfig> {
    private _appliedEditsConfigStack: AppliedEditsInfo[] = [];
    private _currentEditor: IEditor<unknown, EditTypeToConfigMap[Edit], EditTypeToInitPropsMap[Edit]> | null = null;
    private _areEditsCommitted = true;

    private _getEditor(editName: Edit): IEditor<unknown, EditTypeToConfigMap[Edit], EditTypeToInitPropsMap[Edit]> {
        const Editor = editTypeToEditorMap[editName];
        return new Editor();
    }

    private async _preInitializeBeforeEdit(config: ELStageDocEditingConfig): Promise<void> {
        try {
            const { deleteExistingLayer, layerName, contextId } = config.docEditingContext;
            if (deleteExistingLayer && layerName && contextId === ELStageDocEditingContextId.document) {
                const topLayer = this.doc.getTopLayer();
                if (topLayer && topLayer.getName() === layerName) {
                    await this.doc.notify({ type: ELStageDocActions.removeLayer, payload: { layerId: topLayer.getId(), redraw: true } });
                }
            }
        } catch (error) {
            Logger.log(LogLevel.WARN, `Error initializing layer before applying edit: ${error}`);
        }
    }

    private _getFinalisedEditingConfig(editName: Edit, config: ELStageDocEditingConfig): ELStageDocEditingConfig {
        const defaultContext = editTypeToDefaultContext[editName];
        const docEditingContext = { ...defaultContext, ...config.docEditingContext };
        return { ...config, docEditingContext };
    }

    private async _commitDataToDoc(data: unknown, docEditingContext: ELStageDocEditingContext, dataType: DocumentConvertibleDataType): Promise<void> {
        const payload: ELStageDocConversionPayload = {
            layerKind: docEditingContext.layerKind,
            layerName: docEditingContext.layerName,
            redraw: true
        };
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const docConverter = ELStageDocDataConverterFactory.createDataConverter(this.doc, dataType);
        await docConverter.convertToDocData(data, payload);
    }

    private async _applyEditToDoc(editName: Edit, config: ELStageDocEditingConfig): Promise<void> {
        try {
            if (!this._editorAvailable(editName) && !this._areEditsCommitted) {
                return Promise.reject(`Edit ${editName} can't be applied as previous edit is not committed`);
            }

            await this.initializeEdit(editName);
            const modifiedConfig = this._getFinalisedEditingConfig(editName, config);
            const data = await this._currentEditor?.applyEdit(modifiedConfig.editParams as EditTypeToConfigMap[Edit]);
            this._areEditsCommitted = false;

            if (modifiedConfig.docEditingContext.applyEditDirectlyOnDoc) {
                await this._preInitializeBeforeEdit(modifiedConfig);
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                await this._commitDataToDoc(data, modifiedConfig.docEditingContext, editorInputToDocumentDataTypeMap[this._currentEditor!.getInputDataType()]);
                this._areEditsCommitted = true;
            }
        } catch (error: unknown) {
            Logger.log(LogLevel.ERROR, `Error applying edit ${editName}: ${error}`);
            return Promise.reject(error);
        }
    }

    private _saveConfig(editName: Edit, config: ELStageDocEditingConfig): void {
        if (
            this._editorAvailable(editName) &&
            !this._currentEditor?.isAdditiveEdit() &&
            this._appliedEditsConfigStack.length > 0 &&
            this._appliedEditsConfigStack[this._appliedEditsConfigStack.length - 1].editName === editName &&
            this._appliedEditsConfigStack[this._appliedEditsConfigStack.length - 1].config.docEditingContext.layerName === config.docEditingContext.layerName
        ) {
            this._appliedEditsConfigStack.pop();
        }
        this._appliedEditsConfigStack.push({ editName, config });
    }

    private _editorAvailable(editName: Edit): boolean {
        return (this._currentEditor !== null && this._currentEditor.getEditName() === editName);
    }

    private _resetDocEdit(layerName?: string): void {
        const topLayer = this.doc.getTopLayer();
        //GLIA_REVISIT: What if the layerName is empty and top layer is also not assigned a name but top layer wasn't belonging to the editor?
        if (topLayer && topLayer.getName() === layerName) {
            this.doc.notify({ type: ELStageDocActions.removeLayer, payload: { layerId: topLayer.getId(), redraw: true } });
        }
        this._currentEditor?.resetEdit();
        this._appliedEditsConfigStack.pop();
    }

    async applyEdit(editName: Edit, config: ELStageDocEditingConfig): Promise<void> {
        const contextId = config.docEditingContext.contextId;
        switch (contextId) {
            case ELStageDocEditingContextId.document:
                await this._applyEditToDoc(editName, config);
                break;
            default:
                return Promise.reject(`Unsupported contextId: ${contextId}`);
        }
        const modifiedConfig = this._getFinalisedEditingConfig(editName, config);
        this._saveConfig(editName, modifiedConfig);
    }

    async resetEdit(editName: Edit): Promise<void> {
        const appliedEditConfig = this._appliedEditsConfigStack[this._appliedEditsConfigStack.length - 1];

        if (!appliedEditConfig) {
            return Promise.reject("No edit is applied");
        }

        if (appliedEditConfig.editName !== editName) {
            return Promise.reject(`Edit ${editName} can't be reset as it is not latest edit`);
        }

        const { contextId, layerName } = appliedEditConfig.config?.docEditingContext;

        switch (contextId) {
            case ELStageDocEditingContextId.document: {
                this._resetDocEdit(layerName);
                break;
            }
            default:
                Promise.reject(`Unsupported contextId: ${contextId}`);
        }
    }

    async initializeEdit(editName: Edit): Promise<void> {
        switch (editName) {
            case Edit.adjustments:
            default: {
                if (!this._editorAvailable(editName)) {
                    this._currentEditor = this._getEditor(editName);
                    const requiredContext = this._currentEditor.getRequiredCanvasContextId();
                    const contextOptions = this._currentEditor.getRequiredCanvasContextOptions();
                    const canvas = await this.doc.getEditCanvas(requiredContext, contextOptions);
                    await this._currentEditor.initialize({ canvas: canvas, applyEditOnCanvas: true });
                    await this.setDataForEdit(editName);
                }
            }
        }
    }

    async setDataForEdit(editName: Edit): Promise<void> {
        if (!this._editorAvailable(editName)) {
            return Promise.reject(`Editor not found for edit ${editName}`);
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const docConverter = ELStageDocDataConverterFactory.createDataConverter(this.doc, editorInputToDocumentDataTypeMap[this._currentEditor!.getInputDataType()]);
        const dataBeforeEdit = await docConverter.convertToData();
        this._currentEditor?.setData(dataBeforeEdit);
    }

    async commitEdits(deInitializeEditor: boolean): Promise<void> {
        if (this._appliedEditsConfigStack.length === 0 || this._areEditsCommitted) {
            Logger.log(LogLevel.DEBUG, "ELStageDocEditingManager: commitEdits: No edits to commit");
            return;
        }

        try {
            await this._preInitializeBeforeEdit(this._appliedEditsConfigStack[this._appliedEditsConfigStack.length - 1].config);
            const editCanvas = await this.doc.getEditCanvas();
            if (editCanvas) {
                const width = editCanvas.width;
                const height = editCanvas.height;
                const dstCanvas = ImageUtils.createHTMLCanvasElementForSize({ width, height });
                const destCtx = dstCanvas.getContext("2d");
                destCtx?.translate(0, height);
                destCtx?.scale(1, -1);
                destCtx?.drawImage(editCanvas, 0, 0);
                destCtx?.restore();
                const imageData = destCtx?.getImageData(0, 0, width, height);
                const docEditingContext = this._appliedEditsConfigStack[this._appliedEditsConfigStack.length - 1].config.docEditingContext;
                await this._commitDataToDoc(imageData, docEditingContext, DocumentConvertibleDataType.imageData);
            }
            this._areEditsCommitted = true;

            if (deInitializeEditor) {
                this._currentEditor = null;
                this.doc.removeEditCanvas();
            }
        } catch (error) {
            Logger.log(LogLevel.ERROR, `Error committing edits: ${error}`);
        }
    }
}
