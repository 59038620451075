/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

export enum FeatureName {
    eWebAccess = "WebAccess",
    eOpenInDesktop = "OpenInDesktop",
    ePatternOverlay = "PatternOverlay",
    eJarvisChatBot = "JarvisChatBot",
    ePeekThrough = "PeekThrough",
    eMovingOverlay = "MovingOverlay",
    eReplaceBackground = "ReplaceBackground",
    eShowDesktopProductCards = "ShowDesktopProductCards",
    eClientReplaceBackground = "ClientReplaceBackground",
    eQRShareView = "QRShareView",
    eMultiMediaQRShareView = "MultiMediaQRShareView",
    eStartTrialDialog = "StartTrialDialog",
    eAdjustments = "Adjustments",
    eSampleMediaInCreations = "SampleMediaInCreations",
    ePhotoText = "PhotoText",
    eSharePagePopup = "SharePagePopUp",
    eStockInAutoBackground = "StockInAutoBackground",
    eABTestEliveCardClickable = "ABTestEliveCardClickable"
}