/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { combineReducers } from "redux";

//Application Specific
import mediaConfigReducer from "./mediaGridConfigReducer";
import mediaOrganizerReducer from "./mediaOrganizerReducers";
import mediaThumbReducer from "./mediaThumbReducer";
import mediaMetadataReducer from "./mediaMetadataReducer";
import selectedMediaListReducer from "./selectedMediaListReducer";
import creationsReducer from "./CreationsReducer";
import shareReducer from "./ShareReducer";
import floodgateFeaturesReducer from "./FloodgateFeaturesReducer";
import mediaGridToolbarStateReducer from "./mediaGridToolbarReducer";
import docStateReducer from "./DocReducer"
import sivReducer from "./SIVReducer";
import appReducer from "./AppReducer";
import cookiePreferencesReducer from "./CookiePreferencesReducer";
import fullResMediaReducer from "./FullResMediaReducer";
import collageReducer from "./CollageReducer";
import uploadedMediaListReducer from "./uploadedMediaListReducer";
import dualViewReducer from "./DualViewReducer";
import peekThroughReducer from "./PeekThroughReducer";
import resizedDocReducer from "./ResizedDocReducer";
import recommendationWorkflowReducer from "./RecommendationWorkflowReducer";
import layoutReducer from "./LayoutReducer";
import canvasReducer from "./CanvasReducer";
import recentCreationReducer from "./RecentCreationsReducer";
import accessProfileReducer from "./AccessProfileReducer";
import adjustmentsReducer from "./AdjustmentsReducer";
import imageEditWorkflowReducer from "./imageEditWorkflowReducer";
import textEditReducer from "./TextEditReducer";
import photoTextReducer from "./PhotoTextReducer";
import adobeStockReducer from "./AdobeStockReducer";

const rootReducer = combineReducers({
    appReducer: appReducer,
    mediaOrganizerReducer: mediaOrganizerReducer,
    mediaThumbReducer: mediaThumbReducer,
    mediaMetadataReducer: mediaMetadataReducer,
    mediaConfigReducer: mediaConfigReducer,
    selectedMediaListReducer: selectedMediaListReducer,
    shareReducer: shareReducer,
    creationsReducer: creationsReducer,
    docStateReducer: docStateReducer,
    floodgateFeaturesReducer: floodgateFeaturesReducer,
    mediaGridToolbarStateReducer: mediaGridToolbarStateReducer,
    sivReducer: sivReducer,
    cookiePreferencesReducer: cookiePreferencesReducer,
    fullResMediaReducer: fullResMediaReducer,
    collageReducer: collageReducer,
    uploadedMediaListReducer: uploadedMediaListReducer,
    dualViewReducer: dualViewReducer,
    peekThroughReducer: peekThroughReducer,
    resizedDocReducer: resizedDocReducer,
    recommendationWorkflowReducer: recommendationWorkflowReducer,
    adobeStockReducer: adobeStockReducer,
    layoutReducer: layoutReducer,
    canvasReducer: canvasReducer,
    recentCreationReducer: recentCreationReducer,
    accessProfileReducer: accessProfileReducer,
    adjustmentsReducer: adjustmentsReducer,
    imageEditWorkflowReducer: imageEditWorkflowReducer,
    textEditReducer: textEditReducer,
    photoTextReducer: photoTextReducer
});

export default rootReducer;
